import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ICD10UxService } from "@app/pages/danh-muc/icd10/services/icd10-ux.service";
import { ICD9UxService } from "@app/pages/danh-muc/icd9/services/icd9-ux.service";
import { ComboServiceProxy, ComboTableInputDtoOfString, ICD10Dto } from "@shared/service-proxies/service-proxies";
import { NzSelectModeType } from "ng-zorro-antd/select";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, finalize } from "rxjs/operators";

@Component({
    selector: 'icd10-combo',
    template: ` <div class="ora-combo">
        
         <nz-select
                    nzShowSearch
                    nzServerSearch
                    [nzMaxTagCount]="maxTagCount"
                    [nzMaxTagPlaceholder]="maxTagPlaceholderTmpl"
                    [nzAllowClear]="nzAllowClear"
                    nzPlaceHolder="{{ placeHolders }}"
                    [nzMode]="nzMode"
                    [nzLoading]="loading"
                    [(ngModel)]="_value"
                    [nzDisabled]="_isDisabled"
                    (nzFocus)="(onFocus)"
                    style="width:100%"
                    (ngModelChange)="onChangeValue($event)"
                    (nzOnSearch)="search$.next($event)"
                    [nzDropdownClassName]="'oda-select-dropdown'"
                    [nzDropdownRender]="renderTemplate"
                    [nzSuffixIcon]="prefixTemplateUser"
                >

                    <div *ngFor="let item of optionList" >
                        <nz-option nzDisabled *ngIf="item.data.loaiDataICD10 == 1 && isStatus == true" nzCustomContent  [nzLabel]="item.displayText"  >
                            <div>{{item.displayText}}</div>
                        </nz-option> 
                        <nz-option nzDisabled *ngIf="item.data.loaiDataICD10 == 2 && isStatus == true" nzCustomContent  [nzLabel]="item.displayText"  >
                            <div style="margin-left:7px">{{item.displayText}}</div>
                        </nz-option> 
                        <nz-option nzDisabled *ngIf="item.data.loaiDataICD10 == 3 && isStatus == true" nzCustomContent  [nzLabel]="item.displayText"  >
                            <div style="margin-left:12px">{{item.displayText}}</div>
                        </nz-option>
                        <nz-option *ngIf="item.data.loaiDataICD10 == 4 && isStatus == true" nzCustomContent  [nzLabel]="item.displayText" [nzValue]="item.value" >
                            <div style="margin-left:19px">{{item.displayText}}</div>
                        </nz-option> 
                    </div>


                    <ng-template #renderTemplate>
                        <hr *ngIf="isSearchAdvance" />
                        <div *ngIf="isSearchAdvance">
                            <a nz-button nzType="link" (click)="searchAdvance()"
                                ><i nz-icon nzType="search" nzTheme="outline"></i> Tìm kiếm</a
                            >
                        </div>
                    </ng-template>

                    <ng-template #prefixTemplateUser><i nz-icon nzType="down" nzTheme="outline"></i></ng-template>
                </nz-select>
                <ng-template #maxTagPlaceholderTmpl let-selectedList>+ {{ selectedList.length }} </ng-template>
    </div>`,
    styles: [
        `
            .ora-combo {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            .ora-combo nz-select {
                width: 100%;
            }

            .ora-close {
                cursor: pointer;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ICD10ComboV2Component),
            multi: true,
        },
    ],
})
export class ICD10ComboV2Component implements OnInit, ControlValueAccessor {
    @Input() placeHolders?: string = '- Chọn (Nhập để tìm kiếm ...) -';
    @Input() nzAllowClear?: boolean = true;
    @Input() maxOptionRender = 20;
    @Input() maxTagCount = 5;
    @Input() isAdd = false;
    @Input() isUpdate = false;
    @Input() isSearchAdvance = true;
    @Input() coSoId;
    @Input() hinhThucToChucId: number;
    @Output() eventChange = new EventEmitter<any>();

    search$ = new Subject<string>();
    nzMode: NzSelectModeType = 'default';
    _value: any | string | number = '';
    public optionList: any[] = [];
    public optionListDefault: any[] = [];
    _isDisabled = false;
    loading = false;
    private searchSubscription: Subscription;
    private dataSubscription: Subscription;
    public onChange = (v: any) => { };
    private onTouched = () => { };
    key = "combo-data-icd10-default";
    cache = sessionStorage.getItem(this.key);
    isStatus = true;
    isMulti = false;

    constructor(
        private _comBoServiceProxy: ComboServiceProxy,
        private _ICD10UxService: ICD10UxService) {
    }

    get svalue() {

        if (this._value != null) {
            let selectedItem = this.optionListDefault.find((m) => m.value == this._value);
            if (selectedItem != null) {
                return selectedItem.displayText;
            }
        }

    }

    @Input()
    get disabled() {
        return this._isDisabled;
    }

    set disabled(v: boolean) {
        this._isDisabled = v;
    }

    writeValue(obj: any): void {
        this._value = obj;
        this.getDataFromSever(undefined, true);
    }

    getDataFromSever(search?: string, isChangeListDefault: boolean = false) {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
            this.dataSubscription = null;
        }


        let isUseCache = !this.hinhThucToChucId && !this.coSoId && !search && !this._value;
        if (isUseCache && this.cache) {
            this.optionList = JSON.parse(this.cache);
        } else {
            let input = new ComboTableInputDtoOfString();
            input.maxResult = this.maxOptionRender;
            input.filter = search;
            if (!search) input.id = this._value;
            this.loading = true;
            this.dataSubscription = this._comBoServiceProxy.allICD10(input)
                .pipe(finalize(() => { this.loading = false }))
                .subscribe(
                    result => {
                        this.optionList = result;
                        if (isUseCache && !this.cache) {
                            sessionStorage.setItem(this.key, JSON.stringify(result));
                        }
                        if (isChangeListDefault) {
                            this.optionListDefault = result;
                        }
                    }
                )
        }


    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }

    ngOnInit() {
        this.searchSubscription = this.search$.pipe(
            debounceTime(1000),
            distinctUntilChanged()
        )
            .subscribe((result) => {
                if (result) {
                    this.getDataFromSever(result);
                } else {
                    if (this._value) { this.optionList = this.optionListDefault; }
                    else {
                        this.getDataFromSever();
                    }
                }
            });
    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }
    }

    onFocus(event: any): void {
        this.onTouched();
    }

    searchAdvance() {
        this._ICD10UxService.openSearchListModal({
            listOfSelectedValue: this.isMulti ? [...this._value] : [this._value],
            title: 'Tìm kiếm ',
            isMulti: this.isMulti,
            callBack: (result) => {
                if (result?.code) {
                    let selectedValueFromModal = result?.code;
                    this.writeValue(selectedValueFromModal);
                    this.onChangeValue(this._value)
                }
                // if (result != null && result.length > 0) {
                //     if (this.isMulti) {
                //         let listOfSelectedValueFromModal = result.map((x) => x.ma);
                //         this.writeValue(listOfSelectedValueFromModal);
                //     } else {
                //         let selectedValueFromModal = result[0].ma;
                //         this.writeValue(selectedValueFromModal);
                //     }
                // }
            },
        });
    }

    onChangeValue(event: any): void {
        this.onChange(event);
        let selectedItem = this.optionList.find((x) => x.value == event);
        if (selectedItem) {
            this.eventChange.emit(selectedItem.data);
        }
        else {
            this.eventChange.emit(null);
        }
        this.optionListDefault = this.optionList;
    }

}
