import { Input, Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'status-active-control',
    template: `
     <i nz-icon nzType="check" nzTheme="outline" class="text-success font-weight-bolder" *ngIf="isActive"></i>
     <i nz-icon nzType="close" nzTheme="outline" class="text-danger font-weight-bolder" *ngIf="!isActive"></i>
  `,
})

export class StatusActiveControlComponent implements OnInit {
    @Input() isActive: boolean;
    constructor(
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }
}
