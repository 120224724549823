<div nz-row [nzGutter]="8" [id]="formId">
    <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Tên cơ sở (tiếng Việt) <span class="text-red">*</span></label>
        <input nz-input [(ngModel)]="dataItem.tenCoSo" [disabled]="isView" />
        <validation-custom [sModel]="dataItem.tenCoSo"></validation-custom>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Tên cơ sở (tiếng Anh) </label>
        <input nz-input [(ngModel)]="dataItem.tenCoSo_En" [disabled]="isView" />
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Tên viết tắt </label>
        <input nz-input [(ngModel)]="dataItem.tenVietTat" [disabled]="isView" />
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Hình thức tổ chức </label>
        <table-combo [TableEnum]="ComboBoxTableCode.GetAllHinhThucToChuc" [typeRequest]="dataItem.level"
            [(ngModel)]="dataItem.hinhThucToChucId" [disabled]="isView">
        </table-combo>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Tuyến kỹ thuật</label>
        <enum-combo [EnumCode]="ComboBoxEnumCode.TuyenKyThuat" [(ngModel)]="dataItem.tuyenKyThuatEnum"
            [disabled]="isView">
        </enum-combo>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Tỉnh/TP<span class="text-red">*</span></label>
        <don-vi-hanh-chinh-chinh-combo [(ngModel)]="dataItem.maTinh" [capDo]="CapDoDonViHanhChinh.Tinh"
            [disabled]="isView">
        </don-vi-hanh-chinh-chinh-combo>
        <validation-custom [sModel]="dataItem.maTinh"></validation-custom>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Quận/Huyện<span class="text-red">*</span></label>
        <don-vi-hanh-chinh-chinh-combo [(ngModel)]="dataItem.maHuyen" [capDo]="CapDoDonViHanhChinh.Huyen"
            [parentCode]="dataItem.maTinh" [disabled]="isView">
        </don-vi-hanh-chinh-chinh-combo>
        <validation-custom [sModel]="dataItem.maHuyen"></validation-custom>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Phường/Xã</label>
        <don-vi-hanh-chinh-chinh-combo [(ngModel)]="dataItem.maXa" [capDo]="CapDoDonViHanhChinh.Xa"
            [parentCode]="dataItem.maHuyen" [disabled]="isView">
        </don-vi-hanh-chinh-chinh-combo>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6">
        <label class="font-weight-500">Địa chỉ đầy đủ</label>
        <input nz-input [(ngModel)]="dataItem.diaChi" [disabled]="isView" />
    </div>

    <nz-divider *ngIf="isCSKCB"></nz-divider>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Mã CSKCB (BHYT) <span class="text-red">*</span></label>
        <input nz-input [(ngModel)]="dataItem.maKCB" [disabled]="isView" />
        <validation-custom [sModel]="dataItem.maKCB"></validation-custom>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Nơi cấp mã CSKCB</label>
        <table-combo [TableEnum]="ComboBoxTableCode.GetAllCoQuanChuQuan" [(ngModel)]="dataItem.noiCapMaKCBId"
            [disabled]="isView">
        </table-combo>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Ngày cấp mã CSKCB</label>
        <ora-date-picker [(ngModel)]="dataItem.ngayCapMaKCB" [disabled]="isView"> </ora-date-picker>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Năm thành lập</label>
        <input nz-input [(ngModel)]="dataItem.namThanhLap" type="number" [disabled]="isView" />
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Ngành quản lý</label>
        <table-combo [TableEnum]="ComboBoxTableCode.GetAllBoNganhQuanLy" [(ngModel)]="dataItem.boNganhId"
            [disabled]="isView">
        </table-combo>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Loại đơn vị sự nghiệp</label>
        <enum-combo [EnumCode]="ComboBoxEnumCode.LoaiHinhQuanLy" [(ngModel)]="dataItem.loaiHinhSoHuu"
            [disabled]="isView">
        </enum-combo>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6"
        *ngIf="dataItem.loaiHinhSoHuu === LoaiHinhQuanLy.TuNhan && isCSKCB">
        <label class="font-weight-500">Tên doanh nghiệp/công ty</label>
        <input nz-input [disabled]="isView" [(ngModel)]="dataItem.chuSoHuu" />
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Hình thức tài chính</label>
        <enum-combo [EnumCode]="ComboBoxEnumCode.CoCheTaiChinh" [(ngModel)]="dataItem.loaiHinhTaiChinhEnum"
            [disabled]="isView">
        </enum-combo>
    </div>

    <nz-divider *ngIf="isCSKCB"></nz-divider>
    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Chuyên khoa</label>
        <table-combo [TableEnum]="ComboBoxTableCode.GetAllChuyenKhoa" [(ngModel)]="dataItem.chuyenKhoaId"
            [disabled]="isView">
        </table-combo>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Cơ sở có thực hiện khám bệnh, chữa bệnh</label>
        <div>
            <nz-select style="width: 100%" [(ngModel)]="dataItem.isBenhVien"  [disabled]="isView">
                <nz-option [nzValue]="true" nzLabel="Có"></nz-option>
                <nz-option [nzValue]="false" nzLabel="Không"></nz-option>
            </nz-select>
        </div>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Có đăng ký là nơi ĐK khám chữa bệnh BHYT ban đầu</label>
        <div>
            <nz-select style="width: 100%" [(ngModel)]="dataItem.isBHYTBanDau"[disabled]="isView">
                <nz-option [nzValue]="true" nzLabel="Có"></nz-option>
                <nz-option [nzValue]="false" nzLabel="Không"></nz-option>
            </nz-select>
        </div>
    </div>

    <nz-divider *ngIf="isCSKCB"></nz-divider>

    <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Số giấy phép hoạt động<span class="text-red">*</span></label>  
        <input nz-input [(ngModel)]="dataItem.soGiayPhepHoatDong" [disabled]="isView" />
        <validation-custom [sModel]="dataItem.soGiayPhepHoatDong"></validation-custom>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Ngày cấp giấy phép</label>
        <ora-date-picker [(ngModel)]="dataItem.ngayCapGPHD" [disabled]="isView"> </ora-date-picker>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Nơi cấp giấy phép</label>
        <table-combo [TableEnum]="ComboBoxTableCode.GetAllCoQuanChuQuan" [(ngModel)]="dataItem.noiCapGPHDId"
            [disabled]="isView">
        </table-combo>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Đính kèm giấy phép hoạt động</label>
        <div>
            <custom-file-upload-controler [(sModel)]="dataItem.fileGPHD" [urlApi]="urlApi" [uploadUrl]="baseUploadUrl"
                [isView]="isView" [viewUrl]="baseViewUrl" [downloadUrl]="baseDownloadUrl" #filedinhkem
                [disableDelete]="true">
            </custom-file-upload-controler>
        </div>
    </div>

    <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
        <label class="font-weight-500">Trạng thái hoạt động <span class="text-red">*</span></label>
        <enum-combo [EnumCode]="ComboBoxEnumCode.TrangThaiHoatDongCoSo" [(ngModel)]="dataItem.trangThaiHoatDong"
            [disabled]="isView">
        </enum-combo>
        <validation-custom [sModel]="dataItem.trangThaiHoatDong"></validation-custom>
    </div>

    <div nz-col nzSpan="24">
        <div class="title-muc" style="margin-top: 20px">Thông tin liên hệ</div>

        <div>
            <nz-tabset>
                <nz-tab [nzForceRender]="true" nzTitle="Thông tin liên hệ">
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Điện thoại</label>
                            <input nz-input [(ngModel)]="dataItem.dienThoai" [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Fax</label>
                            <input nz-input [(ngModel)]="dataItem.fax" [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Email</label>
                            <input nz-input [(ngModel)]="dataItem.email" [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Website</label>
                            <input nz-input [(ngModel)]="dataItem.website" [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Đường dây nóng Bộ Y tế cấp</label>
                            <input nz-input [(ngModel)]="dataItem.duongDayNongBYT" [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Đường dây nóng riêng của đơn vị</label>
                            <input nz-input [(ngModel)]="dataItem.duongDayNongBV" [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Đường dây nóng PCD (thiên tai, thảm hoạ)</label>
                            <input nz-input [(ngModel)]="dataItem.duongDayNongPCDB" [disabled]="isView" />
                        </div>

                    </div>
                </nz-tab>
                <nz-tab [nzForceRender]="true" nzTitle="Thông tin liên hệ lãnh đạo">
                    <div nz-row [nzGutter]="16">
                        <nz-table nzTemplateMode [nzScroll]="{ x: '800px' }" nzBordered nzTableLayout="fixed">
                            <thead>
                                <tr>
                                    <th nzAlign="center" nzWidth="50px">STT</th>
                                    <th>Chức vụ</th>
                                    <th>Họ tên</th>
                                    <th>Điện thoại di động</th>
                                    <th>Điện thoại bàn</th>
                                    <th>Email</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of dataItem.listThongTinLienHeLanhDao;index as idx">
                                    <td>{{idx+1}}</td>
                                    <td>
                                        <table-combo [TableEnum]="ComboBoxTableCode.GetAllChucVu"
                                            [(ngModel)]="item.chucVu" [disabled]="isView" [isAdd]="false"
                                            [isUseCache]="false">
                                        </table-combo>
                                    </td>
                                    <td><input nz-input [(ngModel)]="item.hoTen" [disabled]="isView" /></td>
                                    <td><input nz-input [(ngModel)]="item.mobile" [disabled]="isView" /></td>
                                    <td><input nz-input [(ngModel)]="item.dienThoaiBan" [disabled]="isView" /></td>
                                    <td><input nz-input [(ngModel)]="item.email" [disabled]="isView" /></td>
                                    <td>
                                        <button nz-button nzDanger nzSize="small"
                                            (click)="xoaItem(idx,dataItem.listThongTinLienHeLanhDao)">
                                            <i nz-icon nzType="delete"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot  *ngIf=" !isView">
                                <tr>
                                    <td>
                                        <ord-add-new-btn nzSize="small" (click)="addLienHeLanhDao()"></ord-add-new-btn>
                                    </td>
                                </tr>
                            </tfoot>
                        </nz-table>
                    </div>
                </nz-tab>
                <nz-tab [nzForceRender]="true" nzTitle="Thông tin liên hệ phòng ban">
                    <div nz-row [nzGutter]="16">
                        <nz-table nzTemplateMode [nzScroll]="{ x: '800px' }" nzBordered nzTableLayout="fixed">
                            <thead>
                                <tr>
                                    <th nzAlign="center" nzWidth="50px">STT</th>
                                    <th>Chức vụ</th>
                                    <th>Khoa phòng</th>
                                    <th>Họ tên</th>
                                    <th>Mobile</th>
                                    <th>Điện thoại bàn</th>
                                    <th>Email</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of dataItem.listThongTinLienHePhongBan;index as idx">
                                    <td>{{idx+1}}</td>
                                    <td>
                                        <table-combo [TableEnum]="ComboBoxTableCode.GetAllChucVu" [isUseCache]="false"
                                            [(ngModel)]="item.chucVu" [disabled]="isView" [isAdd]="false">
                                        </table-combo>
                                    </td>
                                    <td>
                                        <table-combo [TableEnum]="ComboBoxTableCode.GetAllKhoaPhong"
                                            [(ngModel)]="item.khoaPhong" [disabled]="isView">
                                        </table-combo>
                                    </td>
                                    <td><input nz-input [(ngModel)]="item.hoTen" [disabled]="isView" /></td>
                                    <td><input nz-input [(ngModel)]="item.mobile" [disabled]="isView" /></td>
                                    <td><input nz-input [(ngModel)]="item.dienThoaiBan" [disabled]="isView" /></td>
                                    <td><input nz-input [(ngModel)]="item.email" [disabled]="isView" /></td>
                                    <td>
                                        <button nz-button nzDanger nzSize="small"
                                            (click)="xoaItem(idx,dataItem.listThongTinLienHePhongBan)">
                                            <i nz-icon nzType="delete"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot *ngIf=" !isView">
                                <tr>
                                    <td >
                                        <ord-add-new-btn nzSize="small" (click)="addLienHePhongBan()"></ord-add-new-btn>
                                    </td>
                                </tr>
                            </tfoot>
                        </nz-table>
                    </div>
                </nz-tab>
                <nz-tab [nzForceRender]="true" nzTitle="Thông tin giường bệnh" *ngIf="isCSKCB">
                    <div nz-row [nzGutter]="16">
                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Số GBKH hoặc GBĐK (BV Tư nhân)</label>
                            <input nz-input [(ngModel)]="dataItem.thongTinGiuongBenh.soGiuongKeHoach"
                                [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Số Giường bệnh thực tế</label>
                            <input nz-input [(ngModel)]="dataItem.thongTinGiuongBenh.soGiuongThucTe"
                                [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Số Giường bệnh theo yêu cầu</label>
                            <input nz-input [(ngModel)]="dataItem.thongTinGiuongBenh.soGiuongTheoYeuCau"
                                [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Số Giường HSCC - ICU - Chống độc</label>
                            <input nz-input [(ngModel)]="dataItem.thongTinGiuongBenh.soGiuongHSCCICUCD"
                                [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Số bàn mổ</label>
                            <input nz-input [(ngModel)]="dataItem.thongTinGiuongBenh.soBanMo" [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Số bàn đẻ</label>
                            <input nz-input [(ngModel)]="dataItem.thongTinGiuongBenh.soBanDe" [disabled]="isView" />
                        </div>

                        <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                            <label class="font-weight-500">Số Giường cách ly áp lực âm</label>
                            <input nz-input [(ngModel)]="dataItem.thongTinGiuongBenh.soGiuongCachLyALA"
                                [disabled]="isView" />
                        </div>
                    </div>
                </nz-tab>
            </nz-tabset>
        </div>

        <div class="title-muc" style="margin-top: 20px">Thông tin khác</div>
        <div nz-row [nzGutter]="16">
            <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                <label class="font-weight-500">Kinh độ</label>
                <input nz-input [(ngModel)]="dataItem.mapKinhDo" [disabled]="isView" />
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                <label class="font-weight-500">Vĩ độ</label>
                <input nz-input [(ngModel)]="dataItem.mapViDo" [disabled]="isView" />
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
                <label class="font-weight-500">VPostCode</label>
                <input nz-input [(ngModel)]="dataItem.vPostCode" [disabled]="isView" />
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
                <label class="font-weight-500">Diện tích mặt bằng</label>
                <input nz-input [(ngModel)]="dataItem.dienTichMatBang" [disabled]="isView" />
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
                <label class="font-weight-500">Diện tích sử dụng</label>
                <input nz-input [(ngModel)]="dataItem.dienTichSuDung" [disabled]="isView" />
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
                <label class="font-weight-500">Khoảng cách tuyến trên gần nhất</label>
                <input nz-input [(ngModel)]="dataItem.khoangCachTuyenTren" [disabled]="isView" />
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6" *ngIf="isCSKCB">
                <label class="font-weight-500">Dân số trung bình xã (theo TYT)</label>
                <input nz-input [(ngModel)]="dataItem.danSoTrungBinhXa" [disabled]="isView" />
            </div>
        </div>
        <div style="display: flex; flex-direction: column;margin-top: 10px; " nz-col class="gutter-row" nzXs="24" nzLg="8" nzXXl="6">
            <label [disabled]="isView" nz-checkbox  [(ngModel)]="dataItem.isCongKhai"><b>Công khai y tế</b></label>
        </div>
    </div>

</div>