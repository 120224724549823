import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Optional, Output, Provider, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { ISelectOption, ISelectOptions, SelectOptions } from './model';

const VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OraSelectComponent),
    multi: true,
};

@Component({
    selector: 'ora-select',
    templateUrl: './ora-select.component.html',
    styleUrls: ['./ora-select.component.scss'],
    // providers: [VALUE_ACCESSOR],
})
export class OraSelectComponent implements OnInit {
    options: ISelectOption[] = [];
    optionsSource: ISelectOption[] = [];

    @Input() refNotFound: TemplateRef<any> | string = 'Không tìm thấy...';
    @Input() placeHolder = 'Chọn...';
    @Input() allowClear = true;
    @Input() selectMode?: 'default' | 'multiple' | 'tags' = 'default';
    @Input() isMultiple?: boolean;
    @Input() isAdd = false;
    @Input() isSearchAdvance = false;

    @Output() onItemSelected = new EventEmitter<ISelectOption>();
    @Output() onSearch = new EventEmitter();
    @Output() onSearchAdvance = new EventEmitter();
    @Output() onAddOrEdit = new EventEmitter();

    isHasDirective = false;
    searchTerm$ = new Subject<string>();
    keyword;
    subscribe: any;

    _value: any;
    @Input()
    get value() {
        return this._value;
    }

    set value(v: any) {
        this._value = v;
    }

    _isDisabled = false;
    @Input()
    get disabled() {
        return this._isDisabled;
    }

    set disabled(v: boolean) {
        this._isDisabled = v;
    }

    _sources: ISelectOption[] = [];
    get sources() {
        return this._sources;
    }

    @Input()
    set sources(values: ISelectOption[]) {
        this._sources = values;
        if (this._sources) {
            this.options = this._sources;
            this.optionsSource = this._sources;
        }
    }

    onChangeValue(event: any): void {
        const obj = this._sources.find((m) => m.value == event);
        this.onItemSelected.emit(obj);
    }

    search(value: string): void {
        this.keyword = value;

        if (this.isHasDirective) {
            value = AppUtilityService.removeDau(value);
            this.options = this.optionsSource.filter((s) => AppUtilityService.removeDau(s.displayText.toLowerCase()).indexOf(value.toLowerCase()) !== -1);
        } else {
            this.searchTerm$.next(value);
        }
    }

    constructor(@Optional() @Inject(SelectOptions) private directive: ISelectOptions) {
        this.isHasDirective = directive !== null && directive !== undefined;
        if (this.isHasDirective) {
            directive.options$.subscribe((ressult) => {
                this.options = ressult;
                this.optionsSource = ressult;
            });
        }
        this.searchTerm$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((term: string) => {
            this.onSearch.emit(term);
        });
    }

    ngOnInit(): void {
        this.selectMode = this.isMultiple ? 'multiple' : 'default';
    }

    addOrEdit() {
        this.onAddOrEdit.emit();
    }

    searchAdvance() {
        this.onSearchAdvance.emit();
    }
}
