import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ord-cancel-btn',
  template: `
    <ord-common-btn nzTypeButton="default" nzIcon="close" text="{{text}}"
                    [loading]="loading"
                    (clickEvent)="clickEvent.emit()"></ord-common-btn>`
})
export class CancelBtnComponent {
  @Input() text = 'Đóng';
  @Input() loading = false;
  @Output() clickEvent = new EventEmitter();
}
