import { Injectable } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { AMR_AntibioticClassDto, ChuyenKhoaDto } from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AMRLopKhangSinhEditComponent } from '../arm-lop-khang-sinh-edit-modal/arm-lop-khang-sinh-edit-modal.component';


@Injectable({
    providedIn: 'root'
})
export class AMRLopKhangSinhUxService {

    constructor(private modalService: NzModalService) { }

    public openCreateOrEditModal(option: { dataItem: AMR_AntibioticClassDto; isView?: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.isView ? 'Xem chi tiết' : (option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ');
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: AMRLopKhangSinhEditComponent,
            nzComponentParams: {
                dataItem: Object.assign(new AMR_AntibioticClassDto(), { ...option.dataItem }),
                isView: option.isView,
            },
            nzWidth: '50%',
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result: AMR_AntibioticClassDto) => {
            if (result != null) {
                option.callBack(result);
            }
        });
    }
    // public openSearchListModal(option: {
    //     listOfSelectedValue: number[];
    //     title?: string;
    //     isMulti?: boolean;
    //     callBack: any;
    // }): void {
    //     let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
    //     let sTitle = option.title || 'Danh sách ';
    //     const modal = this.modalService.create({
    //         nzTitle: sTitle + icon,
    //         nzContent: ChuyenKhoaSearchModalComponent,
    //         nzComponentParams: {
    //             listOfSelectedValue: [...option.listOfSelectedValue],
    //             isMulti: option.isMulti,
    //         },
    //         nzFooter: null,
    //         nzMaskClosable: false,
    //         nzWidth: '100%',
    //     });

    //     modal.afterClose.subscribe((result: ChuyenKhoaDto[]) => {
    //         if (result != null && result.length > 0) {
    //             option.callBack(result);
    //         }
    //     });
    // }

}
