
import { Injectable } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { LstDictionaryDto } from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DictionaryEditModalComponent } from '../modal/dictionary-edit-modal.component';
@Injectable(
    {
        providedIn:'root'
    }
)
export class DictionaryUXService {
    constructor(
        private modalService: NzModalService,
    ) {

    }

    public openCreateOrEditModal(option: { dataItem: LstDictionaryDto; lstDictionaryTypeCode: string; isView?: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = AppUtilityService.isNullOrWhiteSpace(option.dataItem.code) ? 'Thêm mới' : 'Cập nhật';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: DictionaryEditModalComponent,
            nzComponentParams: {
                dataItem: Object.assign(new LstDictionaryDto(), { ...option.dataItem }),
                isView: option.isView,
                lstDictionaryTypeCode: option.lstDictionaryTypeCode
            },
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result: LstDictionaryDto) => {
            if (result != null) {
                option.callBack(result);
            }
        });

    }

    public openSearchListModal(option: {
        listOfSelectedValue: number[];
        title?: string;
        callBack: any;
    }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách phòng ban';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            //  nzContent: PhongBanSelectSearchModalComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue]
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: "60%"
        });

        modal.afterClose.subscribe((result: LstDictionaryDto[]) => {
            if (result != null && result.length > 0) {
                option.callBack(result);
            }
        });
    }
}
