import { Component, EventEmitter, forwardRef, Input, OnInit, Output, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select'; // Import kiểu NzSelectModeType
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { CommonServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { BaseComboComponent } from './base-combo-abstract';

@Component({
    selector: 'enum-combo',
    template: `
        <nz-select nzShowSearch
                   nzServerSearch
                   nzAllowClear={{AllowClear}}
                   nzPlaceHolder="{{placeHolder}}"
                   [nzMode]="nzMode"  
                   [nzMaxTagCount]="nzMaxTagCount"
                   [(ngModel)]="_value"
                   [nzDisabled]='_isDisabled'
                   (nzFocus)="onFocus"
                   style="width:100%"
                   (ngModelChange)="onChangeValue($event)"
                   (nzOnSearch)="search($event)">
            <nz-option *ngFor="let item of optionList"
                       [nzLabel]="item.displayText"
                       [nzValue]="item.value"></nz-option>
        </nz-select>
    `,
    styles: [`
      .ant-select,.ant-select-multiple.ant-select-selector{
          height: auto !important;
      }
      .ant-select-multiple .ant-select-selector {
        height: auto !important;
}
   `],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EnumComboComponent),
        multi: true
    }]
})

export class EnumComboComponent extends BaseComboComponent implements OnInit, ControlValueAccessor {
    @Input() EnumCode: number = 0;
    @Input() nzMaxTagCount: any = 3;
    @Input() AllowClear: boolean = true;
    @Input() group: number;
    @Input() useCache: boolean = true;
    @Input() isMulti: boolean = false; // Thêm thuộc tính đầu vào để kiểm tra chế độ multi-select
    @Output() eventChange = new EventEmitter();

    nzMode: NzSelectModeType = 'default'; // Khởi tạo giá trị cho nzMode
    _value: any[] = []; // Đảm bảo rằng _value là mảng cho chế độ multi-select

    constructor(
        injector: Injector,
        private _commonService: CommonServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        // Thiết lập chế độ multiple nếu isMulti = true
        this.nzMode = this.isMulti ? 'multiple' : 'default';

        if (this.useCache) this.isUseCache = true;
        else this.isUseCache = false;

        this.getDataSource();
    }

    getDataSource() {
        let key = 'combo-enum-' + this.EnumCode;
        if (this.group) {
            key += '-' + this.group;
        }
        const cache = sessionStorage.getItem(key);
        if (cache && this.isUseCache) {
            let lst = JSON.parse(cache);
            this.setListOfOption(lst);
            this.addSelectedNotInRender();
            return;
        }
        this.getDataSourceFromServer(key);
    }

    getDataSourceFromServer(keyCache?: string) {
        this._commonService.getAppEnum(this.EnumCode, this.group)
            .subscribe((result) => {
                let data = _.map(result, (it) => {
                    return Object.assign({}, {
                        value: Number(it.value),
                        displayText: it.displayText,
                        fts: AppUtilityService.getFullTextSearch(it.displayText),
                    });
                });

                if (keyCache) {
                    sessionStorage.setItem(keyCache, JSON.stringify(data));
                }
                this.setListOfOption(data);
            });
    }

    onChangeValue(event) {
        this.onChange(event);

        if (!this.isMulti) {
            let obj = this.optionList.find((m) => m.value == event);
            this.eventChange.emit(obj);
        } else {
            if (Array.isArray(this._value)) {
                let ListChosen = this.optionList.filter((m) => this._value.includes(m.value));
                this.eventChange.emit(ListChosen);
            } else {
                this.eventChange.emit([]);
            }
        }
    }
}
