<ora-table #datatable [rows]="dataList" [isLoading]="isTableLoading" [totalRows]="totalItems" [(pageSize)]="pageSize"
    [(page)]="pageNumber" (pageSizeChange)="refresh()" (pageChange)="pageNumberChange()" (sortChange)="gridSort($event)"
    [(selectedDataItems)]="selectedDataItems" [scroll]="{ x: '600px', y:'calc(100vh - 56px)' }"
    (selectedDataItemsChange)="changeChooseChiSoBaoCao($event)">

    <ng-container actionTable *ngIf="selectedDataItems && selectedDataItems.length > 0">
        <div style="display: flex; align-items: center; height: 100%;">
            <b>Đã chọn: {{listChoose.length}}</b>
        </div>

    </ng-container>

    <ng-container titleTable>
        <label class="font-weight-bold">Danh sách chỉ số ({{ totalItems | oraNumber: '1.0-0':'en' }} bản ghi)</label>
    </ng-container>
    <ora-column header="STT" width="50" align="center" dataType="STT"></ora-column>
    <ora-column dataType="Select" width="50"></ora-column>
    <ora-column header="Chỉ số" key="ten" [sort]="true" [width]="getWidthMobile(250)"></ora-column>
    <ora-column header="Sắp xếp" type="number" key="sortOrder" [sort]="true" width="100"></ora-column>
    <ora-column header="Loại báo cáo" key="strLoaiBaoCao" width="250"></ora-column>
</ora-table>

<div *nzModalFooter>
    <button type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Thêm</button>
</div>
