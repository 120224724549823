import { Injectable } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ICD10Dto, ICD9Dto } from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ICD9EditModalComponent } from '../../icd9/icd9-edit-modal/icd9-edit-modal.component';
import { ICD9SearchModalComponent } from '../../icd9/icd9-search-modal/icd9-search-modal.component';
import { ICD10EditModalComponent } from '../icd10-edit-modal/icd10-edit-modal.component';
import { ICD10SearchModalComponent } from '../icd10-search-modal/icd10-search-modal.component';
import { ICD10SearchAdvanceComponent } from '../icd10-search-advent/icd10-search-advance.component';
import { ICD10SearchControlComponent } from '../icd10-search-control/icd10-search-control.component';
// import { ICD10EditModalComponent } from '../icd10-edit-modal/icd10-edit-modal.component';
// import { ICD9SearchModalComponent } from '../icd10-search-modal/icd9-search-modal.component';

@Injectable({
    providedIn: 'root'
})
export class ICD10UxService {

    constructor(private modalService: NzModalService) { }

    public openCreateOrEditModal(option: { dataItem: ICD10Dto; isView?: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = AppUtilityService.isNullOrWhiteSpace(option.dataItem.ma) ? 'Thêm mới' : 'Cập nhật ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: ICD10EditModalComponent,
            nzComponentParams: {
                dataItem: Object.assign(new ICD10Dto(), { ...option.dataItem }),
                isView: option.isView,
            },
            nzWidth: '50%',
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result: ICD10Dto) => {
            if (result != null) {
                option.callBack(result);
            }
        });
    }
    public openSearchListModal(option: {
        listOfSelectedValue: number[];
        title?: string;
        isMulti?: boolean;
        callBack: any;
    }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: ICD10SearchControlComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue],
                //isMulti: option.isMulti,
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '80%',
        });

        modal.afterClose.subscribe((result: ICD10Dto[]) => {
            // if (result != null && result.length > 0) {
            //     option.callBack(result);
            // }
            if (result) {
                option.callBack(result);
            }
        });
    }

}
