<section (keydown.enter)="searchEvent.emit(searchModel)" class="section-search">
    <div nz-row nzLayout="horizontal">
        <div nz-col class="gutter-row ord-form-control" [nzSm]="(searchLayout.controlWidth != null ?  searchLayout.controlWidth : 20)" [nzXs]="(searchLayout.controlWidth != null ?  searchLayout.controlWidth : 20)">
            <div class="row main-search-box">
                <div class="col-md-12">
                    <ord-dynamic-form #normalSearch [listOfFormItems]="searchLayout.groups" [focusFirstItem]="false" [dataForm]="searchModel" (dataFormChange)="onChangeDataForm($event)" (searchEvent)="searchEvent.emit(searchModel)" (refreshFilterEvent)="refreshFilterEvent.emit()"
                        (resetSearchEvent)="resetSearch()" [hasBtnSearchBottom]="hasBtnSearchBottom" style="width: 100%">
                    </ord-dynamic-form>
                </div>
            </div>
            <!--tìm kiếm nâng cao -->
            <div class="advanced-search-box row" [hidden]="showAdvancedSearch === false">
                <div class="col-md-12">
                    <ord-dynamic-form [listOfFormItems]="searchLayout.advanceGroups" [focusFirstItem]="false" [dataForm]="searchModel" (searchEvent)="searchEvent.emit(searchModel)" (resetSearchEvent)="resetSearch()" (dataFormChange)="onChangeDataForm($event)" style="width: 100%"></ord-dynamic-form>
                </div>
            </div>

            <!-- <div class="row" *ngIf="hasAdvancedSearch">
        <div class="col-md-12">
          <a class="kt-font-info kt-font-bold" (click)="clickShowAdvancedSearch()" *ngIf="showAdvancedSearch === false"> <i class="fa fa-angle-double-down"></i> Hiện tìm kiếm nâng cao </a>
          <a class="kt-font-info kt-font-bold" (click)="clickHideAdvancedSearch()" *ngIf="showAdvancedSearch === true"> <i class="fa fa-angle-double-up"></i> Ẩn tìm kiếm nâng cao </a>
        </div>
      </div> -->
        </div>
        <div nz-col class="gutter-row ord-form-control" [nzXs]="searchLayout.controlWidth != null ?  (24 - searchLayout.controlWidth) : 4">
            <div class="group-control-search-box">


                <button (click)="showAdvancedSearch === false ? clickShowAdvancedSearch() : clickHideAdvancedSearch()" style="border-radius:  8px 0px 0px 8px" *ngIf="hasAdvancedSearch" class="mt-9 btn btn-sm btn-primary btn-search-event">
          <i class="fa fa-angle-double-down" *ngIf="showAdvancedSearch === false"></i>
          <i class="fa fa-angle-double-up" *ngIf="showAdvancedSearch === true"></i>
        </button>
                <button (click)="searchEvent.emit(searchModel)" class="mt-9 btn btn-sm btn-primary btn-search-event" style="border-radius: 0px">
          <i class="fas fa-search"></i>
        </button>
                <button (click)="resetSearch()" class="mt-9 btn btn-sm btn-secondary btn-refresh-event" style="margin-left: -1px;border-radius: 0px 8px 8px 0px;">
          <i class="fas fa-sync"></i>
        </button>

                <!--
                   <button *ngIf="hasAdvancedSearch" nz-button nzType="primary" class="mt-9" (click)="showAdvancedSearch === false ? clickShowAdvancedSearch() : clickHideAdvancedSearch()">
          <i class="fa fa-angle-double-down" *ngIf="showAdvancedSearch === false"></i>
          <i class="fa fa-angle-double-up" *ngIf="showAdvancedSearch === true"></i>
        </button>
                  <button nz-button nzType="primary" class="margin-top-31" (click)="searchEvent.emit(searchModel)">
          <i nz-icon nzType="search" nzTheme="outline"></i></button>
                <button nz-button nzType="default" class="margin-top-31" (click)="resetSearch()">
          <i nz-icon nzType="reload" nzTheme="outline"></i></button> -->
            </div>
        </div>
    </div>

</section>