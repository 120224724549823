import { Component, EventEmitter, Injector, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '@delon/theme';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CommonResultDtoOfThietLapHoTroSettingDto, CommonSettingsServiceProxy, TenantSettingsServiceProxy, ThietLapChungServiceProxy, ThietLapHoTroSettingDto } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
@Component({
    selector: 'layout-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent extends AppComponentBase implements OnInit {
    settingThietLapHoTro : ThietLapHoTroSettingDto = new ThietLapHoTroSettingDto();

    constructor(
        injector: Injector,
        private _commonSettingsService: ThietLapChungServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.getSetting();
    }

    getSetting() : void {
        abp.ui.setBusy();
        this._commonSettingsService.getThietLapHoTro()
            .pipe(finalize(() => {abp.ui.clearBusy()}))
            .subscribe((result: CommonResultDtoOfThietLapHoTroSettingDto) => {
                this.settingThietLapHoTro = result.dataResult;
            })
    }
    clickZalo() {
        window.open(this.settingThietLapHoTro.zalo)
    }
   
}
