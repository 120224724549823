import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  debounceTime,
  filter,
  takeUntil,
  tap,
} from '@node_modules/rxjs/internal/operators';
import {
  NzButtonShape,
  NzButtonSize,
  NzButtonType,
} from '@node_modules/ng-zorro-antd/button/button.component';
import { BehaviorSubject } from 'rxjs';
import { DestroyRxjsService } from '@shared/destroy-rxjs.service';

@Component({
  selector: 'ord-common-btn',
  template: ` <button
    nz-button
    style = "margin-left: 5px"
    [ngStyle]="{'border': nzTypeButton === 'default' ? '1px solid white' : 'none'}"
    type="button"
    [disabled]="disabled"
    [nzType]="nzTypeButton"
    [nzDanger]="nzDanger"
    [nzShape]="nzShape"
    [nzSize]="nzSize"
    (click)="onClick()"
    [nzLoading]="loading"
    [nzDanger]="isDanger"
    >
    <i *ngIf="nzIcon" nz-icon nzType="{{ nzIcon }}" nzTheme="outline"></i>
    <span *ngIf="text">{{ text  }}</span>
  </button>`,
  providers: [DestroyRxjsService],

})
export class CommonBtnComponent implements OnInit {

  @Input() nzTypeButton: NzButtonType = 'primary';
  @Input() nzDanger: boolean;
  @Input() nzShape: NzButtonShape;
  @Input() nzSize: NzButtonSize;
  @Input() nzIcon = '';
  @Input() text = '';
  @Input() loading = false;
  @Input() debounceTime = 50;
  @Input() disabled = false;
  @Input() isDanger = false;
  @Output() clickEvent = new EventEmitter();
  click$ = new BehaviorSubject<number>(0);

  constructor(private destroy$: DestroyRxjsService) {}

  onClick() {
    this.click$.next(Number(new Date()));
  }

  ngOnInit(): void {
    this.click$
      .pipe(
        filter(s => s > 0),
        debounceTime(this.debounceTime),
        tap(() => {
          this.clickEvent.emit();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
