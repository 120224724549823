import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { PagedListingComponentBase, PagedRequestDto } from '@shared/common/paged-listing-component-base';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { finalize } from '@node_modules/rxjs/operators';
import * as $ from 'jquery';
import * as _ from 'lodash';
import {
    DichVuKyThuatTuongDuongPagingListRequest,
    DichVuKyThuatTuongDuongServiceProxy
} from '@shared/service-proxies/service-proxies';
@Component({
    selector: 'dich-vu-ky-thuat-tuong-duong-search-modal',
    templateUrl: './dich-vu-ky-thuat-tuong-duong-search-modal.component.html',
    styleUrls: ['./dich-vu-ky-thuat-tuong-duong-search-modal.component.scss']
})
export class DichVuKyThuatTuongDuongSearchModalComponent extends PagedListingComponentBase<any> implements OnInit {
    @Input() listOfSelectedValue: number[];
    isMulti: boolean = false;
    constructor(
        injector: Injector,
        private modal: NzModalRef,
        private cdr: ChangeDetectorRef,
        private dichVuKyThuatTuongDuongServiceProxy: DichVuKyThuatTuongDuongServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.refresh();
    }

    clear() {
        this.searchDto = {};
        this.refresh();
    }

    protected fetchDataList(request: PagedRequestDto, pageNumber: number, finishedCallback: () => void): void {
        const input: DichVuKyThuatTuongDuongPagingListRequest = new DichVuKyThuatTuongDuongPagingListRequest();
        input.maxResultCount = request.maxResultCount;
        input.skipCount = request.skipCount;
        input.sorting = request.sorting;

        let rep: any = {
            ...input,
            ...this.searchDto,
        };

        this.dichVuKyThuatTuongDuongServiceProxy
            .dichVuKyThuatTuongDuongPagingList(rep)
            .pipe(finalize(finishedCallback))
            .subscribe((result) => {
                this.dataList = result.items;
                this.showPaging(result);
            });

    }

    save() {
        this.modal.close(this.selectedDataItems);
    }

    close() {
        this.modal.destroy();
    }

    //#region UX-JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion

}
