<div class="d-flex justify-content-between align-items-center">
    <div class="pageTitle col-6">
        <ng-content select="[pageTitle]"></ng-content>
    </div>
    <page-header [autoTitle]="false">
    </page-header>
</div>

<ng-content select="[pageAction]"></ng-content>

<ng-content select="[filterBox]"></ng-content>

<ng-content select="[tableList]"></ng-content>

<!-- CONTENT -->
<ng-content></ng-content>


