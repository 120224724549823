<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col class="gutter-row" nzXs="24" nzMd="24">
            <label>Module chức năng</label>
            <enum-combo [EnumCode]="ComboBoxEnumCode.ModuleDangKy" [(ngModel)]="dataItem.moduleEnum">
            </enum-combo>
        </div>
        <div nz-col class="gutter-row" nzXs="24" nzSm="24">
            <label class="font-weight-normal">Đợt tập huấn<span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.name" [disabled]="isView" #maLoai>
                <validation-custom [sModel]="dataItem.name"></validation-custom>
            </nz-form-control>
        </div>

        <div nz-col class="gutter-row ord-form-control" [nzSm]="12" [nzXs]="12">
            <label class="font-weight-normal">Ngày bắt đầu</label>
            <nz-form-control>
                <ora-date-picker [(ngModel)]="dataItem.tuNgay">
                </ora-date-picker>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="12" [nzXs]="12">
            <label class="font-weight-normal">Ngày kết thúc</label>
            <nz-form-control>
                <ora-date-picker [(ngModel)]="dataItem.denNgay">
                </ora-date-picker>
            </nz-form-control>
        </div>
    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
