import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BCT_DanhMucChiSoDto, BCTD_DanhMucChiSoDto } from '@shared/service-proxies/service-proxies';
import { DanhMucChiSoEditModalComponent } from '../modal/danh-muc-chi-so-edit.component';


@Injectable(
    {
        providedIn:'root'
    }
)
export class DanhMucChiSoUXService {
    constructor(private modalService: NzModalService) { }

    public openCreateOrEditModal(option: { dataItem: BCTD_DanhMucChiSoDto; isView: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: DanhMucChiSoEditModalComponent,
            nzComponentParams: {
                dataItem: Object.assign(new BCTD_DanhMucChiSoDto(), { ...option.dataItem }),
                isView:option.isView
            },
            nzMaskClosable: false,
            nzWidth: '720px'
        });

        modal.afterClose.subscribe((result: BCTD_DanhMucChiSoDto) => {
            option.callBack(result);
        });
    }

    public openSearchListModal(option: {
        listOfSelectedValue: number[];
        title?: string;
        isMulti?: boolean;
        callBack: any;
    }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
        //    nzContent: ChiSoBaoCaoTetSelectSearchModalComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue],
                isMulti: option.isMulti,
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: "60%"
        });

        modal.afterClose.subscribe((result: BCTD_DanhMucChiSoDto[]) => {
            if (result != null && result.length > 0) {
                option.callBack(result);
            }
        });
    }
}
