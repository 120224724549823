import { Component, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '@delon/theme';

@Component({
    selector: 'layout-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
    constructor(public settingsSrv: SettingsService,) {
    }

    get collapsed() {
        return this.settingsSrv.layout.collapsed;
    }
    toggleCollapsed() {
        this.settingsSrv.setLayout('collapsed', !this.collapsed);
    }
}
