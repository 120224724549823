import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ora-filter-spin',
    templateUrl: './ora-filter-spin.component.html',
    styleUrls: ['./ora-filter-spin.component.scss']
})
export class OraFilterSpinComponent implements OnInit {
    keyword = '';
    @Input() isTypeButtonSearch?= false;
    @Input() searchAfterEnter = true;
    @Input() isOnlySearch?= false;
    @Input() isShowTitleSearch?= false;
    @Input() hasAdvancedSearch?= false;
    @Input() titleInputSearch?= 'Tìm kiếm';
    @Input() widthColSeach?= 12;
    @Output() onSearch = new EventEmitter();
    @Output() onRefresh = new EventEmitter();
    @Input() showAdvancedSearch = true;
    checkScreen = window.innerWidth;
    constructor() { }

    ngOnInit(): void { }

    search() {
        this.onSearch.emit();
    }

    refresh() {
        this.onRefresh.emit();
    }

    onEnter() {
        if (this.searchAfterEnter) {
            this.search();
        }
    }

    clickShowHideAdvancedSearch() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }

    trackByIndex(index: number, el: any): number {
        return index;
    }
}
