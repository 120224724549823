import { Component, Injector, Input, SimpleChanges } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { DieuKienICD10CheckInputDto, DieuKienICD10ServiceProxy } from "@shared/service-proxies/service-proxies";
import { Observable } from "rxjs";
import { map, timeout } from "rxjs/operators";

@Component({
    selector: 'validation-icd-10',
    template: `
    <span #mySpan class="form-control-feedback"
    class="{{isHidden ? '' : 'custom-error-validate'}}" [hidden]="isHidden">{{errorMessage}}</span>`
}) export class ValidateICD10Component extends AppComponentBase {
    @Input() listMaICD10;
    @Input() tuoiNgay;
    @Input() tuoiThang;
    @Input() tuoiNam;
    @Input() gioiTinh;
    @Input() isValidUC;

    isHidden: boolean = true;
    errorMessage: string = "Mã ICD 10 không hợp lệ";

    constructor(
        injector: Injector,
        private _dataService: DieuKienICD10ServiceProxy,
    ) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isHidden = true;
    }

    checkValidate(): Observable<any> {
        const input = new DieuKienICD10CheckInputDto;
        input.gioiTinh = this.gioiTinh;
        input.tuoiThang = this.tuoiThang;
        input.tuoiNam = this.tuoiNam;
        input.tuoiNgay = this.tuoiNgay;
        input.isValidUC = this.isValidUC;
        input.listMaICD10 = this.listMaICD10;
        return this._dataService.dieuKienICD10Check(input)
        .pipe(map((val) =>{
            if(val.isSuccessful) {
                this.isHidden = true;
            } 
            else 
            {
                this.isHidden = false;
                this.errorMessage = val.errorMessage;
            }
            return val;},
        ));
    }
}