<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="8">
        <div nz-col class="gutter-row ord-form-control"  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Diễn biến tình huống<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.ma" >
            <validation-custom [sModel]="dataItem.ma"></validation-custom>
        </div>

        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Cấp độ nguy cơ-NC<span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <enum-combo [EnumCode]="ComboBoxEnumCode.CapDoNguyCo" [(ngModel)]="dataItem.capDoNguyCo" [disabled]="isView">
                </enum-combo>
                <validation-custom [sModel]="dataItem.capDoNguyCo"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Hình thức báo cáo <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <enum-combo [EnumCode]="ComboBoxEnumCode.HinhThucBaoCao" [(ngModel)]="dataItem.hinhThucBaoCao" [disabled]="isView">
                </enum-combo>
                <validation-custom [sModel]="dataItem.hinhThucBaoCao"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label class="font-weight-normal">Mô tả sự cố y khoa<span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <textarea rows="4" nz-input [(ngModel)]="dataItem.ten"></textarea>
                <validation-custom [sModel]="dataItem.ten"></validation-custom>
            </nz-form-control>
        </div>
        <div  nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label class="font-weight-normal">Kích hoạt</label>
            <nz-form-control>
                <nz-switch [(ngModel)]="dataItem.isActive" nzCheckedChildren="Có" nzUnCheckedChildren="Không">
                </nz-switch>
            </nz-form-control>
        </div>
    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" [nzLoading]="saving" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
