import { Component, forwardRef, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICD9UxService } from '@app/pages/danh-muc/icd9/services/icd9-ux.service';
import {
    ICD9Dto, ICD9PagingListRequest, ICD9ServiceProxy, DeleteMultiDtoOfInt64
} from '@shared/service-proxies/service-proxies';
import _ from 'lodash';
import { finalize, map } from 'rxjs/operators';
import { AppUtilityService } from '../../../common/custom/utility.service';
import { BaseComboComponent } from '../../_base/base-combo-abstract';

@Component({
    selector: 'icd9-combo-v2',
    template: ` <div class="ora-combo">
         <nz-select
                    nzShowSearch
                    nzServerSearch
                    [nzMaxTagCount]="maxTagCount"
                    [nzMaxTagPlaceholder]="maxTagPlaceholderTmpl"
                    [nzAllowClear]="nzAllowClear"
                    nzPlaceHolder="{{ placeHolders }}"
                    [nzMode]="nzMode"
                    [nzLoading]="loading"
                    [(ngModel)]="_value"
                    [nzDisabled]="_isDisabled"
                    (nzFocus)="(onFocus)"
                    style="width:100%"
                    (ngModelChange)="onChangeValue($event)"
                    (nzOnSearch)="search($event)"
                    [nzDropdownClassName]="'oda-select-dropdown'"
                    [nzDropdownRender]="renderTemplate"
                    [nzSuffixIcon]="prefixTemplateUser"
                >

                    <div *ngFor="let item of optionList" >
                        <nz-option *ngIf="item.data.loaiDataICD9 == 1" nzCustomContent  [nzLabel]="item.displayText"  [nzDisabled]="true">
                            <div>{{item.displayText}}</div>
                        </nz-option>
                        <nz-option *ngIf="item.data.loaiDataICD9 == 2" nzCustomContent  [nzLabel]="item.displayText"  [nzDisabled]="true">
                            <div style="margin-left:5px">{{item.displayText}}</div>
                        </nz-option>
                        <nz-option *ngIf="item.data.loaiDataICD9 == 3" nzCustomContent  [nzLabel]="item.displayText" [nzValue]="item.value" >
                            <div style="margin-left:10px">{{item.displayText}}</div>
                        </nz-option>
                        <nz-option *ngIf="item.data.loaiDataICD9 == 4" nzCustomContent  [nzLabel]="item.displayText" [nzValue]="item.value" >
                            <div style="margin-left:15px">{{item.displayText}}</div>
                        </nz-option>
                    </div>


                    <ng-template #renderTemplate>
                        <hr *ngIf="isSearchAdvance" />
                        <div *ngIf="isSearchAdvance">
                            <a nz-button nzType="link" (click)="searchAdvance()"
                                ><i nz-icon nzType="search" nzTheme="outline"></i> Tìm kiếm</a
                            >
                        </div>
                    </ng-template>

                    <ng-template #prefixTemplateUser><i nz-icon nzType="down" nzTheme="outline"></i></ng-template>
                </nz-select> 
               
        
        <ng-template #maxTagPlaceholderTmpl let-selectedList>+ {{ selectedList.length }} </ng-template>
    </div>`,
    styles: [
        `
            .ora-combo {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            .ora-combo nz-select {
                width: 100%;
            }

            .ora-close {
                cursor: pointer;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ICD9ComboComponent),
            multi: true,
        },
    ],
})
export class ICD9ComboComponent extends BaseComboComponent implements OnInit, ControlValueAccessor {
    @Input() placeHolders?: string = '- Chọn -';
    @Input() nzAllowClear?: boolean = true;
    maxOptionRender = 50;
    @Input() isAdd = false;
    @Input() isUpdate = false;
    @Input() isSearchAdvance = true;
    loading = false;

    constructor(
        injector: Injector,
        private _ICD9ServiceProxy: ICD9ServiceProxy,
        private _ICD9UxService: ICD9UxService,
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        this.loading = true;
        this._ICD9ServiceProxy
            .getAllToDDL()
            .pipe(
                map((list) => {
                    return list.map((item) => {
                        return Object.assign(item, {
                            fts: AppUtilityService.getFullTextSearch(item.displayText),
                        });
                    });
                }),
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe((result) => {
                this.setListOfOption(result);
                this.addSelectedNotInRender();
            });
    }

    searchAdvance() {
        this._ICD9UxService.openSearchListModal({
            listOfSelectedValue: this.isMulti ? [...this._value] : [this._value],
            title: 'Tìm kiếm ',
            isMulti: this.isMulti,
            callBack: (result: ICD9Dto[]) => {
                if (result != null && result.length > 0) {
                    if (this.isMulti) {
                        let listOfSelectedValueFromModal = result.map((x) => x.ma);

                        this.writeValue(listOfSelectedValueFromModal);
                    } else {
                        let selectedValueFromModal = result[0].ma;
                        this.writeValue(selectedValueFromModal);
                        this.addSelectedNotInRender();



                    }
                }
            },
        });
    }
}
