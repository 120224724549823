import { RootModule } from './root.module';
import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { preloaderFinished } from '@delon/theme';
import { NzSafeAny } from 'ng-zorro-antd/core/types';

import { environment } from './environments/environment';

preloaderFinished();

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(RootModule, {
        defaultEncapsulation: ViewEncapsulation.Emulated,
        preserveWhitespaces: false,
    })
    .then((res) => {
        const win = window as NzSafeAny;
        if (win && win.appBootstrap) {
            win.appBootstrap();
        }
        return res;
    })
    .catch((err) => console.error(err));
