<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Mã <span *ngIf="dataItem.loaiDataICD9==1">Chương</span><span
                    *ngIf="dataItem.loaiDataICD9==2">Nhóm</span>
                <span *ngIf="dataItem.loaiDataICD9==3">Bệnh</span> </label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.ma" [disabled]="isView" #maICD9>
                <validation-custom [sModel]="dataItem.ma" [nativeElement]="maICD9"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12" *ngIf="dataItem.loaiDataICD9!=1">
            <label class="font-weight-normal">Mã Cha <span *ngIf="dataItem.loaiDataICD9==2">Nhóm</span>
                <span *ngIf="dataItem.loaiDataICD9==3">Bệnh</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.maCha" [disabled]="isView">
            </nz-form-control>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Tên <span *ngIf="dataItem.loaiDataICD9==1">Chương</span><span
                    *ngIf="dataItem.loaiDataICD9==2">Nhóm</span>
                <span *ngIf="dataItem.loaiDataICD9==3">Bệnh</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.ten" [disabled]="isView">
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Tên Tiếng Anh <span *ngIf="dataItem.loaiDataICD9==1">Chương</span><span
                    *ngIf="dataItem.loaiDataICD9==2">Nhóm</span>
                <span *ngIf="dataItem.loaiDataICD9==3">Bệnh</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.tenTiengAnh" [disabled]="isView">
            </nz-form-control>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Ngày cập nhật <span *ngIf="dataItem.loaiDataICD9==1">Chương</span><span
                    *ngIf="dataItem.loaiDataICD9==2">Nhóm</span>
                <span *ngIf="dataItem.loaiDataICD9==3">Bệnh</span></label>
            <nz-form-control>
                <ora-date-picker [(ngModel)]="dataItem.ngayCapNhat" [disabled]="isView"> </ora-date-picker>
            </nz-form-control>
        </div>

    </div>
</div>


<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon
            nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>