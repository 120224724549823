<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[16,6]">
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Mã<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.ssi_class_type" [disabled]="isView">       
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="4" nzLg="4" nzXl="4">
            <!-- <label class="font-weight-normal">Mã</label> -->
            <input nz-input [(ngModel)]="dataItem.code" [disabled]="true" style="margin-top: 25px;">
         
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <label class="font-weight-normal">Mã bảo hiểm</label>
            <input nz-input [(ngModel)]="dataItem.insurance_code" [disabled]="isView">
         
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label class="font-weight-normal">Tên tiếng anh<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.name" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.name"></validation-custom>
        </div>
       
        <div nz-col  nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label class="font-weight-normal">Tên tiếng việt<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.name_vi" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.name_vi"></validation-custom>            
        </div>      
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
