<div class="root">
    <div class="title">
        <ng-container *nzStringTemplateOutlet="title">
            {{title}}
        </ng-container>
    </div>
    <div class="action">
        <ng-container *nzStringTemplateOutlet="action">
            {{action}}
        </ng-container>
    </div>    
</div>
<nz-card *ngIf="filter" class="ora-custom">
    <ng-container *ngTemplateOutlet="filter"></ng-container>
</nz-card>