<ora-filter-spin (onSearch)="refresh()" (onRefresh)="clear()" (keydown.enter)="refresh()" [widthColSeach]="12">

    <input nz-input class="txtSearch" [(ngModel)]="filter.filter" placeholder="Nhập từ khóa để tìm kiếm..." />
</ora-filter-spin>
<ora-table #datatable [rows]="dataList" [isMulti]="isMulti" [isLoading]="isTableLoading" [totalRows]="totalItems"
    [(pageSize)]="pageSize" [(page)]="pageNumber" (pageSizeChange)="refresh()" (pageChange)="pageNumberChange()"
    (sortChange)="gridSort($event)" [(selectedDataItems)]="selectedDataItems"
    [scroll]="{ x: '600px', y:'calc(100vh - 56px)' }">
    <ng-container titleTable>
        <label class="font-weight-bold">Danh sách ({{ totalItems | oraNumber: '1.0-0':'en' }} bản
            ghi)</label>
    </ng-container>

    <ora-column dataType="Select" width="50"></ora-column>
    <ora-column key="code" header="Mã" [width]="getWidthMobile(100)" cellAlign="center"></ora-column>
    <ora-column key="ten" header="Tên" [width]="getWidthMobile(150)"></ora-column>
    <ora-column key="tenDayDu" [width]="getWidthMobile(250)" header="Tên đầy đủ"></ora-column>
    <ora-column key="strCapDo" [width]="getWidthMobile(150)" header="Cấp"></ora-column>
    <ora-column header="Hoạt động" width="100" cellAlign="center">
        <ng-template oraCell let-data>
            <status-active-control [isActive]="data.isActive"></status-active-control>
        </ng-template>
    </ora-column>
</ora-table>

<ng-container>
    <nz-form-item *nzModalFooter>
        <nz-form-control>
            <div>
                <btn-add-item typeBtn="secondary" (clickEvent)="close()" icon="close" title="Đóng" text="Đóng">
                </btn-add-item>
                <btn-add-item *ngIf="selectedDataItems.length>0" typeBtn="primary" (clickEvent)="save()" icon="save"
                    title="Chọn" text="Chọn">
                </btn-add-item>
            </div>
        </nz-form-control>
    </nz-form-item>
</ng-container>