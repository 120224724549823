import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ModalHelper } from '@delon/theme';
import { AppConsts } from '@shared/AppConsts';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { v4 as uuid } from 'uuid';
import { ViewPdfModalComponent } from './modal/view-pdf.component';
const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

@Component({
    selector: 'ora-file-upload-control',
    template: `
    <div class="clearfix" style="display: block;">
    <p *ngIf="isView && isShowTitle" style="margin: 0px;
    color: #392c86 !important;
    font-size: 18px !important;
    font-weight: bold;">{{sTitle}}</p>
      <nz-upload [nzMultiple]="isMultiple"  [nzAccept]="sAcceptFile" class="uploadfile-control ant-upload-{{isView?'hideBox':''}}"
      [nzAction]="uploadUrl" [(nzFileList)]="fileList" (nzChange)="handleChange($event)" [nzBeforeUpload]="beforeUpload" [nzFileListRender]="fileListTpl">
      <ng-container *ngIf="!isImage && !isView && (!sLimit || sLimit>1 || (sLimit == 1 && sModel.length ==0 ))">
        <button nz-button><i nz-icon nzType="upload"></i>{{sTitle}}</button>
        <span class="ml-2"><i>({{sAcceptFile}})</i></span>
      </ng-container>

      <ng-container *ngIf="isImage && sModel== null">
        <div class="img-logo-tenant">
            <img src="{{profilePicture}}" style="width: 100%;height: 116px;object-fit: cover;">
        </div>
      </ng-container>
      <ng-container *ngIf="isImage && sModel!= null">
        <div class="img-logo-tenant">
            <img src="{{gotoViewImage()}}" style="width: 100%;height: 116px;object-fit: cover;">
            <div class="control-action-upload-file">
                <a (click)="removeFile(0)" style="font-size: 20px;" class="font-red" nzDanger nz-tooltip="Xóa" *ngIf="!isView">
                    <i nz-icon nzType="close" nzTheme="outline"></i>
                </a>
           </div>
        </div>
      </ng-container>

    </nz-upload>
    <ng-template #fileListTpl let-list>
    <!-- <div style="width: calc(100vh - -140px)!important;"> -->
    <div style="width: 100% !important;">
       <ul class="ul-file-upload" *ngIf="!isImage && !isHide">
         <li *ngFor="let item of list;index as idx" class="li-file-upload">
           <span>{{ item.name }}</span>
           <div class="control-action-upload-file">
             <a (click)="viewFile(item)" nz-tooltip="Xem"><i nz-icon nzType="eye" nzTheme="outline"></i></a>
             <a (click)="downLoadFile(item)" nz-tooltip="Tải xuống"><i nz-icon nzType="download" nzTheme="outline"></i></a>
             <a (click)="removeFile(idx)" nzDanger nz-tooltip="Xóa" *ngIf="!isView"><i nz-icon nzType="close" nzTheme="outline"></i></a>
           </div>
         </li>
       </ul>
    </div>
   </ng-template>
    </div>
  `,
    styles: [
        `
        .ant-upload{
            width:100%;
        }
     .ul-file-upload{
      padding-left: 5px;
     }
       .ul-file-upload .li-file-upload {
          display: inline-block;
          width: 100%;
          border-bottom: 1px dashed #bfbfbf;
          padding-bottom: 3px;
          margin-top: 12px;
        }
        .ul-file-upload .li-file-upload .control-action-upload-file{
           float: right;
        }
        .ul-file-upload .li-file-upload a{
           margin-left: 12px;
        }

        .tab-span {
            margin-left: 6px;
            display: inline-block;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            text-align: center;
            line-height: 26px;
            background: #5fa11d;
            color: #fff;
            font-size: 13px;
        }
        .img-logo-tenant .control-action-upload-file{
            display: none;
            position: absolute;
        }
        .img-logo-tenant:hover .control-action-upload-file{
            display: block;
            position: absolute;
            top:0px;
            right: 0px;
        }
  `,
    ],
})

export class OraFileUploadControlComponent implements OnChanges, AfterViewInit {
    @Input() isMultiple = false;
    @Input() isImage = false;
    @Input() sAcceptFile = '';
    @Input() sLimit?: number;
    @Input() sTitle = 'File đính kèm';
    @Input() sModel: any;
    @Input() isView?= false;
    @Input() isShowTitle?= true;
    @Input() isHide?= false;
    @Output() sModelChange = new EventEmitter()
    @Output() eventChange = new EventEmitter()
    fileList: NzUploadFile[] = [];
    uploadUrl: string = '';
    modalHelper: ModalHelper;
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/no-image.jpg';

    constructor(injector: Injector,
        private modalService: NzModalService,
    ) {
        this.modalHelper = injector.get(ModalHelper);

    }
    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sModel && !this.isImage) {
            this.fileList = changes.sModel.currentValue;
        }
    }

    ngAfterViewInit() {
        this.uploadUrl = AppConsts.remoteServiceBaseUrl + `/api/FileUpload/UploadFile`;
        if (!this.isImage) {
            this.fileList = this.sModel;
        }
    }

    handlePreview = async (file: NzUploadFile): Promise<void> => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj!);
        }
    };

    Guid = uuid();

    beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]) => {
        this.sModel = this.Guid;
        this.Guid = uuid();
        return;
    };

    arrFileAfterUpload = [];
    handleChange(info: any): void {
        this.arrFileAfterUpload = [];
        // 2. read from response and show file link
        if (info.file.status == 'error') {
            info.file.message = info.file.error.error.message;
        }

        info.fileList.forEach((item) => {
            if (item.uid == info.file.uid) {
                item.message = info.file.message;
            }
            let res = item.response?.result?.lstDataResult;
            if (res != null) {
                this.arrFileAfterUpload.push(res[0]);
            }
            else {
                this.arrFileAfterUpload.push(item);
            }

        });
        if (!this.isImage) {

            this.sModelChange.emit(this.arrFileAfterUpload);
            this.eventChange.emit(info);
        }
        else {
            this.sModelChange.emit(this.arrFileAfterUpload[0].path);
        }
    }

    downLoadFile(item: any) {
        const url = AppConsts.remoteServiceBaseUrl + '/api/FileUpload/DownloadFileByFileName?fileName=' + item.name;
        location.href = url;
    }

    removeFile(idx) {
        this.arrFileAfterUpload = [];
        this.fileList.splice(idx, 1);
        this.fileList.forEach((item) => {
            let res = item.response?.result?.lstDataResult;
            if (res != null) {
                this.arrFileAfterUpload.push(res[0]);
            }
            else {
                this.arrFileAfterUpload.push(item);
            }
        });
        this.sModelChange.emit(this.arrFileAfterUpload);
    }

    viewFile(item: any): void {
        abp.ui.setBusy();
        const path = AppConsts.remoteServiceBaseUrl + `/api/FileUpload/GoToViewTempFile?url=` + item.path;
        abp.ui.clearBusy();
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = 'Xem thông tin tài liệu';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: ViewPdfModalComponent,

            nzComponentParams: {
                path: path
            },
            nzFooter: null,
            nzWidth: "50%"
        });

        modal.afterClose.subscribe(result => {

        });

    }

    gotoViewImage() {
        if (this.sModel != null) {
            return AppConsts.remoteServiceBaseUrl + `/api/FileUpload/GoToViewTempFile?url=` + this.sModel;
        }

    }
}
