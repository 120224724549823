import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BCT_DotBaoCaoDto, BCTD_KyBaoCaoDto } from '@shared/service-proxies/service-proxies';
import { KyBaoCaoEditComponent } from '../edit/ky-bao-cao-edit.component';

@Injectable({
    providedIn: 'root',
})
export class KyBaoCaoUXService {
    constructor(private modalService: NzModalService) {}

    public openCreateOrEditModal(option: { dataItem: BCTD_KyBaoCaoDto; isView: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: KyBaoCaoEditComponent,
            nzComponentParams: {
                dataItem: Object.assign(new BCTD_KyBaoCaoDto(), { ...option.dataItem }),
                isView: option.isView,
            },
            nzMaskClosable: false,
            nzWidth: '80%',
        });

        modal.afterClose.subscribe((result: BCTD_KyBaoCaoDto) => {
            option.callBack(result);
        });
    }

    public openSearchListModal(option: { listOfSelectedValue: number[]; title?: string; isMulti?: boolean; callBack: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            //    nzContent: ChiSoBaoCaoTetSelectSearchModalComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue],
                isMulti: option.isMulti,
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '80%',
        });

        modal.afterClose.subscribe((result: BCTD_KyBaoCaoDto[]) => {
            if (result != null && result.length > 0) {
                option.callBack(result);
            }
        });
    }
}
