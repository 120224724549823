export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export enum TYPE_VALIDATE {
    Number = 1,
    Email = 2,
    Moment = 3,
    MinValue = 4,
    MaxValue = 5,
    PassWord = 6,
    Phone = 7,
    CheckDuplicates = 8,
    Dynamic = 9,
    Datetime = 10,
    CCCD = 11,
    MaBHYT = 12,
    ValidRef = 13,
    Array = 14,
    DateFormat = 15,
    DateTimeFormat = 16,
    RegexFormat = 16,
}


export enum DASHBOARD_TO_TRINH {
    SO_CV_MOI = 1,
    SO_VB_MOI = 2,
    SO_VB_CHO_PHAN_CONG_PB = 3,
    SO_VB_CHO_PHAN_CONG_CBXL = 4,
    SO_VB_CHO_TV = 5,
    SO_VB_CHO_PB_GOP_Y = 6,
    SO_VB_CHO_CB_GOP_Y = 7,
    SO_VB_CHO_CB_XU_LY = 8,
    TO_TRINH_CHO_PHO_PHONG_DUYET = 9,
    TO_TRINH_CHO_LANH_DAO_PHONG_DUYET = 10,
    TO_TRINH_CHO_PHO_GIAM_DOC_DUYET = 11,
    TO_TRINH_CHO_BAN_GIAM_DOC_DUYET = 12,
    TO_TRINH_CHO_TU_VAN = 13,
    TO_TRINH_CHO_BAN_HANH = 14,
    TO_TRINH_CHO_LANH_DAO_PHONG_GOP_Y = 15,
}

export enum ShowMode{
    List,
    ImportExcel,
    CreateEditView,
}