<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="16">
        <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="8">
            <label class="font-weight-normal">Loại báo cáo tiến độ<span class="text-red">*</span></label>
            <enum-combo (eventChange)="changeDotBaoCao($event)"  [EnumCode]="ComboBoxEnumCode.LoaiBaoCaoTienDo" [(ngModel)]="dataItem.loaiBaoCaoTienDoEnum">
            </enum-combo>
            <validation-custom [sModel]="dataItem.loaiBaoCaoTienDoEnum"></validation-custom>
        </div>

        <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="8">
            <label class="font-weight-normal">Kỳ báo cáo <span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.ten">
            <validation-custom [sModel]="dataItem.ten"></validation-custom>
        </div>

        <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="8">
            <label class="font-weight-normal">Năm báo cáo <span class="text-red">*</span></label>
            <ora-year-picker [(ngModel)]="dataItem.namBaoCao"></ora-year-picker>
            <validation-custom [sModel]="dataItem.namBaoCao"></validation-custom>
        </div>

        <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="8">
            <label class="font-weight-normal">Ngày bắt đầu <span class="text-red">*</span></label>
            <ora-date-picker [(ngModel)]="dataItem.ngayBatDau"></ora-date-picker>
            <validation-custom [sModel]="dataItem.ngayBatDau"></validation-custom>
        </div>

        <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="8">
            <label class="font-weight-normal">Ngày kết thúc <span class="text-red">*</span></label>
            <ora-date-picker [(ngModel)]="dataItem.ngayKetThuc"></ora-date-picker>
            <validation-custom [sModel]="dataItem.ngayKetThuc"></validation-custom>
        </div>

        <!-- <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="8">
            <label class="font-weight-normal">Kích hoạt</label>
            <div style="margin-top: 5px">
                <nz-switch [(ngModel)]="dataItem.isActive"> </nz-switch>
            </div>
        </div>
     -->
        <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label class="font-weight-normal">Ghi chú</label>
            <textarea rows="2" nz-input [(ngModel)]="dataItem.ghiChu"></textarea>
        </div>
      

        <div nz-col class="gutter-row" [nzSpan]="24">
            <label class="font-weight-normal">Cấu hình chỉ số</label>
            <nz-table nzTemplateMode #nzTablelstNgayBaoCao [nzScroll]="{ y: '650px' }" nzBordered nzTableLayout="fixed">
                <thead>
                    <tr>
                        <th nzAlign="center" nzWidth="70px" nzLeft>STT</th>
                        <th nzAlign="center" nzWidth="150px">Mã</th>
                        <th nzAlign="center" nzWidth="450px">Tên</th>
                        <th nzAlign="center" nzWidth="100px">Số thứ tự</th>
                        <!-- <th nzAlign="center" nzWidth="100px" nzRight>Thao tác</th> -->
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of dataItem.listKyBaoCaoChiSo;index as idx">
                        <td nzAlign="center" nzLeft>
                            {{idx+1}}
                        </td>
                        <td>
                            <input nz-input [(ngModel)]="item.ma" disabled="true">
                        </td>
                        <td>
                            <input nz-input [(ngModel)]="item.ten" disabled="true">
                        </td>
                        <td>
                            <nz-input-number style="height: 36px !important" [(ngModel)]="item.soThuTu" disabled="true"></nz-input-number>
                        </td>
<!-- 
                        <td nzAlign="center" nzRight>
                            <button nz-button nzDanger nzSize="small" (click)="xoaChiSo(idx)">
                                <i nz-icon nzType="delete"></i>
                            </button>
                        </td> -->
                    </tr>
                </tbody>

                <!-- <tfoot>
                    <tr>
                        <td colspan="5">
                            <ord-add-new-btn nzSize="small" (click)="themChiSo()"></ord-add-new-btn>
                        </td>
                    </tr>
                </tfoot> -->
            </nz-table>
        </div>
    </div>
    
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>