import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { AppComponent } from '@app/app.component';
import { NotificationsComponent } from '@app/shared/layout/notifications/notifications.component';

const routes: Routes = [
    {
        path: 'app',
        component: AppComponent,
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        data: {
            breadcrumb: 'Trang chủ',
        },
        children: [
            {
                path: '',
                children: [
                    { path: 'notifications', component: NotificationsComponent },
                    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                ],
            },
            {
                path: 'main',
                loadChildren: () => import('./main/main.module').then((m) => m.MainModule), // Lazy load main module
                data: { preload: true },
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-co-so',
                loadChildren: () => import('./pages/quan-ly-co-so/quan-ly-co-so.module').then((m) => m.QuanLyCoSoModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'cong-khai-y-te',
                loadChildren: () => import('./pages/cong-khai-y-te/cong-khai-y-te.module').then((m) => m.CongKhaiYTeModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'bao-cao-tet',
                loadChildren: () => import('./pages/bao-cao-tet/bao-cao-tet.module').then((m) => m.BaoCaoTetModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-tu-vong',
                loadChildren: () => import('./pages/quan-ly-tu-vong/quan-ly-tu-vong.module').then((m) => m.QuanLyTuVongModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-gia-pxn',
                loadChildren: () => import('./pages/danh-gia-pxn/danh-gia-pxn.module').then((m) => m.DanhGiaPXNModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-gia-clbv',
                loadChildren: () => import('./pages/danh-gia-clbv/danh-gia-clbv.module').then((m) => m.DanhGiaCLBVModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'thanh-toan-drg',
                loadChildren: () => import('./pages/thanh-toan-drg/thanh-toan-drg.module').then((m) => m.ThanhToanDRGModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'kho-du-lieu-api',
                loadChildren: () => import('./pages/kho-du-lieu-api/kho-du-lieu-api.module').then((m) => m.KhoDuLieuAPIModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'su-co-y-khoa',
                loadChildren: () => import('./pages/su-co-y-khoa/su-co-y-khoa.module').then((m) => m.SuCoYKhoaModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'bao-cao-tien-do',
                loadChildren: () => import('./pages/bao-cao-tien-do/bao-cao-tien-do.module').then((m) => m.BaoCaoTienDoModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-amr',
                loadChildren: () => import('./pages/quan-ly-amr/quan-ly-amr.module').then((m) => m.QuanLyAMRModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-hai',
                loadChildren: () => import('./pages/quan-ly-hai/quan-ly-hai.module').then((m) => m.QuanLyHAIModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc',
                loadChildren: () => import('./pages/danh-muc/danh-muc.module').then((m) => m.DanhMucModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'he-thong',
                loadChildren: () => import('./pages/he-thong/he-thong.module').then((m) => m.HeThongModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            { path: '**', redirectTo: '/app/main/dashboard' },
        ],

    },
];

@NgModule({
    imports: [
        //RouterModule.forChild(routes)]
        RouterModule.forRoot(routes, {
            useHash: false,
            // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
            // Pls refer to https://ng-alain.com/components/reuse-tab
            // scrollPositionRestoration: 'top',
            scrollPositionRestoration: 'disabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router) { }
}
