<div nz-row nzGutter="4">
  <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
    <label class="font-weight-normal">Chọn tệp dữ liệu Excel </label>
    <nz-form-control>
      <btn-upload-file #uploadBtn [(fileHopLe)]="fileHopLe" (selectFileSuccessEvent)="readFileHandler($event)"
        [listOfLoaiFile]="['xlsx', 'xls']"></btn-upload-file>
      <span class="font-weight-bolder" style="line-height: 40px; margin-left:15px;">
        <i class="fa fa-download kt-margin-r-5"></i>
        <a *ngIf="!IsCustomFromServer" [href]="'/assets/sampleFiles/' + urlSampleFile"> Tải file mẫu tại đây</a>
        <a *ngIf="IsCustomFromServer" (click)="eventChange.emit()"> Tải file mẫu tại đây</a>
      </span>
    </nz-form-control>
  </div>

</div>
<div nz-row nzGutter="4" class="mt-3">
  <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
    <p>
      <span class="text-danger font-weight-bolder  margin-right-5">(*)</span>
      Để upload dữ liệu được chính xác nhất, vui lòng điền thông tin theo mẫu file bên dưới. Xin cảm ơn!
    </p>
  </div>
</div>