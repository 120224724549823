import {
    Component,
    OnInit,
    Optional,
    Inject,
    Input,
    Provider,
    forwardRef,
    EventEmitter,
    Output,
    OnDestroy, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges,
} from '@angular/core';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, Form, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { NzDatePickerComponent } from '@node_modules/ng-zorro-antd/date-picker';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import { ComboBoxEnumCode, LoaiNgaySinh } from '@shared/service-proxies/service-proxies';
const VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OraDayYearPickerComponent),
    multi: true,
};

@Component({
    selector: 'ora-day-year-picker',
    template: `
        <div nz-row nzGutter="2" [nzGutter]="8" class="main-ora-range-date-picker">
            <nz-input-group nz-input>
            <nz-select  nzAllowClear [(ngModel)]="dayOrYear">
                <nz-option [nzLabel]="'Năm'" nz-radio [nzValue]="LoaiNgaySinh.Nam"></nz-option>
                <nz-option [nzLabel]="'Ngày'" [nzValue]="LoaiNgaySinh.Ngay"></nz-option>
            </nz-select>
                <ora-year-picker *ngIf="dayOrYear == LoaiNgaySinh.Nam" [(ngModel)]="dateOfBirth">
                </ora-year-picker>
                <ora-date-picker *ngIf="dayOrYear == LoaiNgaySinh.Ngay" [(ngModel)]="dateOfBirth" >
                </ora-date-picker > 
            </nz-input-group> 
        </div>
    `,
    styles: [`
       
    `],
    providers: [VALUE_ACCESSOR],
})
export class OraDayYearPickerComponent implements OnChanges, AfterViewInit {
    @Input() dayOrYear?: any;
    @Input() Year?: Date;
    @Input() Day?: Date;
    @Input() dateOfBirth?: Date;
    @Input() titleFromDate?: string = 'Từ ngày';
    @Input() titleEndDate?: string = 'Đến ngày';
    @Input() placeHolderStartDate = 'Ngày/Tháng/Năm';
    @Input() placeHolderEndDate = 'Ngày/Tháng/Năm';
    ComboBoxEnumCode = ComboBoxEnumCode;
  LoaiNgaySinh = LoaiNgaySinh;

    constructor() { }
    numberNow = Number(new Date());
    now = new Date(2500, 12, 31);
    itemsId = 0;
    lstConfigDate = [
     

    ];

    ngOnInit() {
        
    }

    ngOnChanges(changes: SimpleChanges): void {
        //this.startDateRangeChanges();
    }

    ngAfterViewInit(): void {

    }


}
