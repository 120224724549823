import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { AppUtilityService } from "@app/shared/common/custom/utility.service";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CKYTThongTinCoSoByIdInput, CapDoDonViHanhChinh, CoSoDto, ComboBoxEnumCode, ComboBoxTableCode, LEVEL, LoaiHinhQuanLy, ThongTinCoSoServiceProxy, ThongTinGiuongBenhDto, ThongTinHanhChinhServiceProxy, ThongTinLienHeLanhDaoDto, ThongTinLienHePhongBanDto } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'thong-tin-co-so',
    templateUrl: './thong-tin-co-so.component.html',
    styleUrls: ['./thong-tin-co-so.component.scss'],
})
export class ThongTinCoSoComponent extends AppComponentBase implements OnInit {
    formId = 'formThongTinCoSo';
    @Input() congKhaiYTeId;
    @Input() coSoId;
    @Input() isView;
    @Output() backEvent = new EventEmitter();

    ComboBoxTableCode = ComboBoxTableCode;
    ComboBoxEnumCode = ComboBoxEnumCode;
    CapDoDonViHanhChinh = CapDoDonViHanhChinh;
    LoaiHinhQuanLy = LoaiHinhQuanLy;
     dataItem: CoSoDto;
    isCSKCB = true;
    
    urlApi = AppConsts.remoteServiceBaseUrl;
    baseUploadUrl: string = AppConsts.remoteServiceBaseUrl + '/api/FileUpload/UploadFile';
    baseViewUrl: string = AppConsts.remoteServiceBaseUrl + '/api/FileUpload/GoToViewUserUpload';
    baseDownloadUrl: string = AppConsts.remoteServiceBaseUrl + '/api/FileUpload/DownloadFileUser';

    constructor(
        injector: Injector,
        private dataService: ThongTinHanhChinhServiceProxy,
        private thongTinCoSoService: ThongTinCoSoServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.initData();
        this.getData();
    }

    initData() {
        if (!this.dataItem) this.dataItem = new CoSoDto();
        if (!this.dataItem.thongTinGiuongBenh) this.dataItem.thongTinGiuongBenh = new ThongTinGiuongBenhDto();
        if (!this.dataItem.listThongTinLienHeLanhDao) this.dataItem.listThongTinLienHeLanhDao = [];
        if (!this.dataItem.listThongTinLienHePhongBan) this.dataItem.listThongTinLienHePhongBan = [];
        if (this.dataItem.fileGPHD = "[]") this.dataItem.fileGPHD = undefined;
        switch(this.dataItem.level) {
            case LEVEL.TRUNG_TAM_Y_TE :
            case LEVEL.TRAM_Y_TE:
            case LEVEL.TRUNG_TAM_Y_TE_HAI_CHUC_NANG:
            case LEVEL.BENH_VIEN:
            case LEVEL.PHONG_KHAM:
            case LEVEL.CO_SO_DICH_VU_Y_TE:
                this.isCSKCB = true;
                break
            default:
                this.isCSKCB = false;
                break;

        }
    }

    getData() {
        abp.ui.setBusy();
        let input = new CKYTThongTinCoSoByIdInput();
        input.coSoId = this.coSoId;
        input.congKhaiYTeId = this.congKhaiYTeId;
        this.dataService.thongTinCoSoById(input)
            .pipe(finalize(() => { abp.ui.clearBusy() }))
            .subscribe(result => {
                if (result.isSuccessful) {
                    this.dataItem = result.dataResult;
                    this.initData();
                } else {
                    this.notify.error(this.l('HasError'));
                }
            })
    }

    addLienHeLanhDao() {
        this.dataItem.listThongTinLienHeLanhDao.push(new ThongTinLienHeLanhDaoDto());
    }

    addLienHePhongBan() {
        this.dataItem.listThongTinLienHePhongBan.push(new ThongTinLienHePhongBanDto());
    }

    xoaItem(idx, list: any) {
        list.splice(idx, 1);
    }

    save() {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error('Bạn cần nhập đủ dữ liệu các trường có dấu * đỏ !!');
            return;
        }
        abp.ui.setBusy();

        this.thongTinCoSoService
            .createOrUpdateCoSo(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful && result.dataResult) {
                    abp.notify.success("Lưu thành công");
                    this.backEvent.emit();
                } else {
                
                    this.notify.error(this.l('HasError'));
                    abp.notify.error(result.errorMessage);
                    
                }

            });
    }
    close() {
        this.backEvent.emit();
    }

}