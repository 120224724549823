<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col nzXs="18" nzSm="18">
            <label class="font-weight-normal">Tên chức vụ<span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.ten" [disabled]="isView" #maLoai>
                <validation-custom [sModel]="dataItem.ten"></validation-custom>
            </nz-form-control>
        </div>
    
        <div nz-col nzXs="6" nzSm="6">
            <label class="font-weight-normal">Kích hoạt</label>
            <nz-form-control>
                <nz-switch [(ngModel)]="dataItem.isActive" nzCheckedChildren="Có" nzUnCheckedChildren="Không">
                </nz-switch>
            </nz-form-control>
        </div>
    
        <div nz-col nzXs="24" nzSm="24">
            <label class="font-weight-normal">Mô tả</label>
            <nz-form-control>
                <textarea rows="3" nz-input [(ngModel)]="dataItem.moTa"></textarea>
            </nz-form-control>
        </div>
    </div>
    
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
