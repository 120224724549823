<ora-filter-spin (onSearch)="refresh()" (onRefresh)="clear()" (keydown.enter)="refresh()" [widthColSeach]="12">
    <input nz-input class="txtSearch" [(ngModel)]="searchDto.filter" placeholder="Nhập từ khóa để tìm kiếm..." />
</ora-filter-spin>
<ora-table
    #datatable
    [rows]="dataList"
    [isLoading]="isTableLoading"
    [isMulti]="isMulti"
    [totalRows]="totalItems"
    [(pageSize)]="pageSize"
    [(page)]="pageNumber"
    (pageSizeChange)="refresh()"
    (pageChange)="pageNumberChange()"
    (sortChange)="gridSort($event)"
    [(selectedDataItems)]="selectedDataItems"
    [scroll]="{ x: '600px', y: 'calc(100vh - 56px)' }"
>
    <ng-container titleTable>
        <label class="font-weight-bold">Danh sách ({{ totalItems | oraNumber: '1.0-0':'en' }} bản ghi)</label>
    </ng-container>

    <ora-column header="STT" width="70" align="center">
        <ng-template oraCell let-data>
            {{ skipCount + dataList.indexOf(data) + 1 }}
        </ng-template>
    </ora-column>
    <ora-column dataType="Select" width="50"></ora-column>
    <ora-column key="ma" header="Mã Bệnh ICD9" width="150" cellAlign="center"></ora-column>
    <ora-column key="ten" header="Tên Bệnh ICD9" width="200"></ora-column>
    <ora-column key="tenTiengAnh" [width]="getWidthMobile(300)" header="Tên Tiếng Anh"></ora-column>
</ora-table>

<ng-container>
    <nz-form-item *nzModalFooter>
        <nz-form-control>
            <div>
                <btn-add-item typeBtn="secondary" (clickEvent)="close()" icon="close" title="Đóng" text="Đóng"> </btn-add-item>
                <btn-add-item
                    *ngIf="selectedDataItems.length > 0"
                    typeBtn="primary"
                    (clickEvent)="save()"
                    icon="save"
                    title="Chọn"
                    text="Chọn"
                >
                </btn-add-item>
            </div>
        </nz-form-control>
    </nz-form-item>
</ng-container>
