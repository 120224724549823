import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'btn-add-item',
    template: ` <button
        nz-button
        nzType="{{ typeBtn }}"
        [disabled]="disabled"
        [nzSize]="size"
        class="btn-custom-ora {{ sClass }}"
        (click)="clickEvent.emit()"
        style="margin-left: 7px;"
    >
        <i nz-icon *ngIf="!hideIcon" nzType="{{ icon }}" nzTheme="outline"></i> {{ text }}
    </button>`,
})
export class BtnAddItemComponent {
    @Input() typeBtn = 'primary';
    @Input() sClass = '';
    @Input() text = '';
    @Input() title = '';
    @Input() hideIcon = false;
    //@Input() icon = 'fa fa-plus';
    @Input() icon = '';
    @Input() disabled: any;
    @Input() hoyKey: string;
    @Input() size: string = window.innerWidth <= 576 ? 'xs' : 'sm';
    @Output() clickEvent = new EventEmitter();
}
