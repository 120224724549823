import { Component, Input, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
    selector: 'ora-change-position',
    templateUrl: './ora-change-position.component.html',
    styleUrls: ['./ora-change-position.component.scss']
})
export class OraChangePositionComponent implements OnInit {
    @Input() list: any[];
    @Input() index: number;
    @Input() startPosition: number = 0;

    ngOnInit(): void {
        
    }

    changePositionUp() {
        let listLength = this.list.length;
        if( listLength > 1) {
            if(this.index === this.startPosition) {
                this.swap(this.index, listLength -1)
            } else { 
                this.swap(this.index, this.index - 1)
            }
        }
    }   

    changePositionDown() {
        let listLength = this.list.length;
        if(listLength > 1) {
            if(this.index === listLength - 1) {
                this.swap(this.index, this.startPosition)
            } else { 
                this.swap(this.index, this.index + 1)
            }
        }
    }

    swap(index1, index2) {
        let temp = this.list[index1];
        this.list[index1] = this.list[index2];
        this.list[index2] =  temp;
    }

}