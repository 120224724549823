import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'view-pdf-modal',
  templateUrl: './view-pdf.component.html',
})
export class ViewPdfModalComponent implements OnInit {
  @Input() path;
  constructor() { }

  ngOnInit(): void { }
}
