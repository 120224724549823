import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { QLTV_ChiSoDto } from '@shared/service-proxies/service-proxies';
import { QLTV_ChiSoEditModalComponent } from '../modal/chi-so-edit-modal.component';

@Injectable({ providedIn: 'root' })
export class QLTV_ChiSoUXService {
    constructor(private modalService: NzModalService) { }

    public openCreateOrEditModal(option: { dataItem: QLTV_ChiSoDto; isView: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ';

        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: QLTV_ChiSoEditModalComponent,
            nzComponentParams: {
                dataItem: Object.assign(new QLTV_ChiSoDto(), { ...option.dataItem }),
                isView: option.isView
            },
            nzMaskClosable: false,
            nzWidth: '42%'
        });

        modal.afterClose.subscribe((result: QLTV_ChiSoDto) => {
            option.callBack(result);
        });
    }

    public openSearchListModal(option: {
        listOfSelectedValue: number[];
        title?: string;
        isMulti?: boolean;
        callBack: any;
    }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            //nzContent: ChiSoBaoCaoTetSelectSearchModalComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue],
                isMulti: option.isMulti,
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: "80%"
        });

        modal.afterClose.subscribe((result: QLTV_ChiSoDto[]) => {
            if (result != null && result.length > 0) {
                option.callBack(result);
            }
        });
    }
}
