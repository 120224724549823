
<div nz-dropdown class="alain-default__nav-item d-flex align-items-center px-sm"
     nzPlacement="bottomRight" [nzDropdownMenu]="userMenuTpl">
    <nz-avatar [nzSrc]="profilePicture" nzSize="small" class="mr-sm"></nz-avatar>
    <div class="mr-sm">
        <strong>{{shownLoginName}}</strong>
    </div>
</div>
<nz-dropdown-menu #userMenuTpl="nzDropdownMenu">
    <ul nz-menu>
        <ng-container *ngIf="isImpersonatedLogin">
            <li nz-menu-item (click)="backToMyAccount()">
                <i nz-icon nzType="undo"></i>
                Quay lại tài khoản chính
            </li>
            <li nz-menu-divider></li>
        </ng-container>

        <li nz-menu-item (click)="thietLapChuKySo()" *ngIf="isGrantedChuKySo">
            <i nz-icon nzType="setting"></i>
            Thiết lập chữ ký số
        </li>
        <li nz-menu-item (click)="clearSessionStore()">
            <i nz-icon nzType="delete" nzTheme="outline"></i>
            Xóa bộ nhớ đệm
        </li>
        <li nz-menu-item (click)="changePassword()">
            <i nz-icon nzType="key"></i>
            {{"ChangePassword" | localize}}
        </li>
        <li nz-menu-item (click)="showOpenDangKyBoSungTinhNang()" *ngIf="appSession.user?.coSoId>0">
            <i nz-icon nzType="sisternode"></i>
            Đăng ký bổ sung phân hệ
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-item (click)="logout()">
            <i nz-icon nzType="logout"></i>
            {{'Logout' | localize}}
        </li>
    </ul>
</nz-dropdown-menu>
