import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TaiKhoanNguoiDungSelectSearchModalComponent } from '../modal/tai-khoan-nguoi-dung-select-search-modal.component';
import { LoadModuleService } from '../../../../shared/services/load-module.service';
import { TaiKhoanNguoiDungEditModalComponent } from '../modal/tai-khoan-nguoi-dung-edit-modal.component';

@Injectable({ providedIn: 'root' })
export class TaiKhoanUXService {
    constructor(private modalService: NzModalService, private loadModule: LoadModuleService) {}

    public async openCreateOrEditModal(option: { userId?: number; callBack?: any }): Promise<void> {
        await this.loadModule.loadModuleHeThong();

        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.userId ? 'Cập nhật người dùng' : 'Thêm mới người dùng';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: TaiKhoanNguoiDungEditModalComponent,
            nzComponentParams: {
                userId: option.userId,
            },
            nzFooter: null,
            nzWidth: '992px',
        });

        modal.afterClose.subscribe((result: any) => {
            if (result != null) {
                option.callBack(result);
            }
        });
    }

    public openSearchListModal(option: { listOfSelectedValue: number[]; title?: string; isMulti?: boolean; callBack?: any; level?: any  }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách người dùng';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: TaiKhoanNguoiDungSelectSearchModalComponent,

            nzComponentParams: {
                listOfSelectedValue: _.cloneDeep(option.listOfSelectedValue),
                isMulti: option.isMulti,
                level : option.level
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '60%',
        });

        modal.afterClose.subscribe((result) => {
            if (result != null) {
                option.callBack(result);
            }
        });
    }
}
