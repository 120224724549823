<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="12">
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Lớp kháng sinh</label>
            <table-combo [TableEnum]="ComboBoxTableCode.GetAllLopKhangSinh"
            [(ngModel)]="dataItem.antibiotic_class_id" [disabled]="isView">
        </table-combo>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Phân lớp kháng sinh</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.name" [disabled]="isView">
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Trạng thái<span class="text-red">*</span></label>
            <nz-select [(ngModel)]="dataItem.activated" [disabled]="isView" style="width: 100%;" nzPlaceHolder="-Chọn-">
                <nz-option [nzValue]="true" nzLabel="Đang hoạt động"></nz-option>
                <nz-option [nzValue]="false" nzLabel="Dừng hoạt động"></nz-option>
            </nz-select>
            <validation-custom *ngIf="!isView" [sModel]="dataItem.activated"></validation-custom>
        </div>

    </div>

</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
