import { Component, Input, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { KieuDuLieu } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'dynamic-control',
    templateUrl: './dynamic-control.component.html',
    styleUrls: ['./dynamic-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DynamicControlComponent),
            multi: true,
        },
    ],

})
export class DynamicControlComponent implements OnInit, ControlValueAccessor {
    _value: any = undefined;
    _isDisabled = false;
    @Input() KieuDuLieu : KieuDuLieu;
    @Input() dictionaryTypeCode;
    @Input()
    get value() {
        return this.value;
    }

    set value(v: any) {
        this._value = v;
    }

    public onChange = (v: any) => {};
    private onTouched = () => {};

    ngOnInit(): void {
        
    }

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }
    



}