import { Component, Injector, Input, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ModalComponentBase } from "@shared/common/modal-component-base";
import { DanhSachICD10ServiceProxy } from "@shared/service-proxies/service-proxies";
import * as $ from 'jquery';
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, finalize } from "rxjs/operators";

@Component({
    selector: 'icd10-search-control',
    templateUrl: './icd10-search-control.component.html',
    styleUrls: ['./icd10-search-control.component.scss']
})
export class ICD10SearchControlComponent extends ModalComponentBase implements OnInit {
    @Input() listOfSelectedValue: number[];
    private searchSubscription: Subscription;
    private dataSubscription: Subscription;
    search$ = new Subject<string>();

    isLoading = false;
    searchDto;
    dataAPI: any;

    constructor(
        injector: Injector,
        private _DanhSachICD10ServiceProxy: DanhSachICD10ServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.subscripSearch()
    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }
    }

    clickEvent() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }

        this.search(true);
    }

    search(isClick = false) {
        this.isLoading = true;

        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
            this.dataSubscription = null;
        }
        
        this.dataSubscription = this._DanhSachICD10ServiceProxy.iCD10Search(this.searchDto)
            .pipe(finalize(() => {  }))
            .subscribe(val => {
                this.dataAPI = JSON.parse(val);
                this.isLoading = false;
                if(isClick) this.subscripSearch();
            })
    }

    subscripSearch() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }

        this.searchSubscription = this.search$.pipe(
            debounceTime(600),
            distinctUntilChanged()
        )
            .subscribe((result) => {
                if (result) this.search();
            });
    }

    clear() {
        this.searchDto = null;
        this.dataAPI = undefined;
        
    }

    chooseMaIcdSearch(event) {
        this.success(event);
    }


    //#region UX-JQuery
    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
}