import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICD9UxService } from '@app/pages/danh-muc/icd9/services/icd9-ux.service';
import {
    LstDictionaryDto, LstDictionaryServiceProxy, DeleteMultiDtoOfInt64
} from '@shared/service-proxies/service-proxies';
import _ from 'lodash';
import { finalize, map } from 'rxjs/operators';
import { AppUtilityService } from '../../../../common/custom/utility.service';
import { BaseComboComponent } from '../../../_base/base-combo-abstract';

@Component({
    selector: 'radio-list',
    template: ` <div class="ora-combo">
        <ng-container >        
            <nz-radio-group 
                        nzShowSearch
                        nzServerSearch
                        [(ngModel)]="sModel"
                        (ngModelChange)="onChangeValue($event)"                 
            >
                <div *ngFor="let item of arrData">
                    <label nz-radio [nzValue]="item.value">{{item.displayText}}</label>            
                </div>
            </nz-radio-group>
        </ng-container>
    </div>`,
  
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioListComponent),
            multi: true,
        },
    ],
})
export class RadioListComponent implements OnInit {
    @Output() sModelChange = new EventEmitter();
    @Input() sModel: string;
    arrData: any[] = [];
    loading = false;
    @Input() dictionaryTypeCode;

    constructor(
        private _LstDictionaryServiceProxy: LstDictionaryServiceProxy,
    ) {
    
    }

    ngOnInit() {
  
    }

    onChangeValue(event){
        this.sModel = event;
        this.sModelChange.emit(this.sModel);
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        this.loading = true;
        this._LstDictionaryServiceProxy
            .getToDDLByType(this.dictionaryTypeCode)
            .pipe(
                map((list) => {
                    return list.map((item) => {
                        return Object.assign(item, {
                            fts: AppUtilityService.getFullTextSearch(item.displayText),
                        });
                    });
                }),
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe((result) => {
                this.arrData=result
            });
    }


}
