<div class="oda-body-main" [id]="formId">
    <div nz-row nzLayout="horizontal">
        <div nz-col class="gutter-row " [nzSm]="12" [nzXs]="12" *ngIf="!dataItem.id">
            <label class="font-weight-normal">Sử dụng mã hệ thống tự sinh</label>
            <nz-form-control>
                <nz-switch name="IsActive" [(ngModel)]="dataItem.isDungMaHeThong" nzCheckedChildren="Có" nzUnCheckedChildren="Không"> </nz-switch>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row " [nzSm]="12" [nzXs]="12" *ngIf="!dataItem.isDungMaHeThong||dataItem.id>0">
            <label class="font-weight-normal">Mã  <span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.ma">
                <validation-custom [sModel]="dataItem.ma"></validation-custom>
            </nz-form-control>
        </div>
    </div>
    <div nz-row nzLayout="horizontal">


        <div nz-col class="gutter-row ord-form-control" [nzSm]="24" [nzXs]="24">
            <label class="font-weight-normal">Tên <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input nz-input [(ngModel)]="dataItem.ten">
                <validation-custom [sModel]="dataItem.ten"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="24" [nzXs]="24">
            <label class="font-weight-normal">Loại hình cơ sở  <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <enum-combo [EnumCode]="ComboBoxEnumCode.LEVEL" [(ngModel)]="dataItem.level" [disabled]="isView">
                </enum-combo>
                <validation-custom [sModel]="dataItem.level"></validation-custom>
            </nz-form-control>
        </div>
    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" [nzLoading]="saving" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
