import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { HinhThucToChucDto } from '@shared/service-proxies/service-proxies';
import { HinhThucToChucSelectSearchModalComponent } from '../modal/hinh-thuc-to-chuc-select-search-modal.component';
import { HinhThucToChucEditModalComponent } from '../modal/hinh-thuc-to-chuc-edit-modal.component';

@Injectable(
    {
        providedIn:'root'
    }
)export class HinhThucToChucUXService {
    constructor(private modalService: NzModalService) { }

    public openCreateOrEditModal(option: { dataItem: HinhThucToChucDto; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: HinhThucToChucEditModalComponent,
            nzComponentParams: {
                dataItem: Object.assign(new HinhThucToChucDto(), { ...option.dataItem }),
            },
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result: HinhThucToChucDto) => {
            option.callBack(result);
        });
    }

    public openSearchListModal(option: {
        listOfSelectedValue: number[];
        title?: string;
        isMulti?: boolean;
        callBack: any;
    }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: HinhThucToChucSelectSearchModalComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue],
                isMulti: option.isMulti,
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: "60%"
        });

        modal.afterClose.subscribe((result: HinhThucToChucDto[]) => {
            if (result != null && result.length > 0) {
                option.callBack(result);
            }
        });
    }
}
