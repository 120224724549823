import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LstDictionaryDto, LstDictionaryServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize, map } from 'rxjs/operators';
import { BaseComboComponent } from './base-combo-abstract';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { DictionaryUXService } from '@app/pages/he-thong/he-thong-danh-muc/services/dictionary-ux.service';

@Component({
    selector: 'dictionary-combo',
    template: ` <div class="ora-combo">
        
        <nz-select
            nzShowSearch
            nzServerSearch
            [nzAllowClear]="nzAllowClear"
            nzPlaceHolder="{{ placeHolders }}"
            [nzMode]="nzMode"
            [nzLoading]="loading"
            [(ngModel)]="_value"
            [nzDisabled]="_isDisabled"
            (nzFocus)="(onFocus)"
            style="width:100%"
            (ngModelChange)="onChangeValue($event)"
            (nzOnSearch)="search($event)"
            [nzDropdownClassName]="'oda-select-dropdown'"
            [nzDropdownRender]="renderTemplate"
            [nzSuffixIcon]="prefixTemplateUser"
        >
            <nz-option *ngFor="let item of optionList" [nzLabel]="item.displayText" [nzValue]="item.value"></nz-option>

            <ng-template #renderTemplate>
                <hr *ngIf="isAdd || isSearchAdvance" />
                <div *ngIf="isSearchAdvance">
                    <a nz-button nzType="link" (click)="searchAdvance()"><i nz-icon nzType="search" nzTheme="outline"></i> {{'_TimKiem' | localize}}</a>
                </div>
                <div *ngIf="isAdd">
                    <a nz-button nzType="link" (click)="openCreateOrEditModal()"
                        ><i nz-icon nzType="plus-circle" nzTheme="outline"></i>{{'_ThemMoi' | localize}}</a
                    >
                </div>
            </ng-template>

            <ng-template #prefixTemplateUser><i nz-icon nzType="down" nzTheme="outline"></i></ng-template>
        </nz-select>
        <button nz-button nzType="text" (click)="openCreateOrEditModal(_value)" *ngIf="isUpdate && _value && !disabled">
            <i nz-icon nzType="edit"></i>
        </button>
    </div>`,
    styles: [
        `
            .ora-combo {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            .ora-combo nz-select {
                width: 100%;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DictionaryComboComponent),
            multi: true,
        },
    ],
})

export class DictionaryComboComponent extends BaseComboComponent implements OnInit, ControlValueAccessor {
    @Input() placeHolders = '-' + this.l('_Chon') + '-';
    @Input() nzAllowClear?: boolean = true;
    maxOptionRender = 50;
    @Input() isAdd = false;
    @Input() isUpdate = false;
    @Input() isSearchAdvance = true;
    @Input() CodeSystemConst = '';
    loading = false;
    constructor(
        injector: Injector,
        private dictionaryServiceProxy: LstDictionaryServiceProxy,
        private dictionaryUXService: DictionaryUXService) {
        super(injector);
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        this.loading = true;
        this.dictionaryServiceProxy
            .getToDDLByType(this.CodeSystemConst)
            .pipe(
                map((list) => {
                    if (list) {
                        return list.map((item) => {
                            return Object.assign(item, {
                                fts: AppUtilityService.getFullTextSearch(item.displayText),
                            });
                        });
                    }
                }),
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe((result) => {
                this.setListOfOption(result);
                this.addSelectedNotInRender();
            });
    }

    openCreateOrEditModal(code?: string) {
        let _item = Object.assign(new LstDictionaryDto(), { code });

        this.dictionaryUXService.openCreateOrEditModal({
            dataItem: _item,
            lstDictionaryTypeCode: this.CodeSystemConst,
            callBack: (result: LstDictionaryDto) => {
                if (result) {
                    if (AppUtilityService.isNullOrWhiteSpace(code)) {
                        this.optionListSource.push({
                            value: result.code,
                            displayText: result.name,
                            fts: AppUtilityService.getFullTextSearch(result.name),
                            data: result,
                        });
                    } else {
                        var index = this.optionListSource.findIndex((x) => x.value == code);
                        if (index !== -1) {
                            this.optionListSource[index] = Object.assign(
                                {},
                                {
                                    value: result.code,
                                    displayText: result.name,
                                    fts: AppUtilityService.getFullTextSearch(result.name),
                                    data: result,
                                },
                            );
                        }
                    }

                    this.setListOfOption(this.optionListSource);
                    this.writeValue(result.code);
                    this.addSelectedNotInRender();
                }
            },
        });
    }

    searchAdvance() {
        this.dictionaryUXService.openSearchListModal({
            listOfSelectedValue: this.isMulti ? [...this._value] : [this._value],
            title: 'Tìm kiếm ',
            callBack: (result: LstDictionaryDto[]) => {
                if (result != null && result.length > 0) {
                    if (this.isMulti) {
                        let listOfSelectedValueFromModal = result.map(x => x.code);
                        this.writeValue(listOfSelectedValueFromModal);
                    } else {
                        let selectedValueFromModal = result[0].code;
                        this.writeValue(selectedValueFromModal);
                        this.addSelectedNotInRender();
                    }
                }
            },
        });
    }
}
