import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CoSoDto } from '@shared/service-proxies/service-proxies';
import { CoSoSelectSearchModalComponent } from '../modal/co-so-select-search/co-so-select-search-modal.component';
import { LoadModuleService } from '@app/shared/services/load-module.service';
import { ThongTinCoSoComponent } from '@app/pages/cong-khai-y-te/thong-tin-hanh-chinh/thong-tin-co-so/thong-tin-co-so.component';


@Injectable({
    providedIn: 'root',
})
export class CoSoUXService {
    constructor(private modalService: NzModalService,
        private loadModule: LoadModuleService) { }

    public async openCreateOrEditModal(option: { dataItem: CoSoDto; isView?: boolean; callBack?: any }): Promise<void> {
         //await this.loadModule.loadModuleQuanLyCoSo();
         //let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
         //let sTitle = option.isView ? 'Xem chi tiết' : (option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ');
         //const modal = this.modalService.create({
         //    nzTitle: sTitle + icon,
         //    nzContent: CoSoCreateEditModalComponent,
         //    nzComponentParams: {
         //        dataItem: Object.assign(new CoSoDto(), { ...option.dataItem }),
         //        isView: option.isView
         //    },
         //    nzMaskClosable: false,
         //    nzWidth: '80%',
         //});

         //modal.afterClose.subscribe((result: CoSoDto) => {
         //    option.callBack(result);
         //});
    }

    public async openSearchListModal(option: {
        listOfSelectedValue: number[];
        title?: string;
        isMulti?: boolean;
        coSoId?: number;
        callBack: any;
        isCoQuanChuQuan?: boolean;
        pId?: number;

        //Custom
        maTinh?: string;
        maHuyen?: string;
        maXa?: string;
        arrLevel?: any;
        isAMR?: boolean;
        isHAI?: boolean;

    }): Promise<void> {
        await this.loadModule.loadModuleQuanLyCoSo();
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: CoSoSelectSearchModalComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue],
                isMulti: option.isMulti,
                isCoQuanChuQuan: option.isCoQuanChuQuan,
                coSoId: option.coSoId,
                pId: option.pId,

                //Custom
                maTinh : option.maTinh,
                maHuyen: option.maHuyen,
                maXa: option.maXa,
                arrLevel: option.arrLevel,
                isAMR: option.isAMR,
                isHAI: option.isHAI

            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '80%',
        });

        modal.afterClose.subscribe((result: CoSoDto[]) => {
            if (result != null && result.length > 0) {
                option.callBack(result);
            }
        });
    }

    public async openViewCoSoModal(option: { dataItem: CoSoDto; isView?: boolean; callBack?: any }): Promise<void> {
        await this.loadModule.loadModuleQuanLyCoSo();
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.isView ? 'Xem chi tiết' : (option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ');
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: ThongTinCoSoComponent,
            nzComponentParams: {
                coSoId: option.dataItem.id,
                isView: option.isView
            },
            nzMaskClosable: false,
            nzWidth: '80%',
        });

        modal.afterClose.subscribe((result: CoSoDto) => {
            option.callBack(result);
        });
    }
}
