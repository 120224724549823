
<ora-filter-spin (onSearch)="refresh()" (onRefresh)="clear()" (keydown.enter)="refresh()" [widthColSeach]="12">
    <ng-container searchMain>
    </ng-container>
    <input nz-input [(ngModel)]="filter.filter" placeholder="Nhập từ khóa để tìm kiếm..." class="txtSearch" type="text" />
</ora-filter-spin>
<ora-table #datatable [rows]="dataList" [isMulti]="isMulti"  [isLoading]="isTableLoading"  [totalRows]="totalItems" [(pageSize)]="pageSize" [(page)]="pageNumber" (pageSizeChange)="refresh()" (pageChange)="pageNumberChange()" (sortChange)="gridSort($event)" [(selectedDataItems)]="selectedDataItems"
           [scroll]="{ x: '600px' }">
    <ora-column dataType="Select" width="50"></ora-column>

    <ora-column header="Tên đăng nhập" width="180" cellAlign="center">
        <ng-template oraCell let-data>
            <div> {{data.userName}}</div>
            <div *ngIf="data.lockoutEndDateUtc">
                <span title="Tài khoản đã bị khóa " class="font-red">
                    <i nz-icon nzType="lock" nzTheme="outline"></i>
                </span>: {{data.lockoutEndDateUtc| date:'dd/MM/yyyy HH:mm'}}
            </div>

        </ng-template>
    </ora-column>
    <ora-column key="name" header="Tên người dùng" width="150"></ora-column>
    <ora-column key="emailAddress" header="Địa chỉ Email" width="220"></ora-column>

    <ora-column header="Kích hoạt" width="100" align="center">
        <ng-template oraCell let-data>
            <status-active-control [isActive]="data.isActive"></status-active-control>
        </ng-template>
    </ora-column>
</ora-table>

<ng-container>
    <nz-form-item *nzModalFooter>
        <nz-form-control>
            <div>
                <btn-add-item typeBtn="secondary" (clickEvent)="close()" icon="close" title="Đóng" text="Đóng">
                </btn-add-item>
                <btn-add-item typeBtn="primary" (clickEvent)="save()" icon="save" title="Chọn" text="Chọn">
                </btn-add-item>
            </div>
        </nz-form-control>
    </nz-form-item>
</ng-container>
