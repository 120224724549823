import { Injectable, Injector } from "@angular/core";
import { ComboBoxTableCode } from "@shared/service-proxies/service-proxies";
import { QLTV_ChiSoUXService } from '@app/pages/quan-ly-tu-vong/chi-so/services/qltv-chi-so-ux.service';
import { DotBaoCaoUXService } from "@app/pages/bao-cao-tet/dot-bao-cao/services/dot-bao-cao-ux.service";
import { ChuyenKhoaUxService } from "@app/pages/danh-muc/chuyen-khoa/services/chuyen-khoa-ux.service";
import { ChiSoBaoCaoTetUXService } from "@app/pages/bao-cao-tet/chi-so-bao-cao/services/chi-so-bao-cao-tet-ux.service";
import { ICD9UxService } from "@app/pages/danh-muc/icd9/services/icd9-ux.service";
import { ICD10UxService } from "@app/pages/danh-muc/icd10/services/icd10-ux.service";
import { PhauThuatThuThuatUxService } from "@app/pages/danh-muc/phau-thuat-thu-thuat/services/phau-thuat-thu-thuat-ux.service";
import { DichVuKyThuatTuongDuongUxService } from "@app/pages/danh-muc/dich-vu-ky-thuat-tuong-duong/services/dich-vu-ky-thuat-tuong-duong-ux.service";
import { HinhThucToChucUXService } from "@app/pages/danh-muc/hinh-thuc-to-chuc/services/hinh-thuc-to-chuc-ux.service";
import { KhoaPhongUXService } from "@app/pages/quan-ly-co-so/danh-sach-khoa-phong/services/khoa-phong-ux.service";
import { ChucVuUXService } from "@app/pages/danh-muc/chuc-vu/services/chuc-vu-ux.service";
import { TinhHuongYKhoaUXService } from "@app/pages/danh-muc/tinh-huong-y-khoa/service/tinh-huong-y-khoa-ux.service";
import { DanhMucSuCoUXService } from "@app/pages/danh-muc/danh-muc-su-co/service/danh-muc-su-co-ux.service";
import { NhomSuCoUXService } from "@app/pages/danh-muc/nhom-su-co-phan-tich/service/nhom-su-co-ux.service";
import { NhomNguyenNhanUXService } from "@app/pages/danh-muc/nhom-nguyen-nhan/service/nhom-nguyen-nhan-ux.service";
import { DotBaoCaoQuyUXService } from "@app/pages/su-co-y-khoa/dot-bao-cao-quy/service/dot-bao-cao-quy-ux.service";
import { DanhMucChiSoUXService } from "@app/pages/bao-cao-tien-do/danh-muc-chi-so/service/danh-muc-chi-so-ux.service";
import { KyBaoCaoUXService } from "@app/pages/bao-cao-tien-do/ky-bao-cao/services/ky-bao-cao-ux.service";
import { AMRLopKhangSinhUxService } from "@app/pages/danh-muc/amr-lop-khang-sinh/service/amr-lop-khang-sinh-ux.service";
import { AMRPhanLopKhangSinhUxService } from "@app/pages/danh-muc/amr-phan-lop-khang-sinh/service/amr-phan-lop-khang-sinh-ux.service";
import { DotTapHuanUXService } from '../../../pages/he-thong/dot-tap-huan/services/dot-tap-huan-ux.service';
import { AMRChungLoaiViKhuanUxService } from "@app/pages/danh-muc/amr-chung-loai-vi-khuan/service/amr-chung-loai-vi-khuan-ux.service";
import { AMRViKhuanUxService } from "@app/pages/danh-muc/amr-vi-khuan/service/amr-vi-khuan-ux.service";
import { AMRNhomViKhuanUxService } from "@app/pages/danh-muc/amr-nhom-vi-khuan/service/amr-nhom-vi-khuan-ux.service";
import { HAIDanhMucPhauThuatUxService } from "@app/pages/danh-muc/hai-danh-muc-phau-thuat/service/hai-danh-muc-phau-thuat-ux.service";
import { AMRBenhPhamUxService } from "@app/pages/danh-muc/amr-benh-pham/service/amr-benh-pham-ux.service";

@Injectable({
    providedIn: 'root',
})
export class RegisterServicesKeyUX {
    constructor(
        public injector: Injector
    ) {
    }

    getService(enumKey: ComboBoxTableCode): any {
        if (enumKey == ComboBoxTableCode.GetAllChiSoQLTV) {
            return this.injector.get(QLTV_ChiSoUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllChiSoBaoCaoTet) {
            return this.injector.get(ChiSoBaoCaoTetUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllDotBaoCaoTet) {
            return this.injector.get(DotBaoCaoUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllChuyenKhoa) {
            return this.injector.get(ChuyenKhoaUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllDanToc) {
            return this.injector.get(ChuyenKhoaUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllICD9) {
            return this.injector.get(ICD9UxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllICD10) {
            return this.injector.get(ICD10UxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllICD10QLTV) {
            return this.injector.get(ICD10UxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllLoaiPhauThuatThuThuat) {
            return this.injector.get(PhauThuatThuThuatUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllMaTuongDuong) {
            return this.injector.get(DichVuKyThuatTuongDuongUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllHinhThucToChuc) {
            return this.injector.get(HinhThucToChucUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllLoaiKhoaPhong) {
            return this.injector.get(KhoaPhongUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllChucVu) {
            return this.injector.get(ChucVuUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllTinhHuongYKhoa) {
            return this.injector.get(TinhHuongYKhoaUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllDanhMucSuCo) {
            return this.injector.get(DanhMucSuCoUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllNhomSuCo) {
            return this.injector.get(NhomSuCoUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllNhomNguyenNhan) {
            return this.injector.get(NhomNguyenNhanUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllDotBaoCaoQuy) {
            return this.injector.get(DotBaoCaoQuyUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllDanhMucChiSo) {
            return this.injector.get(DanhMucChiSoUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllKyBaoCao) {
            return this.injector.get(KyBaoCaoUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllLopKhangSinh) {
            return this.injector.get(AMRLopKhangSinhUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllPhanLopKhangSinh) {
            return this.injector.get(AMRPhanLopKhangSinhUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllDotTapHuan) {
            return this.injector.get(DotTapHuanUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllChungLoaiViKhuan) {
            return this.injector.get(AMRChungLoaiViKhuanUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllNhomViKhuan) {
            return this.injector.get(AMRNhomViKhuanUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllViKhuan) {
            return this.injector.get(AMRViKhuanUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllKhangSinh) {
            return this.injector.get(AMRViKhuanUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllBenhVien) {
            return this.injector.get(AMRViKhuanUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllASAClass) {
            return this.injector.get(HAIDanhMucPhauThuatUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllPhanLoaiVeMo) {
            return this.injector.get(HAIDanhMucPhauThuatUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllSSIClassMucDoKhanCap) {
            return this.injector.get(HAIDanhMucPhauThuatUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllSSIClassICD10) {
            return this.injector.get(HAIDanhMucPhauThuatUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllSSIClassPatientOutCome) {
            return this.injector.get(HAIDanhMucPhauThuatUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllSSIClassProceduce) {
            return this.injector.get(HAIDanhMucPhauThuatUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllDanhMucTaiLieu) {
            return this.injector.get(DanhMucSuCoUXService);
        }
        if (enumKey == ComboBoxTableCode.GetAllNhomBenhPham) {
            return this.injector.get(HAIDanhMucPhauThuatUxService);
        }
        if (enumKey == ComboBoxTableCode.GetAllLoaiBenhPham) {
            return this.injector.get(AMRBenhPhamUxService);
        }
        //more
        return;
    }

}
