<div class="modal-header">
    <div class="modal-title">{{'MySettings' | localize}}</div>
</div>
<form nz-form #validateForm="ngForm" (ngSubmit)="save()">
    <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="Name">{{'Name' | localize}}</nz-form-label>
        <nz-form-control [nzErrorTip]="nameValidationTpl" [nzSm]="14" [nzXs]="24" nzHasFeedback>
            <input nz-input name="Name" #nameInput="ngModel" [(ngModel)]="user.name" required maxlength="32">
            <ng-template #nameValidationTpl let-control>
                <validation-messages [formCtrl]="control"></validation-messages>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="Surname">{{'Surname' | localize}}</nz-form-label>
        <nz-form-control [nzErrorTip]="surnameValidationTpl" [nzSm]="14" [nzXs]="24" nzHasFeedback>
            <input nz-input name="Surname" #surnameInput="ngModel" [(ngModel)]="user.surname" required maxlength="32">
            <ng-template #surnameValidationTpl let-control>
                <validation-messages [formCtrl]="control"></validation-messages>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="EmailAddress">{{'EmailAddress' | localize}}</nz-form-label>
        <nz-form-control [nzErrorTip]="emailAddressValidationTpl" [nzSm]="14" [nzXs]="24" nzHasFeedback>
            <input nz-input name="EmailAddress" #emailAddressInput="ngModel" [(ngModel)]="user.emailAddress"
                placeholder="{{'EmailAddress' | localize}}" autocomplete="email" required email maxlength="256">
            <ng-template #emailAddressValidationTpl let-control>
                <validation-messages [formCtrl]="control"></validation-messages>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="smsEnabled && !isPhoneNumberEmpty">
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="PhoneNumber">{{'PhoneNumber' | localize}}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
            <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
                <input type="text" nz-input name="PhoneNumber" [(ngModel)]="user.phoneNumber" placeholder="{{'PhoneNumber' | localize}}"
                    autocomplete="tel" maxlength="24">
            </nz-input-group>
            <ng-template #suffixButton>
                <button *ngIf="!isPhoneNumberConfirmed" nz-button nzType="primary" type="button" (click)="smsVerify()"
                    nz-tooltip [nzTooltipTitle]="l('YourPhoneNumberIsNotVerified')"><i nz-icon nzType="warning"></i>{{'Verify' | localize}}</button>
                <i *ngIf="isPhoneNumberConfirmed" nz-icon nzType="check" nz-tooltip [nzTooltipTitle]="l('YourPhoneNumberIsVerified')"></i>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="!smsEnabled || isPhoneNumberEmpty">
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="PhoneNumber">{{'PhoneNumber' | localize}}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
            <input nz-input name="PhoneNumber" [(ngModel)]="user.phoneNumber" placeholder="{{'PhoneNumber' | localize}}"
                autocomplete="tel" maxlength="24">
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="UserName">{{'UserName' | localize}}</nz-form-label>
        <nz-form-control [nzErrorTip]="userNameValidationTpl" [nzExtra]="userNameExtraTpl" [nzSm]="14" [nzXs]="24" nzHasFeedback>
            <input nz-input name="UserName" #userNameInput="ngModel" [(ngModel)]="user.userName" [disabled]="!canChangeUserName"
                required>
            <ng-template #userNameExtraTpl>
                <ng-container *ngIf="!canChangeUserName">{{'CanNotChangeAdminUserName' | localize}}
                </ng-container>
            </ng-template>
            <ng-template #userNameValidationTpl let-control>
                <validation-messages [formCtrl]="control"></validation-messages>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="showTimezoneSelection">
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="Timezone">
            {{"Timezone" | localize}}
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
            <timezone-combo name="Timezone" [(ngModel)]="user.timezone" defaultTimezoneScope="{{defaultTimezoneScope}}"></timezone-combo>
        </nz-form-control>
    </nz-form-item>
    <div class="modal-footer">
        <button nz-button type="button" [disabled]="saving" (click)="close()">
            {{"Cancel" | localize}}
        </button>
        <button nz-button [nzType]="'primary'" type="submit" [disabled]="!validateForm.valid||saving" [nzLoading]="saving">
            <i nz-icon nzType="save"></i> {{"Save" | localize}}
        </button>
    </div>
</form>