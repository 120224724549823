import { Component, ElementRef, Injector, Input, OnInit, Provider, SimpleChanges, ViewChild, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TYPE_VALIDATE } from "@shared/AppEnums";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { LoaiGiayToTuyThan } from "@shared/service-proxies/service-proxies";

const VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GiayToTuyThanComponent),
    multi: true,
};

@Component({
    selector: 'giay-to-tuy-than',
    template: `
        <input #refInput nz-input  [formControl]="control" [textMask]="{ mask: _mask }" class="{{ disabled ? 'disable' : '' }}"/>
        <validation-custom [isNotValidateNullOrEmpty]="true" [sType]="TYPE_VALIDATE.RegexFormat" [sModel]="inputMask" regexFormat="^((?!_).)*$"></validation-custom>
    `,
    styleUrls: ['./giay-to-tuy-than.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class GiayToTuyThanComponent implements OnInit, ControlValueAccessor {
    private _maQuocTich;
    @Input() set maQuocTich(value) {
        this._maQuocTich = value;
        if (this.loaiGiayToTuyThan === LoaiGiayToTuyThan.Ho_Chieu && this.maQuocTich === this.appSession.application.countryDefaultCode) {
            this._mask = this._maskHoChieu
        }
        else {
            this._mask = false;
        }

    };

    get maQuocTich() {
        return this._maQuocTich;
    }

    private _loaiGiayToTuyThan
    @Input() set loaiGiayToTuyThan(value) {
        if(this._loaiGiayToTuyThan != undefined && this._loaiGiayToTuyThan != null) {
            this.control.setValue(undefined);
        }
        this._loaiGiayToTuyThan = value;
        if (this.loaiGiayToTuyThan === LoaiGiayToTuyThan.Dinh_Danh_Ca_Nhan || this.loaiGiayToTuyThan === LoaiGiayToTuyThan.Can_Cuoc_Cong_Dan) {
            this._mask = this._maskCCCD
        }
        else if (this.loaiGiayToTuyThan === LoaiGiayToTuyThan.Chung_Minh_Thu_Nhan_Dan) {
            this._mask = this._maskCMTND
        }
        else if (this.loaiGiayToTuyThan === LoaiGiayToTuyThan.Ho_Chieu && this.maQuocTich === this.appSession.application.countryDefaultCode) {
            this._mask = this._maskHoChieu
        }
        else {
            this._mask = false;
        }
    };

    get loaiGiayToTuyThan() {
        return this._loaiGiayToTuyThan;
    }

    @Input() disabled = true;

    inputMask;
    TYPE_VALIDATE = TYPE_VALIDATE;
    LoaiGiayToTuyThan = LoaiGiayToTuyThan;

    appSession: AppSessionService;
    constructor(injector: Injector) {
        this.appSession = injector.get(AppSessionService);
    }
    ngOnInit(): void {
        this.control.valueChanges.subscribe(val => {
            this.inputMask = val;
            if (val) {
                let valueChange = val.replaceAll(" ", '').replaceAll("_", '').toUpperCase();
                this.control.setValue(valueChange, {emitEvent: false, onlySelf: true})
                this.onChange(valueChange);
            } else {
                this.onChange(undefined);
            }
        })
    }

    @ViewChild('refInput') private refInput!: ElementRef;
    control = new FormControl();
    _mask;
    _maskCCCD = [/\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/];
    _maskCMTND = [/\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/];
    _maskHoChieu = [/[A-Z]/i, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/, " ", /\d/];

    private onChange = (v: any) => { };
    private onTouched = () => { };

    writeValue(obj: any): void {
        this.control.setValue(obj);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

}