<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="8">
        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="6">
            <label class="font-weight-normal">Năm<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.nam">
            <validation-custom [sModel]="dataItem.nam"></validation-custom>
        </div>
        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="6">
            <label class="font-weight-normal">Quý<span class="text-red">*</span></label>
            <enum-combo [EnumCode]="ComboBoxEnumCode.DotBaoCaoQuy"
                        [(ngModel)]="dataItem.quy" [disabled]="isView">
            </enum-combo>
            <validation-custom [sModel]="dataItem.quy"></validation-custom>
        </div>

        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="12">
            <label class="font-weight-normal">Tên đợt báo cáo<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.tenDotBaoCao">
            <validation-custom [sModel]="dataItem.tenDotBaoCao"></validation-custom>
        </div>

        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Ngày bắt đầu<span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <ora-date-picker nz-input [(ngModel)]="dataItem.ngayBatDau">
                </ora-date-picker>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Ngày kết thúc<span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <ora-date-picker nz-input [(ngModel)]="dataItem.ngayKetThuc">
                </ora-date-picker>
            </nz-form-control>
        </div>

    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" [nzLoading]="saving" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
