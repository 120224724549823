import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class TreeDataHelperService {

    findNode(data, selector): any {
        //let nodes = _.filter(data, selector);
        let nodes = data.filter(m=>m.key == selector.data.key);
        if (nodes && nodes.length === 1) {
            return nodes[0];
        }

        let foundNode = null;

        _.forEach(data, d => {
            if (!foundNode) {
                foundNode = this.findNode(d._children, selector);
            }
        });

        return foundNode;
    }

    findParent(data, nodeSelector) {
        let node = this.findNode(data, nodeSelector);
        if (!node) {
            return null;
        }

        return node.parentNode;
    }

    findChildren(data, selector) {
        let traverseChildren = function (node) {
            let names = [];
            if (node._children) {
                _.forEach(node.children, c => {
                    names.push(c.origin.key);
                    names = names.concat(traverseChildren(c));
                });
            }
            return names;
        };

        let foundNode = this.findNode(data, selector);
        if (foundNode) {
            return traverseChildren(foundNode);
        } else {
            return [];
        }
    }
}
