<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[16,6]">
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Vi khuẩn<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.organism" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.organism"></validation-custom>
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">ORG Clean</label>
            <input nz-input [(ngModel)]="dataItem.org_clean" [disabled]="isView">
         
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal">Mã vi khuẩn<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.org" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.org"></validation-custom>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal">GRAM<span class="text-red">*</span></label>
            <nz-select [(ngModel)]="dataItem.gram" [disabled]="isView" style="width: 100%;" nzPlaceHolder="-Chọn-">
                <nz-option nzLabel="-" nzValue="-"></nz-option>
                <nz-option nzLabel="+" nzValue="+"></nz-option>
                <nz-option nzLabel="f" nzValue="f"></nz-option>
                <nz-option nzLabel="a" nzValue="a"></nz-option>
                <nz-option nzLabel="m" nzValue="m"></nz-option>
                <nz-option nzLabel="w" nzValue="w"></nz-option>
                <nz-option nzLabel="v" nzValue="v"></nz-option>
            </nz-select>
            <validation-custom *ngIf="!isView" [sModel]="dataItem.gram"></validation-custom>
        </div>
        
        ModuleDangKy
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal" ></label> 
            <div style="margin-top: 10px;">
                <label nz-checkbox [(ngModel)]="dataItem.common">Common</label> 
            </div>
            
           
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Nhóm khuẩn</label>
            <table-combo [TableEnum]="ComboBoxTableCode.GetAllNhomViKhuan" 
            [(ngModel)]="dataItem.org_group_id" [disabled]="isView">
        </table-combo>
          
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Sub Group</label>
            <input nz-input [(ngModel)]="dataItem.sub_group" [disabled]="isView">
         
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Chi</label>
            <table-combo 
                [TableEnum]="ComboBoxTableCode.GetAllChungLoaiViKhuan" 
                [(ngModel)]="dataItem.genus_id" 
                (ngModelChange)="Change($event)" 
                [disabled]="isView">
            </table-combo>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Mã Chi</label>
            <input nz-input [(ngModel)]="dataItem.genus_code" [disabled]="isView">
        </div>
        
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">SCT Code</label>
            <input nz-input [(ngModel)]="dataItem.sct_code" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">SCT</label>
            <input nz-input [(ngModel)]="dataItem.sct_text" [disabled]="isView">
         
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Trạng thái<span class="text-red">*</span></label>
            <nz-select [(ngModel)]="dataItem.activated" [disabled]="isView" style="width: 100%;" nzPlaceHolder="-Chọn-">
                <nz-option [nzValue]="true" nzLabel="Đang hoạt động"></nz-option>
                <nz-option [nzValue]="false" nzLabel="Dừng hoạt động"></nz-option>
            </nz-select>
            <validation-custom *ngIf="!isView" [sModel]="dataItem.activated"></validation-custom>
        </div>
    </div>

</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
