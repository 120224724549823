import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { Menu, MenuService } from '@delon/theme';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? '/';
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService,
        private _ngAlainMenuService: MenuService,
    ) { }

    ngOnInit(): void { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'dashboard', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'dashboard', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'bars', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'shopping', '/app/admin/editions'),

            new AppMenuItem('Pages_QuanLyCoSoYTe_ThongTinCoSo', 'Pages.QuanLyCoSoYTe.ThongTinCoSo', 'desktop', '/app/quan-ly-co-so/thong-tin-co-so'),

            //Quản lý cơ sở y tế
            new AppMenuItem('Pages_QuanLyCoSoYTe', 'Pages.QuanLyCoSoYTe', 'home', '', null, [
                new AppMenuItem('Pages_QuanLyCoSoYTe_ThongKeCoSoYTe', 'Pages.QuanLyCoSoYTe.ThongKeCoSoYTe', 'check-circle', '/app/quan-ly-co-so/thong-ke-co-so-y-te'),
                new AppMenuItem('Pages_QuanLyCoSoYTe_DanhSachCoSo', 'Pages.QuanLyCoSoYTe.DanhSachCoSo', 'check-circle', '/app/quan-ly-co-so/danh-sach-co-so'),
                new AppMenuItem('Pages_QuanLyCoSoYTe_DanhSachKhoaPhong', 'Pages.QuanLyCoSoYTe.DanhSachKhoaPhong', 'check-circle', '/app/quan-ly-co-so/danh-sach-khoa-phong'),
                new AppMenuItem('Pages_QuanLyCoSoYTe_DanhSachNguoiHanhNghe', 'Pages.QuanLyCoSoYTe.DanhSachNguoiHanhNghe', 'check-circle', '/app/quan-ly-co-so/danh-sach-nguoi-hanh-nghe'),
                new AppMenuItem('Pages_QuanLyCoSoYTe_DanhSachTrangThietBi', 'Pages.QuanLyCoSoYTe.DanhSachTrangThietBi', 'check-circle', '/app/quan-ly-co-so/danh-sach-trang-thiet-bi'),
                new AppMenuItem('Pages_QuanLyCoSoYTe_GiaDichVuKyThuat', 'Pages.QuanLyCoSoYTe.GiaDichVuKyThuat', 'check-circle', '/app/quan-ly-co-so/gia-dich-vu-ky-thuat'),
                new AppMenuItem('Pages_QuanLyCoSoYTe_GiaThuoc', 'Pages.QuanLyCoSoYTe.GiaThuoc', 'check-circle', '/app/quan-ly-co-so/gia-thuoc'),
            ]),

            //Công khai y tế
            new AppMenuItem('Pages_CongKhaiYTe', 'Pages.CongKhaiYTe', 'deployment-unit', '', null, [
                new AppMenuItem('Pages_CongKhaiYTe_ThongTinCoSo', 'Pages.CongKhaiYTe.ThongTinCoSo', 'check-circle', '/app/cong-khai-y-te/thong-tin-co-so'),
                new AppMenuItem('Pages_CongKhaiYTe_HoSoCongKhai', 'Pages.CongKhaiYTe.HoSoCongKhai', 'check-circle', '/app/cong-khai-y-te/ho-so-cong-khai'),
                new AppMenuItem('Pages_TraCuuKeKhai_TraCuuHanhChinh', 'Pages.CongKhaiYTe.TraCuuHanhChinh', 'check-circle', '/app/cong-khai-y-te/tra-cuu-ke-khai/tra-cuu-hanh-chinh'),
                new AppMenuItem('Pages_TraCuuKeKhai_TraCuuNguoiHanhNghe', 'Pages.CongKhaiYTe.TraCuuNguoiHanhNghe', 'check-circle', '/app/cong-khai-y-te/tra-cuu-ke-khai/tra-cuu-nguoi-hanh-nghe'),
                new AppMenuItem('Pages_TraCuuKeKhai_TraCuuMaKeKhai', 'Pages.CongKhaiYTe.TraCuuMaKeKhai', 'check-circle', '/app/cong-khai-y-te/tra-cuu-ke-khai/tra-cuu-ma-ke-khai/tra-cuu-ma-ke-khai-list'),
                new AppMenuItem('Pages_TraCuuKeKhai_TraCuuMap', 'Pages.CongKhaiYTe.TraCuuMap', 'check-circle', '/app/cong-khai-y-te/tra-cuu-ke-khai/tra-cuu-ban-do-agm'),
            ]),

            //Báo cáo tết
            new AppMenuItem('Pages_BaoCaoTet', 'Pages.BaoCaoTet', 'schedule', '', null, [
                new AppMenuItem('Pages_BaoCaoTet_BaoCao', 'Pages.BaoCaoTet.BaoCao', 'check-circle', '/app/bao-cao-tet/bao-cao'),
                new AppMenuItem('Pages_BaoCaoTet_KiemTraSoLieu', 'Pages.BaoCaoTet.KiemTraSoLieu', 'check-circle', '/app/bao-cao-tet/kiem-tra-so-lieu'),
                new AppMenuItem('Pages_BaoCaoTet_TongHopBaoCao', 'Pages.BaoCaoTet.TongHopBaoCao', 'check-circle', '/app/bao-cao-tet/tong-hop-bao-cao'),
                new AppMenuItem('Pages_BaoCaoTet_ThongKeBenhNhan', 'Pages.BaoCaoTet.ThongKeBenhNhan', 'check-circle', '/app/bao-cao-tet/thong-ke-benh-nhan'),
                new AppMenuItem('Pages_BaoCaoTet_DotBaoCao', 'Pages.BaoCaoTet.DotBaoCao', 'check-circle', '/app/bao-cao-tet/dot-bao-cao'),
                new AppMenuItem('Pages_BaoCaoTet_ChiSo', 'Pages.BaoCaoTet.ChiSo', 'check-circle', '/app/bao-cao-tet/chi-so-bao-cao'),
            ]),

            //Quản lý tử vong
            new AppMenuItem('Pages_QuanLyTuVong', 'Pages.QuanLyTuVong', 'medicine-box', '', null, [
                new AppMenuItem('Pages_QuanLyTuVong_CaTuVong', 'Pages.QuanLyTuVong.CaTuVong', 'check-circle', '/app/quan-ly-tu-vong/ca-tu-vong'),
                new AppMenuItem('Pages_QuanLyTuVong_TienLuongNangXinVe', 'Pages.QuanLyTuVong.TienLuongNangXinVe', 'check-circle', '/app/quan-ly-tu-vong/tien-luong-nang-xin-ve'),
                new AppMenuItem('Pages_QuanLyTuVong_XetDuyetNNTV', 'Pages.QuanLyTuVong.XetDuyetNNTV', 'check-circle', '/app/quan-ly-tu-vong/xet-duyet-nntv'),
                new AppMenuItem('Pages_QuanLyTuVong_ThongKe', 'Pages.QuanLyTuVong.ThongKe', 'check-circle', '/app/quan-ly-tu-vong/thong-ke'),
                new AppMenuItem('Pages_QuanLyTuVong_LoaiBaoCao', 'Pages.QuanLyTuVong.LoaiBaoCao', 'check-circle', '/app/quan-ly-tu-vong/loai-bao-cao'),
                new AppMenuItem('Pages_QuanLyTuVong_ChiSo', 'Pages.QuanLyTuVong.ChiSo', 'check-circle', '/app/quan-ly-tu-vong/chi-so'),
            ]),

            //Báo cáo sự cố y khoa
            new AppMenuItem('Pages_SuCoYKhoa', 'Pages.SuCoYKhoa', 'alert', '', null, [
                new AppMenuItem('Pages_SuCoYKhoa_KhaiBaoSuCo', 'Pages.SuCoYKhoa.KhaiBaoSuCo', 'check-circle', '/app/su-co-y-khoa/khai-bao-su-co'),
                new AppMenuItem('Pages_SuCoYKhoa_ThongKe', 'Pages.SuCoYKhoa.ThongKe', 'check-circle', '/app/su-co-y-khoa/thong-ke'),
                new AppMenuItem('Pages_SuCoYKhoa_DotBaoCaoQuy', 'Pages.SuCoYKhoa.DotBaoCaoQuy', 'check-circle', '/app/su-co-y-khoa/dot-bao-cao-quy'),
                new AppMenuItem('Pages_SuCoYKhoa_BaoCaoQuy', 'Pages.SuCoYKhoa.BaoCaoQuy', 'check-circle', '/app/su-co-y-khoa/bao-cao-quy'),
                new AppMenuItem('Pages_SuCoYKhoa_TongHopBaoCaoQuy', 'Pages.SuCoYKhoa.TongHopBaoCaoQuy', 'check-circle', '/app/su-co-y-khoa/tong-hop-bao-cao-quy'),
            ]),

            //Thanh toán BHYT theo DRG
            new AppMenuItem('Pages_ThanhToanDRG', 'Pages.ThanhToanDRG', 'dollar', '', null, [
                new AppMenuItem('Pages_ThanhToanDRG_CaBenh', 'Pages.ThanhToanDRG.CaBenh', 'check-circle', '/app/thanh-toan-drg/drg-benh-nhan'),
            ]),

            //Quản lý đánh giá CLBV
            new AppMenuItem('Pages_DanhGiaCLBV', 'Pages.DanhGiaCLBV', 'project', '', null, [
                new AppMenuItem('Pages_DanhGiaCLBV_PhieuDanhGia', 'Pages.DanhGiaCLBV.PhieuDanhGia', 'check-circle', '/app/danh-gia-clbv/phieu-danh-gia'),
                new AppMenuItem('Pages_DanhGiaCLBV_DotDanhGia', 'Pages.DanhGiaCLBV.DotDanhGia', 'check-circle', '/app/danh-gia-clbv/dot-danh-gia'),
                new AppMenuItem('Pages_DanhGiaCLBV_TieuChiDanhGia', 'Pages.DanhGiaCLBV.TieuChiDanhGia', 'check-circle', '/app/danh-gia-clbv/tieu-chi-danh-gia'),
            ]),

            //Quản lý đánh giá PXN
            new AppMenuItem('Pages_QuanLyPXN', 'Pages.QuanLyPXN', 'experiment', '', null, [
                new AppMenuItem('Pages_QuanLyPXN_PhieuDanhGia', 'Pages.QuanLyPXN.PhieuDanhGia', 'check-circle', '/app/quan-ly-pxn/phieu-danh-gia'),
                new AppMenuItem('Pages_QuanLyPXN_DotDanhGia', 'Pages.QuanLyPXN.DotDanhGia', 'check-circle', '/app/quan-ly-pxn/dot-danh-gia'),
                new AppMenuItem('Pages_QuanLyPXN_TieuChiDanhGia', 'Pages.QuanLyPXN.TieuChiDanhGia', 'check-circle', '/app/quan-ly-pxn/tieu-chi-danh-gia'),
                new AppMenuItem('Pages_QuanLyPXN_PhongXetNghiem', 'Pages.QuanLyPXN.PhongXetNghiem', 'check-circle', '/app/quan-ly-pxn/phong-xet-nghiem'),
            ]),

            //Báo cáo tiến độ
            new AppMenuItem('Pages_BaoCaoTienDo', 'Pages.BaoCaoTienDo', 'line-chart', '', null, [
                new AppMenuItem('Pages_BaoCaoTienDo_TongQuan', 'Pages.BaoCaoTienDo.TongQuan', 'check-circle', '/app/bao-cao-tien-do/tong-quan'),
                new AppMenuItem('Pages_BaoCaoTienDo_BaoCao', 'Pages.BaoCaoTienDo.BaoCao', 'check-circle', '/app/bao-cao-tien-do/bao-cao'),
                new AppMenuItem('Pages_BaoCaoTienDo_KyBaoCao', 'Pages.BaoCaoTienDo.KyBaoCao', 'check-circle', '/app/bao-cao-tien-do/ky-bao-cao'),
                new AppMenuItem('Pages_BaoCaoTienDo_DanhMucChiSo', 'Pages.BaoCaoTienDo.DanhMucChiSo', 'check-circle', '/app/bao-cao-tien-do/danh-muc-chi-so'),
            ]),

            //Quản lý AMR
            new AppMenuItem('Pages_QuanLyAMR', 'Pages.QuanLyAMR', 'cloud-server', '', null, [
                new AppMenuItem('Pages_QuanLyAMR_TongQuan', 'Pages.QuanLyAMR.TongQuan', '', '/app/quan-ly-amr/tong-quan', true),
                new AppMenuItem('Pages_QuanLyAMR_NhapLieu_TaiTepDuLieu', 'Pages.QuanLyAMR.NhapLieu.TaiTepDuLieu', '', '/app/quan-ly-amr/tai-tep-du-lieu'),
                new AppMenuItem('Pages_QuanLyAMR_NhapLieu_NhapSoBenhPham', 'Pages.QuanLyAMR.NhapLieu.NhapSoBenhPham', '', '/app/quan-ly-amr/nhap-so-benh-nhan', true),
                new AppMenuItem('Pages_QuanLyAMR_PhanTich_PhanTichDuLieu', 'Pages.QuanLyAMR.PhanTich.PhanTichDuLieu', '', '/app/quan-ly-amr/phan-tich-du-lieu'),
                new AppMenuItem('Pages_QuanLyAMR_PhanTich_DanhSachCaBenh', 'Pages.QuanLyAMR.PhanTich.DanhSachCaBenh', '', '/app/quan-ly-amr/danh-sach-ca-benh'),
                new AppMenuItem('Pages_QuanLyAMR_PhanTich_TomTatDuLieu', 'Pages.QuanLyAMR.PhanTich.TomTatDuLieu', '', '/app/quan-ly-amr/tom-tat-du-lieu'),
                new AppMenuItem('Pages_QuanLyAMR_PhanTich_BaoCaoBenhVien', 'Pages.QuanLyAMR.PhanTich.BaoCaoBenhVien', '', '/app/quan-ly-amr/bao-cao-benh-vien', true),
                new AppMenuItem('Pages_QuanLyAMR_TaiLieuThamKhao', 'Pages.QuanLyAMR.TaiLieuThamKhao', '', '/app/quan-ly-amr/tai-lieu-tham-khao'),
                new AppMenuItem('Pages_QuanLyAMR_HoiDap', 'Pages.QuanLyAMR.HoiDap', '', '/app/he-thong/hoi-dap-amr')
            ]),

            //Quản lý HAI
            new AppMenuItem('Pages_QuanLyHAI', 'Pages.QuanLyHAI', 'cloud-server', '', null, [
                new AppMenuItem('Pages_QuanLyHAI_TongQuan', 'Pages.QuanLyHAI.TongQuan', '', '/app/quan-ly-hai/tong-quan', true),
                new AppMenuItem('Pages_QuanLyHAI_SoLieu_NhiemKhuanHuyet', 'Pages.QuanLyHAI.SoLieu.NhiemKhuanHuyet', '', '/app/quan-ly-hai/nhiem-khuan-huyet'),
                new AppMenuItem('Pages_QuanLyHAI_SoLieu_NhiemKhuanTietLieu', 'Pages.QuanLyHAI.SoLieu.NhiemKhuanTietLieu', '', '/app/quan-ly-hai/nhiem-khuan-tiet-lieu'),
                new AppMenuItem('Pages_QuanLyHAI_SoLieu_NhiemKhuanVetMo', 'Pages.QuanLyHAI.SoLieu.NhiemKhuanVetMo', '', '/app/quan-ly-hai/nhiem-khuan-vet-mo'),
                new AppMenuItem('Pages_QuanLyHAI_SoLieu_TomTatSoLieu', 'Pages.QuanLyHAI.SoLieu.TomTatSoLieu', '', '/app/quan-ly-hai/tom-tat-so-lieu', true),
                new AppMenuItem('Pages_QuanLyHAI_BaoCao_BaoCaoTongHop', 'Pages.QuanLyHAI.BaoCao.BaoCaoTongHop', '', '/app/quan-ly-hai/bao-cao-tong-hop'),
                new AppMenuItem('Pages_QuanLyHAI_BaoCao_TacNhanGayBenh', 'Pages.QuanLyHAI.BaoCao.TacNhanGayBenh', '', '/app/quan-ly-hai/tac-nhan-gay-benh'),
                new AppMenuItem('Pages_QuanLyHAI_BaoCao_NhayCamKhangSinh', 'Pages.QuanLyHAI.BaoCao.NhayCamKhangSinh', '', '/app/quan-ly-hai/nhay-cam-khang-sinh'),
                new AppMenuItem('Pages_QuanLyHAI_BaoCao_MoHinhKhangSinh', 'Pages.QuanLyHAI.BaoCao.MoHinhKhangSinh', '', '/app/quan-ly-hai/mo-hinh-khang-sinh'),
                new AppMenuItem('Pages_QuanLyHAI_BaoCao_HoanThanhDuLieu', 'Pages.QuanLyHAI.BaoCao.HoanThanhDuLieu', '', '/app/quan-ly-hai/hoan-thanh-du-lieu', true),
                new AppMenuItem('Pages_QuanLyHAI_TaiLieuThamKhao', 'Pages.QuanLyHAI.TaiLieuThamKhao', '', '/app/quan-ly-hai/tai-lieu-tham-khao'),
                new AppMenuItem('Pages_QuanLyHAI_HoiDap', 'Pages.QuanLyHAI.HoiDap', '', '/app/he-thong/hoi-dap-hai')
                    
            ]),

            //Danh Mục
            new AppMenuItem('Pages_DanhMuc_Menu', 'Pages.DanhMuc.Menu', 'folder', '/app/danh-muc/danh-muc-menu'),

            //Kho dữ liệu API
            new AppMenuItem('Pages_KhoDuLieuAPI', 'Pages.KhoDuLieuAPI', 'database', '', null, [
                new AppMenuItem('Pages_KhoDuLieuAPI_GiayChungSinh', 'Pages.KhoDuLieuAPI.GiayChungSinh', 'check-circle', '/app/kho-du-lieu-api/giay-chung-sinh'),
                new AppMenuItem('Pages_KhoDuLieuAPI_GiayBaoTu', 'Pages.KhoDuLieuAPI.GiayBaoTu', 'check-circle', '/app/kho-du-lieu-api/giay-bao-tu'),
                new AppMenuItem('Pages_KhoDuLieuAPI_APIDayDHIS2', 'Pages.KhoDuLieuAPI.APIDayDHIS2', 'check-circle', '/app/kho-du-lieu-api/api-day-ahis2'),
                new AppMenuItem('Pages_KhoDuLieuAPI_LichSuAPI', 'Pages.KhoDuLieuAPI.LichSuAPI', 'check-circle', '/app/kho-du-lieu-api/lich-su-api'),
            ]),

            //Hệ thống
            new AppMenuItem('Pages_HeThong', 'Pages.HeThong', 'setting', '', null, [
                new AppMenuItem('Pages_HeThong_DuyetTaiKhoan', 'Pages.HeThong.DuyetTaiKhoan', 'check-circle', '/app/he-thong/tai-khoan-dang-ky'),
                new AppMenuItem('Pages_HeThong_TaiKhoan', 'Pages.HeThong.QuanLyTaiKhoan', 'check-circle', '/app/he-thong/tai-khoan-nguoi-dung'),
                new AppMenuItem('Pages_HeThong_TaiKhoanAPI', 'Pages.HeThong.QuanLyTaiKhoanAPI', 'check-circle', '/app/he-thong/tai-khoan-api'),
                new AppMenuItem('Pages_HeThong_CayDanhMuc', 'Pages.HeThong.CayDanhMuc', 'check-circle', '/app/he-thong/cay-danh-muc'),
                new AppMenuItem('Pages_HeThong_CaiDat', 'Pages.HeThong.CaiDat', 'setting', '/app/he-thong/cai-dat'),
                new AppMenuItem('Pages_HeThong_ThietLapChung', 'Pages.HeThong.ThietLapChung', 'check-circle', '/app/he-thong/thiet-lap-chung'),
                new AppMenuItem('Pages_HeThong_TaiLieuHuongDan', 'Pages.HeThong.TaiLieuHuongDan', 'check-circle', '/app/he-thong/tai-lieu-huong-dan'),
                new AppMenuItem('Pages_HeThong_DotTapHuan', 'Pages.HeThong.DotTapHuan', 'check-circle', '/app/he-thong/dot-tap-huan'),
            ]),

            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '', null, [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'team', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'safety', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'user', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'global', '/app/admin/languages'),
                new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'global', '/app/admin/languagetexts'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'book', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'setting', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'setting', '/app/admin/tenantSettings'),
            ])
        ]);
    }

    mapToNgAlainMenu() {
        let menu = this.getMenu();
        let ngAlainRootMenu = <Menu>{
            text: this._localizationService.l(menu.name),
            group: false,
            hideInBreadcrumb: true,
            children: [],
        };
        this.generateNgAlainMenus(ngAlainRootMenu.children, menu.items);

        let ngAlainMenus = [ngAlainRootMenu];
        this._ngAlainMenuService.add(ngAlainMenus);
    }

    generateNgAlainMenus(ngAlainMenus: Menu[], appMenuItems: AppMenuItem[]) {
        appMenuItems.forEach((item) => {
            let ngAlainMenu: Menu;

            ngAlainMenu = {
                key: item.name,
                text: this._localizationService.l(item.name),
                link: item.route,
                icon: {
                    type: 'icon',
                    value: item.icon,
                },
                hide: !this.showMenuItem(item),
            };

            if (item.items && item.items.length > 0) {
                ngAlainMenu.children = [];
                this.generateNgAlainMenus(ngAlainMenu.children, item.items);
            }

            ngAlainMenus.push(ngAlainMenu);
        });
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
