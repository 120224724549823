<div class="d-flex justify-content-between align-items-center">
    <label class="pageTitle">
        <ng-content select="[pageTitle]"></ng-content>
    </label>
    <page-header [autoTitle]="false">
    </page-header>
</div>
<ng-content select="[pageAction]"></ng-content>
<ng-container>
    <div nz-row class="content">

        <div nz-col nzXs="24" nzSm="24" nzMd="18">
            <ng-content select="[createOrEdit]"></ng-content>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="6">
            <ng-content select="[logs]"></ng-content>
        </div>
    </div>
</ng-container>