<div style="height: 70vh; overflow-y: scroll;">
    <ora-filter-spin (onSearch)="clickEvent()" (onRefresh)="clear()" (keydown.enter)="clickEvent()" [widthColSeach]="12">
        <input nz-input (ngModelChange)="search$.next($event)" class="txtSearch" [(ngModel)]="searchDto"
            placeholder="Nhập từ khóa để tìm kiếm..." />
    </ora-filter-spin>

    <div *ngIf="!searchDto" style="margin-top: 10px;">
        <b>Chưa có thông tin. Yêu cầu nhập dữ liệu để tìm kiếm...</b>
    </div>
    <div *ngIf="searchDto" style="margin: 10px 0px;">Tìm kiếm cho: "{{searchDto}}"
        <nz-spin [nzSpinning]="isLoading"></nz-spin>
    </div>
    <ng-container *ngIf="searchDto && !isLoading">
        

        <div *ngIf="dataAPI?.json?.header" [innerHTML]="dataAPI?.json?.header| safe:'html'" style="margin-bottom: 5px;">
        </div>
        <hr>
        <div *ngFor="let dataChapter of dataAPI?.json?.data">
            <div class="chapter">
                {{dataChapter?.id}} <span [innerHTML]="dataChapter?.name| safe:'html'"></span>
            </div>


            <div style="margin-left: 5px" *ngFor="let dataSection of dataChapter?.childs">
                <div class="section">
                    {{dataSection?.code}} <span [innerHTML]="dataSection?.name| safe:'html'"></span>
                </div>


                <div style="margin-left: 10px" *ngFor="let dataType of dataSection?.childs">
                    <div class="type">
                        {{dataType?.code}} <span [innerHTML]="dataType?.name| safe:'html'"></span>
                    </div>


                    <div style="margin-left: 15px" *ngFor="let dataDisease of dataType?.childs">
                        <a (click)="chooseMaIcdSearch(dataDisease)">
                            <div style="margin-bottom: 4px; color: #0084f3;">{{dataDisease?.code}} <span
                                    [innerHTML]="dataDisease?.name| safe:'html'"></span></div>
                        </a>
                    </div>
                </div>
            </div>
            <hr>
        </div>

        <div *ngIf="dataAPI?.json?.footer" [innerHTML]="dataAPI?.json?.footer| safe:'html'"></div>

    </ng-container>
</div>