import { Directive } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';

@Directive()
export abstract class BaseUXService {

    l(key: string): string {

        let localizedText = abp.localization.localize(key, AppConsts.localization.defaultLocalizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }
        return localizedText;
    }
}
