import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ord-save-btn',
  template: `
    <ord-common-btn nzTypeButton="primary" [nzIcon]="icon" text="{{text}}"
                    [loading]="loading"
                    (clickEvent)="clickEvent.emit()" [disabled]="disabled"></ord-common-btn>`
})
export class SaveBtnComponent {
  @Input() text = 'Lưu';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() icon = 'save';
  @Output() clickEvent = new EventEmitter();
}
