<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Mã tương đương<span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.maTuongDuong" [disabled]="isView" #maTuongDuong />
                <validation-custom [sModel]="dataItem.maTuongDuong" [nativeElement]="maTuongDuong"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Mã thông tư</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.maThongTu" [disabled]="isView" />
            </nz-form-control>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="24">
            <label class="font-weight-normal">Tên theo danh mục kỹ thuật</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.tenTheoDanhMucKyThuat" [disabled]="isView" />
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Phân tuyến</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.phanTuyen" [disabled]="isView" />
            </nz-form-control>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Phân loại phẫu thuật, thủ thuật</label>
            <table-combo [TableEnum]="ComboBoxTableCode.GetAllLoaiPhauThuatThuThuat" (eventChange)="Change($event)" 
                placeHolders="Loại phẫu thuật, thủ thuật"
                [isAdd]="true"
                [isUpdate]="true"
                [(ngModel)]="dataItem.phanLoaiPTTTId" [disabled]="isView">
            </table-combo>
        </div>
    </div>
    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Số thứ tự thông tư</label>
            <nz-form-control>
                <nz-input-number nz-input [nzMin]="1" [(ngModel)]="dataItem.sttThongTu" [disabled]="isView"></nz-input-number>
            </nz-form-control>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="24">
            <label class="font-weight-normal">Tên theo danh mục giá thông tư</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.tenTheoDMGiaThongTu" [disabled]="isView" />
            </nz-form-control>
        </div>
    </div>
</div>

<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
