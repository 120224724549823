import { Component, forwardRef, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICD9UxService } from '@app/pages/danh-muc/icd9/services/icd9-ux.service';
import {
    LstDictionaryDto, LstDictionaryServiceProxy, DeleteMultiDtoOfInt64
} from '@shared/service-proxies/service-proxies';
import _ from 'lodash';
import { finalize, map } from 'rxjs/operators';
import { AppUtilityService } from '../../../../common/custom/utility.service';
import { BaseComboComponent } from '../../../_base/base-combo-abstract';

@Component({
    selector: 'droplist-down-combo',
    template: ` <div class="ora-combo">
        <ng-container *ngIf="!_isDisabled">
            <nz-select
                    nzShowSearch
                    nzServerSearch
                    [nzMaxTagCount]="maxTagCount"
                    [nzMaxTagPlaceholder]="maxTagPlaceholderTmpl"
                    [nzAllowClear]="nzAllowClear"
                    nzPlaceHolder="{{ placeHolders }}"
                    [nzMode]="nzMode"
                    [nzLoading]="loading"
                    [(ngModel)]="_value"
                    [nzDisabled]="_isDisabled"
                    (nzFocus)="(onFocus)"
                    style="width:100%"
                    (ngModelChange)="onChangeValue($event)"
                    (nzOnSearch)="search($event)"
                    [nzDropdownClassName]="'oda-select-dropdown'"
                    [nzSuffixIcon]="prefixTemplateUser"
                >
                    <div *ngFor="let item of optionList" >
                        <nz-option  [nzLabel]="  item.displayText " [nzValue]="item.value" > </nz-option>
                       </div>
                    <ng-template #prefixTemplateUser><i nz-icon nzType="down" nzTheme="outline"></i></ng-template>
                </nz-select> 
               
        </ng-container>
        <input *ngIf="_isDisabled" nz-input [ngModel]="svalue" readonly />
        <ng-template #maxTagPlaceholderTmpl let-selectedList>+ {{ selectedList.length }} </ng-template>
    </div>`,
    styles: [
        `
            .ora-combo {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            .ora-combo nz-select {
                width: 100%;
            }

            .ora-close {
                cursor: pointer;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropListDownComboComponent),
            multi: true,
        },
    ],
})
export class DropListDownComboComponent extends BaseComboComponent implements OnInit, ControlValueAccessor {
    @Input() placeHolders?: string = '- Chọn -';
    @Input() nzAllowClear?: boolean = true;
    maxOptionRender = 50;
    @Input() isAdd = false;
    @Input() isUpdate = false;
    @Input() isSearchAdvance = true;
    @Input() dictionaryTypeCode;
    loading = false;

    constructor(
        injector: Injector,
        private _LstDictionaryServiceProxy: LstDictionaryServiceProxy,
        private _ICD9UxService: ICD9UxService,
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getDataSourceFromServer();
    }

    getDataSourceFromServer() {
        this.loading = true;
        this._LstDictionaryServiceProxy
            .getToDDLByType(this.dictionaryTypeCode)
            .pipe(
                map((list) => {
                    return list.map((item) => {
                        return Object.assign(item, {
                            fts: AppUtilityService.getFullTextSearch(item.displayText),
                        });
                    });
                }),
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe((result) => {
                this.setListOfOption(result);
                this.addSelectedNotInRender();
            });
    }

    
}
