import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { PagedListingComponentBase, PagedRequestDto } from '@shared/common/paged-listing-component-base';
import { HinhThucToChucPagingListRequest, HinhThucToChucServiceProxy } from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './hinh-thuc-to-chuc-select-search-modal.component.html',
})
export class HinhThucToChucSelectSearchModalComponent extends PagedListingComponentBase<any> implements OnInit {
    @Input() listOfSelectedValue: number[];
    isMulti: boolean = false;
    filter: any = {};
    constructor(
        injector: Injector,
        private _dataService: HinhThucToChucServiceProxy,
        private modal: NzModalRef,
        private cdr: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.refresh();
    }

    clear() {
        this.filter = _.cloneDeep(this.searchDto);
        this.refresh();
    }

    protected fetchDataList(request: PagedRequestDto, pageNumber: number, finishedCallback: () => void): void {
        const input: HinhThucToChucPagingListRequest = new HinhThucToChucPagingListRequest();
        input.maxResultCount = request.maxResultCount;
        input.skipCount = request.skipCount;
        input.sorting = request.sorting;

        let rep: any = {
            ...input,
            filterColumns: this.filterColumns,
            ... this.searchDto
        }

        this._dataService.hinhThucToChucPagingList(rep)
            .pipe(finalize(finishedCallback))
            .subscribe((result) => {
                this.dataList = result.items;
                this.dataList.forEach(item => {
                    if (this.listOfSelectedValue && this.listOfSelectedValue.length > 0) {
                        let checkId = this.listOfSelectedValue.findIndex(x => x == item.id);
                        if (checkId > -1) {
                            item.checked = true;
                        }
                    }
                })

                this.showPaging(result);
                this.cdr.detectChanges();
            });
    }


    save() {
        this.modal.close(this.selectedDataItems)
    }

    close() {
        this.modal.destroy();
    }

    //#region UX-JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {

            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header'
        });

    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
