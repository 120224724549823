<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[4, 4]">
        <div nz-col class="gutter-row " [nzSm]="8" [nzXs]="8">
            <label class="font-weight-normal">Mã <span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.ma" >
                <validation-custom [sModel]="dataItem.ma"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row " [nzSm]="8" [nzXs]="8">
            <label class="font-weight-normal">Loại báo cáo <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <enum-combo [EnumCode]="ComboBoxEnumCode.LoaiBaoCaoTienDo"  [(ngModel)]="dataItem.loaiBaoCaoTienDoEnum" [disabled]="isView"   >
                </enum-combo>
                <validation-custom [sModel]="dataItem.loaiBaoCaoTienDoEnum"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="8" [nzXs]="8">
            <label class="font-weight-normal">Sắp xếp <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input  type="number" nz-input [(ngModel)]="dataItem.soThuTu">
                <validation-custom [sModel]="dataItem.soThuTu"></validation-custom>
            </nz-form-control>
        </div>
    </div>

    <div nz-row [nzGutter]="[4, 4]">
        <div nz-col class="gutter-row ord-form-control" [nzSm]="24" [nzXs]="24">
            <label class="font-weight-normal">Nội dung <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input nz-input [(ngModel)]="dataItem.ten">
                <validation-custom [sModel]="dataItem.ten"></validation-custom>
            </nz-form-control>
        </div>
    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>