<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[12,6]">
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Tên (tiếng Anh) <span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.name_en" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.name_en"></validation-custom>
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Tên (tiếng Việt) <span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.name_vi" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.name_en"></validation-custom>
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal">Mã <span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.code_en" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.code_en"></validation-custom>
        </div>
       
        
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal">Phân loại</label>
            <enum-combo [EnumCode]="ComboBoxEnumCode.TRANG_THAI_WHONET" [(ngModel)]="dataItem.spec_group" [disabled]="isView">
            </enum-combo>
            
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal"></label> 
            <div style="margin-top: 10px;">
                <label nz-checkbox [(ngModel)]="isHuman"  [disabled]="isView">Trên người</label> 
            </div>           
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal"></label> 
            <div style="margin-top: 10px;">
                <label nz-checkbox [(ngModel)]="isAnimal"  [disabled]="isView">Trên động vật</label> 
            </div>           
        </div>
        
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Nhóm bệnh phẩm</label>
            <table-combo [TableEnum]="ComboBoxTableCode.GetAllNhomBenhPham" 
            [(ngModel)]="dataItem.group_name_vi" [disabled]="isView">
            </table-combo>
          
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Ghi chú</label>
            <input nz-input [(ngModel)]="dataItem.note" [disabled]="isView">
         
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Trạng thái<span class="text-red">*</span></label>
            <nz-select [(ngModel)]="dataItem.activated" [disabled]="isView" style="width: 100%;" nzPlaceHolder="-Chọn-">
                <nz-option [nzValue]="true" nzLabel="Đang hoạt động"></nz-option>
                <nz-option [nzValue]="false" nzLabel="Dừng hoạt động"></nz-option>
            </nz-select>
            <validation-custom *ngIf="!isView" [sModel]="dataItem.activated"></validation-custom>
        </div>
    </div>

</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
