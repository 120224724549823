import { Component, Injector, Input, OnInit } from "@angular/core";
import { AppUtilityService } from "@app/shared/common/custom/utility.service";
import { ModalComponentBase } from "@shared/common/modal-component-base";
import { BCTD_DanhMucChiSoDto, BCTD_KyBaoCaoChiSoDto, BCTD_KyBaoCaoDto, BCTDKyBaoCaoServiceProxy, ComboBoxEnumCode, ComboBoxTableCode } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";

@Component({
    selector: "ky-bao-cao-edit",
    templateUrl: './ky-bao-cao-edit.component.html',
    styleUrls: ['./ky-bao-cao-edit.component.scss']
})
export class KyBaoCaoEditComponent extends ModalComponentBase implements OnInit {
    @Input() dataItem: BCTD_KyBaoCaoDto;
    @Input() isView: boolean;

    formId = 'formValidateKyBaoCao';
    ComboBoxEnumCode = ComboBoxEnumCode;
    ComboBoxTableCode = ComboBoxTableCode;
    cauHinhChiSo: BCTD_KyBaoCaoChiSoDto[] = [Object.assign(new BCTD_KyBaoCaoChiSoDto(), { sortOrder: 1 })];
    constructor(
        injector: Injector,
        private _dataService: BCTDKyBaoCaoServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.init();
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
        }
    }

    init() {
        if (!this.dataItem) {
            this.dataItem = new BCTD_KyBaoCaoDto();
        }
        if (!this.dataItem.listKyBaoCaoChiSo) {
            this.dataItem.listKyBaoCaoChiSo = [];
        }
    }

    themChiSo() {
        if (!this.dataItem.listKyBaoCaoChiSo) {
            this.dataItem.listKyBaoCaoChiSo = [];
        }
        this.dataItem.listKyBaoCaoChiSo.push(new BCTD_KyBaoCaoChiSoDto());
    }

    xoaChiSo(idx: number) {
        if (this.dataItem.listKyBaoCaoChiSo && this.dataItem.listKyBaoCaoChiSo.length > idx) {
            this.dataItem.listKyBaoCaoChiSo.splice(idx, 1);
        }
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error('Bạn cần nhập đủ dữ liệu các trường có dấu * đỏ !!');
            return;
        }
        abp.ui.setBusy();

        this._dataService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new BCTD_KyBaoCaoDto());
        this.init();
    }

    getInfoById(id: number): void {
        abp.ui.setBusy();
        this._dataService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.dataItem = response;
                this.init();
            });
    }
    changeDotBaoCao($event) {
        if (!$event || $event.value === undefined) {
     
            return;
        }
        abp.ui.setBusy();
        this._dataService.getListDanhMucChiSo($event.value)
            .pipe(finalize(() => {abp.ui.clearBusy()}))
            .subscribe(val => {
                this.dataItem.listKyBaoCaoChiSo = val;
            })
  
    }
}
