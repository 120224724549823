<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Mã loại phẫu thuật, thủ thuật<span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.maLoaiPTTT" [disabled]="isView" #maLoai>
                <validation-custom [sModel]="dataItem.maLoaiPTTT" [nativeElement]="maLoai"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Tên phẫu thuật, thủ thuật viết tắt</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.tenPTTTVietTat" [disabled]="isView">
            </nz-form-control>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="24">
            <label class="font-weight-normal">Tên phẫu thuật, thủ thuật <span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.tenPTTTDayDu" [disabled]="isView" #tenDayDu>
                <validation-custom [sModel]="dataItem.tenPTTTDayDu" [nativeElement]="tenDayDu"></validation-custom>
            </nz-form-control>
        </div>
    </div>

</div>


<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
