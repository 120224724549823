import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DestroyRxjsService } from '@shared/destroy-rxjs.service';
import { TrackingLogInputDto, TrackingLogServiceProxy } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ora-log',
    templateUrl: './ora-log.component.html',
    styleUrls: ['./ora-log.component.scss'],
})
export class OraLogComponent extends AppComponentBase implements OnInit {
    constructor(injector: Injector, private trackingLogServiceProxy: TrackingLogServiceProxy, private destroyRxjsService: DestroyRxjsService) {
        super(injector);
    }

    @Input() referenceId: number;
    @Input() moduleType: string;
    dateNow = DateTime.now();
    dataSource = [];
    ngOnInit(): void {
        if (this.referenceId) {
            let input = Object.assign(new TrackingLogInputDto(), {
                moduleType: this.moduleType,
                referenceId: this.referenceId,
            });
            this.trackingLogServiceProxy.getLog(input)
            .pipe(
              takeUntil(this.destroyRxjsService)
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.dataSource = result.dataResult;
                }
            });
        }
    }
    trackByFn(index: number, item: any) {
        if (item && item.id) {
            return item.id;
        }
        return index;
    }
}
