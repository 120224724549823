import {
    Component,
    OnInit,
    Optional,
    Inject,
    Input,
    Provider,
    forwardRef,
    EventEmitter,
    Output,
    OnDestroy, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges,
} from '@angular/core';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, Form, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { NzDatePickerComponent } from '@node_modules/ng-zorro-antd/date-picker';
import { DateTime } from 'luxon';
import * as moment from 'moment';
const VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OraRangeDatePickerComponent),
    multi: true,
};

@Component({
    selector: 'ora-range-date-picker',
    template: `
        <div nz-row  class="main-ora-range-date-picker">
            <div nz-col class="gutter-row" [nzSpan]="12" id="{{numberNow + '_date-picker-1'}}">
                <label *ngIf="!isNoTitle">{{titleFromDate}}</label>
                <nz-input-group style="display: flex;">
                    <button class="btn btn-secondary" nz-dropdown [nzDropdownMenu]="menuDateTime" nzPlacement="bottomLeft">
                    <i class="icon-date" nz-icon nzType="filter" nzTheme="outline"></i></button>
                    <nz-dropdown-menu #menuDateTime="nzDropdownMenu">
                        <ul nz-menu>
                            <li class="{{item.class}}" nz-menu-item *ngFor="let item of lstConfigDate" (click)="onChangeDate(item)">{{item.name}}</li>
                        </ul>
                    </nz-dropdown-menu>
                    <ora-date-picker-v2 [minDate]="minDateStart" [maxDate]="maxDateStart" style="width:100%;" [isIcon]="true" (ngModelChange)="startDateRangeChange.emit($event); startDateRangeChanges($event)" [(ngModel)]="startDateRange"></ora-date-picker-v2>
                </nz-input-group>
            </div>
            <div style="padding-left: 5px;" nz-col class="gutter-row" [nzSpan]="12" id="{{numberNow + '_date-picker-2'}}">
                <label *ngIf="!isNoTitle">{{titleEndDate}}</label>
                <ora-date-picker-v2 #endatePicker [minDate]="minDateEnd" [maxDate]="maxDateEnd" [isIcon]="true" [(ngModel)]="endDateRange" (ngModelChange)="endDateRangeChange.emit($event);" ></ora-date-picker-v2>
            </div>
        </div>
    `,
    styles: [`
        .main-ora-range-date-picker {
        }
        .main-ora-range-date-picker .ant-form-item-label{
            padding: 0px !important;
        }

       
        .main-ora-range-date-picker .ant-input-group button{
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            padding-bottom: 6px;
            height: auto;
        }
        .icon-date {
            svg {
                color: #8bb8ce;
            }
        }
        .btn-secondary {
            position: relative;
            color: rgba(0, 0, 0, 0.65);
            font-weight: normal;
            text-align: center;
            background-color: #fafafa;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            transition: all 0.3s;
            padding: 6px 7px;

            margin-top: 1px;
        }

        .main-ora-range-date-picker .ant-input-group .ant-input{
            border-bottom-left-radius: 0 !important;
            border-top-left-radius: 0 !important;
        }
    `],
    providers: [VALUE_ACCESSOR],
})
export class OraRangeDatePickerComponent implements OnChanges, AfterViewInit {
    @Input() startDateRange?: any;
    @Input() endDateRange?: any;
    @Input() minDateStart?: Date;
    @Input() maxDateStart?: Date
    @Input() minDateEnd?: Date;
    @Input() maxDateEnd?: Date;
    @Input() endDate?: boolean;
    @Input() startDate?: boolean;
    @Input() isNoTitle?: boolean = false;
    @Input() titleFromDate?: string = 'Từ ngày';
    @Input() titleEndDate?: string = 'Đến ngày';
    @ViewChild('endatePicker') endatePicker: ElementRef;
    @Output() startDateRangeChange = new EventEmitter();
    @Output() endDateRangeChange = new EventEmitter();
    constructor() { }
    numberNow = Number(new Date());
    now = new Date(2500, 12, 31);
    itemsId = 0;
    lstConfigDate = [
        { id: 1, name: 'Hôm nay', class: "" },
        { id: 2, name: 'Hôm qua', class: "" },
        { id: 3, name: '7 ngày trước', class: "" },
        { id: 4, name: '30 ngày trước', class: "" },
        { id: 5, name: 'Tháng này', class: "" },
        { id: 6, name: 'Tháng trước', class: "" },
        { id: 15, name: 'Năm nay', class: "" },
        { id: 16, name: 'Năm trước', class: "" },
        { id: 7, name: 'Quý 1 năm nay', class: "" },
        { id: 8, name: 'Quý 2 năm nay', class: "" },
        { id: 9, name: 'Quý 3 năm nay', class: "" },
        { id: 10, name: 'Quý 4 năm nay', class: "" },
        { id: 11, name: 'Quý 1 năm trước', class: "" },
        { id: 12, name: 'Quý 2 năm trước', class: "" },
        { id: 13, name: 'Quý 3 năm trước', class: "" },
        { id: 14, name: 'Quý 4 năm trước', class: "" },

    ];

    ngOnInit() {
        for (let item of this.lstConfigDate) {
            if (item.id == this.itemsId) {
                item.class = "oda-active";
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        //this.startDateRangeChanges();
    }

    ngAfterViewInit(): void {

    }

    onChangeDate = function (items) {
        var days; // Days you want to subtract

        if (items.id <= 4) {
            if (items.id == 1) {
                days = 0;
            } else if (items.id == 2) {
                days = 1;
            }
            else if (items.id == 3) {
                days = 6;
            }
            else if (items.id == 4) {
                days = 29;
            }

            var date = new Date();
            var last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
            var day = last.getDate();
            var month = last.getMonth();
            var year = last.getFullYear();

            this.startDateRange = new Date(year, month, day);
            if (items.id == 1) {
                //this.startDateRange = new Date();
                this.endDateRange = new Date();
            }
            if (items.id == 2) {
                this.endDateRange = new Date(year, month, day);
            }
            else {
                this.endDateRange = date;
            }
        }

        if (items.id > 4 && items.id <= 6) {
            var date = new Date();
            var firstDay = new Date();
            var lastDay = new Date();

            if (items.id == 5) {
                firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            } else if (items.id == 6) {
                firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
            }
            this.startDateRange = firstDay;
            this.endDateRange = lastDay;
        }

        if (items.id > 6 && items.id <= 10) {
            // cac quy nam nay
            var date = new Date();
            var year = date.getFullYear();
            if (items.id == 7) {
                this.startDateRange = new Date(year, 0, 1);
                this.endDateRange = new Date(year, 2, 31);
            }
            else if (items.id == 8) {
                this.startDateRange = new Date(year, 3, 1);
                this.endDateRange = new Date(year, 5, 30);
            }
            else if (items.id == 9) {
                this.startDateRange = new Date(year, 6, 1);
                this.endDateRange = new Date(year, 8, 30);
            }
            else if (items.id == 10) {
                this.startDateRange = new Date(year, 9, 1);
                this.endDateRange = new Date(year, 11, 31);
            }
        }

        if (items.id > 10 && items.id <= 14) {
            // cac quy nam truoc
            var date = new Date();
            var year = date.getFullYear() - 1;

            if (items.id == 11) {
                this.startDateRange = new Date(year, 0, 1);
                this.endDateRange = new Date(year, 2, 31);
            }
            else if (items.id == 12) {
                this.startDateRange = new Date(year, 3, 1);
                this.endDateRange = new Date(year, 5, 30);
            }
            else if (items.id == 13) {
                this.startDateRange = new Date(year, 6, 1);
                this.endDateRange = new Date(year, 8, 30);
            }
            else if (items.id == 14) {
                this.startDateRange = new Date(year, 9, 1);
                this.endDateRange = new Date(year, 11, 31);
            }
        }

        if (items.id >= 15) {
            var date = new Date();
            if (items.id == 15) {
                this.startDateRange = new Date(date.getFullYear(), 0, 1);
                this.endDateRange = new Date(date.getFullYear(), 11, 31);
            } else if (items.id == 16) {
                this.startDateRange = new Date(date.getFullYear() - 1, 0, 1);
                this.endDateRange = new Date(date.getFullYear() - 1, 11, 31);
            }
        }

        for (let item of this.lstConfigDate) {
            item.class = "";
        }
        items.class = "oda-active";
        this.itemsId = items.id;

        // this.minDateEnd = this.startDateRange;
        // this.maxDateStart = this.endDateRange;
    };

    endDateRangeChanges($event) {
        //this.maxDateStart = this.endDateRange;
    }

    startDateRangeChanges($event?: any) {
        if (this.endDate == true) {
            this.minDateEnd = this.startDateRange;
        }
    }
}
