<div class="kt_searchview_input_container ant-input">
    <div aria-label="search" class="kt_searchview_facet" role="img" tabindex="0" *ngFor="let item of listFieldSelect; let index = index; trackBy: trackByIndex">
        <span class="kt_searchview_facet_label" [ngSwitch]="item.filterType">
            <ng-container *ngSwitchCase="FilterType.SearchByKeyword">
                {{item.fieldName === 'all' ? 'Từ khóa' : item.fieldDisplay}}
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.Filter">
                {{item.fieldDisplay}}
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.Group">
                <i class="fa fa-bars"></i>
            </ng-container>
        </span>
        <div class="kt_facet_values">
            <ng-container *ngIf="item.values?.length > 0">
                <span *ngFor="let value of item.values; let i = index">
                    <span *ngIf="i>0 && i <item.values.length"
                          class="kt_facet_values_sep"> hoặc </span> {{value}}
                </span>
            </ng-container>
        </div>

        <div aria-label="Remove" (click)="removeFilterSelect(item, index)" class="kt_facet_remove" role="img" title="Gỡ">
            <i nz-icon nzType="close" nzTheme="outline"></i>
        </div>
    </div>
    <input nz-dropdown [nzDropdownMenu]="filterSuggestion" [nzTrigger]="'click'" [(nzVisible)]="nzVisibleFilterSuggestion" aria-haspopup="true" class="kt_searchview_input kt-padding-l-0 "
           [(ngModel)]="keyword" id="keywordSearch" (keyup)="searchByKeyword($event)" (focus)="focusInput()" autocomplete="off" placeholder="Tìm..." type="text">
    <nz-dropdown-menu #filterSuggestion="nzDropdownMenu">
        <ul nz-menu style="min-width:200px">
            <ng-container *ngFor="let item of listFieldSuggestion;  let i = index; trackBy: trackByIndex">
                <li nz-menu-item class="d-flex justify-content-between align-items-center" [ngClass]="item.isActive ? 'bg-light': ''" (click)="selectField(item)" [class.selected]="item.selected">
                    <a class="color-primary font-size-12">
                        Tìm kiếm
                        <em> "{{item.fieldName === 'all' ? ' từ khóa' : item.fieldDisplay?.toLowerCase()}}" </em>
                        với giá trị:
                        <strong>{{keyword}}</strong>
                    </a>
                    <i nz-icon nzType="check" nzTheme="outline" *ngIf="item.selected"></i>
                </li>
            </ng-container>
        </ul>
    </nz-dropdown-menu>
</div>
