import { Component, Input } from '@angular/core';

@Component({
    selector: 'ord-search-list-page-layout',
    templateUrl: './search-list-page-layout.component.html',
    styleUrls: ['./search-list-page-layout.component.scss'],
})
export class SearchListPageLayoutComponent {
    constructor() { }
}
