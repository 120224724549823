<div [id]="formId">
    <nz-row>
        <div nz-col class="gutter-row" [nzSpan]="12">
            <label class="font-weight-normal">Mã danh mục<span class="text-red">*</span></label>
            <nz-form-control>
                <div class="example-input">
                    <input placeholder="Mã" nz-input [(ngModel)]="dataItem.code" [disabled]="isView"/>
                </div>
                <validation-custom [sModel]="dataItem.code"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="12">
            <label class="font-weight-normal">Kích hoạt</label>
            <nz-form-control>
                <nz-switch [(ngModel)]="dataItem.isActive" nzCheckedChildren="Có" nzUnCheckedChildren="Không" [nzDisabled]="isView"></nz-switch>
            </nz-form-control>
        </div>
    </nz-row>
    <nz-row >
        <div nz-col class="gutter-row" [nzSpan]="12">
            <label class="font-weight-normal">Tên tiếng việt</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.name" [disabled]="isView">
                <validation-custom [sModel]="dataItem.name"></validation-custom>
            </nz-form-control>
        </div>
    
        <div nz-col class="gutter-row" [nzSpan]="12">
            <label class="font-weight-normal">Tên tiếng anh</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.nameEn" [disabled]="isView" />
            </nz-form-control>
        </div>
    </nz-row>
    <nz-row >
        <div nz-col class="gutter-row" [nzSpan]="12">
            <label class="font-weight-normal">Ghi chú</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.ghiChu" [disabled]="isView">
            </nz-form-control>
        </div>
    
        <div nz-col class="gutter-row" [nzSpan]="12">
            <label class="font-weight-normal">Thứ tự</label>
            <nz-form-control>
                <input nz-input type="number" [(ngModel)]="dataItem.orderNumber" [disabled]="isView" />
            </nz-form-control>
        </div>
    </nz-row>
</div>


<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>