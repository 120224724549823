import { Component, Input, OnInit, Injector } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { ComboBoxTableCode, DichVuKyThuatTuongDuongDto, DichVuKyThuatTuongDuongServiceProxy, PhanLoaiPTTTDto } from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';
import { finalize } from '@node_modules/rxjs/operators';

@Component({
    selector: 'dich-vu-ky-thuat-tuong-duong-edit-modal',
    templateUrl: './dich-vu-ky-thuat-tuong-duong-edit-modal.component.html',
    styleUrls: ['./dich-vu-ky-thuat-tuong-duong-edit-modal.component.scss']
})
export class DichVuKyThuatTuongDuongEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formValidateDichVuKyThuatTuongDuong';
    ComboBoxTableCode = ComboBoxTableCode;

    @Input() dataItem: DichVuKyThuatTuongDuongDto = new DichVuKyThuatTuongDuongDto();
    @Input() isView = false;
    constructor(
        injector: Injector,
        private dichVuKyThuatTuongDuongServiceProxy: DichVuKyThuatTuongDuongServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
        }

    }

    getInfoById(id): void {
        abp.ui.setBusy();
        this.dichVuKyThuatTuongDuongServiceProxy
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.dataItem = response;

            });
    }



    save() {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error('Bạn cần nhập đủ dữ liệu các trường có dấu * đỏ !!');
            return;
        }
        abp.ui.setBusy();
        this.dichVuKyThuatTuongDuongServiceProxy
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new DichVuKyThuatTuongDuongDto());
    }

    Change(item: any) {
        this.dataItem.phanLoaiPTTTId = item?.data.id;
        this.dataItem.phanLoaiPTTTVietTat = item?.data.tenPTTTVietTat;
        this.dataItem.phanLoaiPTTT = item?.data.tenPTTTDayDu;
    }
    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion

}
