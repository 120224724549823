import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { inject } from '@angular/core/testing';
import * as $ from 'jquery';

@Component({
    selector: 'btn-upload-file',
    template: `
        <nz-input-group style="width:350px;" nzSearch [nzAddOnBefore]="prefixIconButton" [nzAddOnAfter]="suffixIconButton">
            <input
                #excelFileInput
                type="file"
                id="{{ idInputFile }}"
                (change)="incomingfile($event)"
                onclick="this.value=null;"
                placeholder="Upload file"
                accept=".xlsx,.xls"
                hidden="hidden"
            />
            <input nz-input type="text" (click)="clickChonFileBtn()" [(ngModel)]="tenFile" placeholder="Chọn file..." />
        </nz-input-group>

        <ng-template #prefixIconButton>
            <a title="Chọn file" (click)="clickChonFileBtn()"> <i nz-icon nzType="file-excel" nzTheme="outline"></i> Chọn file </a>
        </ng-template>

        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="reset()" title="Làm mới">
                <i nz-icon nzType="sync" nzTheme="outline"></i>
            </button>
        </ng-template>
    `,
})
export class BtnUploadFileComponent implements OnInit {
    @Input() listOfLoaiFile: any[] = ['xlsx', 'xls'];
    idInputFile = '';

    @Input() tenFile = 'Chưa chọn file';
    @Output() tenFileChange = new EventEmitter();

    @Input() fileHopLe = false;
    @Output() fileHopLeChange = new EventEmitter();

    @Input() file: File;
    @Output() fileChange = new EventEmitter();

    @Output() selectFileSuccessEvent = new EventEmitter();
    constructor() {
        this.idInputFile = 'inputFileMauKetQuaExcel_' + Number(new Date());
    }

    ngOnInit(): void {}

    clickChonFileBtn() {
        $('#' + this.idInputFile).click();
    }

    incomingfile(event) {
        const file = event.target.files[0];
        if (file && this.checkTenFile(file)) {
            this.fileHopLe = true;
            this.fileHopLeChange.emit(true);
            this.file = file;
            this.fileChange.emit(file);
            this.tenFile = file.name;
            this.tenFileChange.emit(file.name);
            this.selectFileSuccessEvent.emit(file);
            return;
        }

        abp.notify.error('File không hợp lệ! Vui lòng chọn lại file khác');
        //this.toaster.error('File không hợp lệ! Vui lòng chọn lại file khác');
    }

    checkTenFile(file) {
        const filename = file.name;
        const fileExt = filename.split('.').pop();
        return this.listOfLoaiFile.indexOf(fileExt) > -1;
    }

    reset() {
        this.fileHopLe = false;
        this.fileHopLeChange.emit(false);
        this.file = null;
        this.fileChange.emit(null);
        this.tenFile = 'Chưa chọn file';
        this.tenFileChange.emit('Chưa chọn file');
    }
}
