import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ora-layout-filter',
    templateUrl: './ora-layout-filter.component.html',
    styleUrls: ['./ora-layout-filter.component.scss'],
})
export class OraLayoutFilterComponent implements OnInit {
    @Input() searchAfterEnter = true;
    @Input() isHasSpaceControl: boolean = false;
    @Input() isUsingReset = false;
    @Output() onSearch = new EventEmitter();
    @Output() onReset = new EventEmitter();
    @Output() showAdvanced = new EventEmitter();
    constructor() {
    }

    ngOnInit(): void {
    }

    search() {
        this.onSearch.emit();
    }

    reset() {
        this.onReset.emit();
    }

    onShowAdvanced() {
        this.showAdvanced.emit();
    }
    onEnter() {
        if (this.searchAfterEnter) {
            this.search();
        }
    }
}
