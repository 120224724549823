import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { KhoaPhongDto } from '@shared/service-proxies/service-proxies';

import { LoadModuleService } from '@app/shared/services/load-module.service';
import { KhoaPhongCreateEditModalComponent } from '../khoa-phong-create-edit-modal/khoa-phong-create-edit-modal.component';
import { KhoaPhongSearchAdvanceComponent } from '../khoa-phong-search-advance/khoa-phong-search-advance.component';

@Injectable({
    providedIn: 'root',
})
export class KhoaPhongUXService {
    constructor(private modalService: NzModalService,
        private loadModule: LoadModuleService) { }

    public async openCreateOrEditModal(option: { dataItem: KhoaPhongDto; isView?: boolean; callBack?: any }): Promise<void> {
        await this.loadModule.loadModuleQuanLyCoSo();
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.isView ? 'Xem chi tiết' : (option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ');
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: KhoaPhongCreateEditModalComponent,
            nzComponentParams: {
                dataItem: Object.assign(new KhoaPhongDto(), { ...option.dataItem }),
                isView: option.isView
            },
            nzMaskClosable: false,
            nzWidth: '60%',
        });

        modal.afterClose.subscribe((result: KhoaPhongDto) => {
            option.callBack(result);
        });
    }

    public openSearchListModal(option: {
        listOfSelectedValue: number[];
        title?: string;
        isMulti?: boolean;
        callBack: any;
    }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: KhoaPhongSearchAdvanceComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue],
                //isMulti: option.isMulti,
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '80%',
        });

        modal.afterClose.subscribe((result: KhoaPhongDto[]) => {
            // if (result != null && result.length > 0) {
            //     option.callBack(result);
            // }
            if (result) {
                option.callBack(result);
            }
        });
    }
}