<ora-filter-spin (onSearch)="refresh()" (onRefresh)="clear()" (keydown.enter)="refresh()" [widthColSeach]="16"
    [hasAdvancedSearch]="true" [isShowTitleSearch]="true" [showAdvancedSearch]="true">
    <input nz-input class="txtSearch" type="text" [(ngModel)]="searchDto.filter"
        placeholder="Nhập từ khóa để tìm kiếm..." />

    <ng-container advancedSearch>
        <div nz-row [nzGutter]="8">
            <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6">
                <label class="font-weight-normal">Loại hình cơ sở</label>
                <enum-combo [EnumCode]="ComboBoxEnumCode.LEVEL" [(ngModel)]="searchDto.arrLevelSearch"
                            [isMulti]="true">
                </enum-combo>
            </div>
            <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" *ngIf="!maTinh">
                <label class="font-weight-normal">Tỉnh</label>
                <don-vi-hanh-chinh-chinh-combo [(ngModel)]="searchDto.maTinhSearch"
                    [capDo]="CapDoDonViHanhChinh.Tinh"></don-vi-hanh-chinh-chinh-combo>
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6" *ngIf="!maTinh && !maHuyen">
                <label class="font-weight-normal">Huyện</label>
                <don-vi-hanh-chinh-chinh-combo [(ngModel)]="searchDto.maHuyenSearch" [capDo]="CapDoDonViHanhChinh.Huyen"
                    [parentCode]="searchDto.maTinhSearch"></don-vi-hanh-chinh-chinh-combo>
            </div>

            <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="6">
                <label class="font-weight-normal">Cơ quan chủ quản</label>
                <table-combo [TableEnum]="ComboBoxTableCode.GetAllCoQuanChuQuan" [(ngModel)]="searchDto.pIdSearch">
                </table-combo>
            </div>
        </div>
    </ng-container>


</ora-filter-spin>
<ora-table #datatable [rows]="dataList" [isLoading]="isTableLoading" [isMulti]="isMulti" [totalRows]="totalItems"
    [(pageSize)]="pageSize" [(page)]="pageNumber" (pageSizeChange)="refresh()" (pageChange)="pageNumberChange()"
    (sortChange)="gridSort($event)" [(selectedDataItems)]="selectedDataItems"
    [scroll]="{ x: '600px', y:'calc(100vh - 56px)' }">
    <ng-container titleTable>
        <label class="font-weight-normal">Danh sách ({{ totalItems | oraNumber: '1.0-0':'en' }} bản
            ghi)</label>
    </ng-container>

    <ora-column dataType="Select" width="50"></ora-column>
    <ora-column key="maKCB" header="Mã KCB(BHYT)" width="120"></ora-column>
    <ora-column key="tenCoSo" header="Tên cơ sở" width="200"></ora-column>
    <ora-column key="sLevel" header="Loại hình cơ sở" width="120"></ora-column>
    <ora-column key="tenTinh" header="Tỉnh" width="150"></ora-column>
    <ora-column key="tenHuyen" header="Huyện" width="150"></ora-column>
</ora-table>

<ng-container>
    <nz-form-item *nzModalFooter>
        <nz-form-control>
            <div>
                <btn-add-item typeBtn="secondary" (clickEvent)="close()" icon="close" title="Đóng" text="Đóng">
                </btn-add-item>
                <btn-add-item *ngIf="selectedDataItems.length>0" typeBtn="primary" (clickEvent)="save()" icon="save"
                    title="Chọn" text="Chọn">
                </btn-add-item>
            </div>
        </nz-form-control>
    </nz-form-item>
</ng-container>