import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LstDictionaryServiceProxy } from '@shared/service-proxies/service-proxies';
import _ from 'lodash';
import { finalize, map } from 'rxjs/operators';
import { AppUtilityService } from '../../../../common/custom/utility.service';
import { filter } from '@node_modules/rxjs/internal/operators';

@Component({
    selector: 'checkbox-list',
    template: ` <div class="ora-combo">
        <ng-container>
            <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="changeModelValue($event)">
                <div nz-row>
                    <div nz-col nzSpan="24" *ngFor="let item of arrData">
                        <label nz-checkbox [nzValue]="item.value" [ngModel]="item.checked">{{item.displayText}}</label>
                    </div>
                </div>
            </nz-checkbox-wrapper>            
        </ng-container>
    </div>`,
    styles: [
    ],
    providers: [
    ],
})
export class CheckboxListComponent implements OnInit {

    @Input() sModel: string[] = [];
    @Output() sModelChange = new EventEmitter();
    @Input() dictionaryTypeCode;
    arrData: any[] = [];
    loading = false;

    constructor(
        private _LstDictionaryServiceProxy: LstDictionaryServiceProxy,
    ) {

    }
    ngOnInit() {
    }

    changeModelValue(event) {

        this.sModel = event;
        this.sModelChange.emit(this.sModel);

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dictionaryTypeCode) {
            this.getDataSourceFromServer();
        }
    }

    getDataSourceFromServer() {
        this.loading = true;
        this._LstDictionaryServiceProxy
            .getToDDLByType(this.dictionaryTypeCode)
            .pipe(
                map((list) => {
                    return list.map((item) => {
                        return Object.assign(item, {
                            fts: AppUtilityService.getFullTextSearch(item.displayText),
                        });
                    });
                }),
                finalize(() => {
                    this.loading = false;
                }),
            )
            .subscribe((result) => {
                this.arrData = result;
                if (this.sModel) {
                    this.arrData.filter(x => this.sModel.includes(x.value)).map(x => x.checked = true);
                }
            });
    }
}
