<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="8">
        <div nz-col class="gutter-row ord-form-control"  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Mã<span class="text-red">*</span>
                <span *ngIf="!(dataItem.id > 0)">
                    (<nz-switch nzCheckedChildren="Có" title="Hệ thống tự sinh" nzUnCheckedChildren="Không"
                        [(ngModel)]="dataItem.isMaTuSinh"> </nz-switch>)
                </span>
            </label>
            <input nz-input [(ngModel)]="dataItem.ma" [disabled]="dataItem.isMaTuSinh">
            <validation-custom *ngIf="!dataItem.isMaTuSinh" [sModel]="dataItem.ma"></validation-custom>
        </div>

        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">Loại nguyên nhân<span class="text-red">*</span></label>
            
            <enum-combo [EnumCode]="ComboBoxEnumCode.NhomNguyenNhan" [(ngModel)]="dataItem.nhomNguyenNhan" [disabled]="isView">
            </enum-combo>
            <validation-custom [sModel]="dataItem.nhomNguyenNhan"></validation-custom>
        </div>
        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label class="font-weight-normal">Nguyên nhân gây ra sự cố<span class="text-red">*</span></label>
            <textarea rows="4" nz-input [(ngModel)]="dataItem.tenNguyenNhan"></textarea>
            <validation-custom [sModel]="dataItem.tenNguyenNhan"></validation-custom>
        </div>

        <div nz-col class="gutter-row ord-form-control" nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label nz-checkbox [(ngModel)]="dataItem.isKhac" [disabled]="isView">Nguyên nhân khác</label>
        </div>
    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" [nzLoading]="saving" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
