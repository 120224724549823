import { ChangeDetectorRef, Component, Inject, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { finalize, takeUntil } from 'rxjs/operators';
import { DestroyRxjsService } from 'src/shared/destroy-rxjs.service';
import { SubscriptionService } from '@abp/ng.core';
import { PagedListingComponentBase, PagedRequestDto } from '@shared/common/paged-listing-component-base';
import { DonViHanhChinhDto, DonViHanhChinhPagingListRequest, DonViHanhChinhServiceProxy } from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';

@Component({
    templateUrl: './don-vi-hanh-chinh-select-search-modal.component.html',
})
export class DonViHanhChinhSelectSearchModalComponent extends PagedListingComponentBase<any> implements OnInit {
    @Input() listOfSelectedValue: number[];
    isMulti: boolean = false;
    filter: any = {};
    constructor(
        injector: Injector,
        private _dataService: DonViHanhChinhServiceProxy,
        private modal: NzModalRef,
        private cdr: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.searchDto = _.cloneDeep(this.filter);
        this.refresh();
    }

    clear() {
        this.searchDto = _.cloneDeep(this.filter);
        this.refresh();
    }

    protected fetchDataList(request: PagedRequestDto, pageNumber: number, finishedCallback: () => void): void {
        const input: DonViHanhChinhPagingListRequest = new DonViHanhChinhPagingListRequest();
        input.maxResultCount = request.maxResultCount;
        input.skipCount = request.skipCount;
        input.sorting = request.sorting;

        let rep: any = {
            ...input,
            ... this.searchDto
        }

        this._dataService.donViHanhChinhPagingList(rep)
            .pipe(finalize(finishedCallback))
            .subscribe((result) => {
                this.dataList = result.items;
                this.dataList.forEach(item => {
                    if (this.listOfSelectedValue && this.listOfSelectedValue.length > 0) {
                        let checkId = this.listOfSelectedValue.findIndex(x => x == item.id);
                        if (checkId > -1) {
                            item.checked = true;
                        }
                    }
                })

                this.showPaging(result);
                this.cdr.detectChanges();
            });
    }


    save() {
        this.modal.close(this.selectedDataItems)
    }

    close() {
        this.modal.destroy();
    }

    //#region UX-JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {

            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header'
        });

    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();;
    }
    //#endregion
}
