import { Injectable } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { DichVuKyThuatTuongDuongDto } from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DichVuKyThuatTuongDuongEditModalComponent } from '../dich-vu-ky-thuat-tuong-duong-edit-modal/dich-vu-ky-thuat-tuong-duong-edit-modal.component';
import { DichVuKyThuatTuongDuongSearchModalComponent } from '../dich-vu-ky-thuat-tuong-duong-search-modal/dich-vu-ky-thuat-tuong-duong-search-modal.component';

@Injectable({
    providedIn: 'root'
})
export class DichVuKyThuatTuongDuongUxService {

    constructor(private modalService: NzModalService) { }

    public openCreateOrEditModal(option: { dataItem: DichVuKyThuatTuongDuongDto; isView?: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = AppUtilityService.isNullOrWhiteSpace(option.dataItem.maThongTu) ? 'Thêm mới' : 'Cập nhật ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: DichVuKyThuatTuongDuongEditModalComponent,
            nzComponentParams: {
                dataItem: Object.assign(new DichVuKyThuatTuongDuongDto(), { ...option.dataItem }),
                isView: option.isView,
            },
            nzWidth: '30%',
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result: DichVuKyThuatTuongDuongDto) => {
            if (result != null) {
                option.callBack(result);
            }
        });
    }

    public openSearchListModal(option: {
        listOfSelectedValue: number[];
        title?: string;
        isMulti?: boolean;
        callBack: any;
    }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.title || 'Danh sách ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: DichVuKyThuatTuongDuongSearchModalComponent,
            nzComponentParams: {
                listOfSelectedValue: [...option.listOfSelectedValue],
                isMulti: option.isMulti,
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '100%',
        });

        modal.afterClose.subscribe((result: DichVuKyThuatTuongDuongDto[]) => {
            if (result != null && result.length > 0) {
                option.callBack(result);
            }
        });
    }

}
