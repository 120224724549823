<layout-header class="alain-default__header"></layout-header>
<!--<layout-navbar></layout-navbar>-->
<layout-sidebar-custom class="alain-default__aside" #layoutSidebar></layout-sidebar-custom>
<!-- <layout-sidebar class="alain-default__aside" #layoutSidebar></layout-sidebar> -->
<section class="alain-default__content">
    <section style="margin-bottom: 60px;" class="alain-fullscreen">

        <reuse-tab [mode]="2" #reuseTab></reuse-tab>
        <router-outlet (activate)="reuseTab.activate($event)"></router-outlet>
        
        <!--<router-outlet></router-outlet>-->
    </section>
</section>

<ng-template #settingHost></ng-template>

<nz-back-top></nz-back-top>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>
<div class="l-footer " >
    <layout-footer></layout-footer>
  </div> 