import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, Injector, OnInit } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { ProfileServiceProxy, SessionServiceProxy, TenantLoginInfoDto } from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DangKyBoSungModalComponent } from '../../profile/dang-ky-bo-sung-modal.component';
import { ViewEncapsulation } from '@angular/core';
import { ImpersonationService } from '../../../../pages/admin/users/impersonation.service';
import { AbpSessionService } from 'abp-ng2-module';

@Component({
    selector: 'header-user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HeaderUserComponent extends AppComponentBase implements OnInit {
    shownLoginNameTitle = '';
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    isGrantedChuKySo = false;
    isImpersonatedLogin = false;
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    tenant: TenantLoginInfoDto = new TenantLoginInfoDto();

    constructor(
        injector: Injector,
        private authService: AppAuthService,
        private route: Router,
        private modalSrv: NzModalService,
        private messageSrv: NzMessageService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _sessionServiceProxy: SessionServiceProxy,
        private _abpSessionService: AbpSessionService,
        private _impersonationService: ImpersonationService,
    ) {
        super(injector);
    }    

    ngOnInit(): void {
        this.setCurrentLoginInformations();
        this.getProfilePicture();
        this.registerToEvents();
        this.isGrantedChuKySo = this.isGranted("Pages.KyDienTu");
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
    }

    clearSessionStore(): void {
        this.modalSrv.confirm({
            nzTitle: 'Chắc chắn xóa bộ nhớ đệm?',
            nzOnOk: () => {
                //this.ClearUserSessionCache();
                sessionStorage.clear();
                localStorage.clear();
                this.messageSrv.success('Clear Finished!');
                window.location.reload();
            },
        });
    }

    changePassword(): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = this.l('ChangePassword');
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: ChangePasswordModalComponent,
            nzFooter: null,
            nzWidth: '50%',
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result) => {
            if (result) {
                this.logout();
            }
        });
    }

    thietLapChuKySo(): void {
        this.route.navigate([`app/he-thong/thiet-lap-chu-ky-so`])
    }

    changeInfo(doanhNghiepId): void {
        // this._doanhNghiepService.getById(doanhNghiepId).subscribe(result => {
        //     this.modalHelper.create(EditDoanhNghiepComponent, { dataItem: result }).subscribe(result => {

        //     });
        // });
    }

    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture();
        });
    }

    setCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user?.userName;
        this.tenant = this.appSession.tenant;
    }

    getProfilePicture(): void {
        if (this.appSession.userId) {
            this._profileServiceProxy.getProfilePicture()
                .subscribe(result => {
                    if (result && result.profilePicture) {
                        this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
                    }
                });
        }
    }

    showLoginAttempts(): void {
        this.modalHelper.create(LoginAttemptsModalComponent).subscribe(result => { });
    }

    changeMySettings(): void {
        this.modalHelper.createStatic(MySettingsModalComponent).subscribe(result => { });
    }

    changeProfilePicture(): void {
        this.modalHelper.createStatic(ChangeProfilePictureModalComponent).subscribe(_ => { });
    }

    logout(): void {
        this._sessionServiceProxy.clearUserSession().subscribe();
        this.authService.logout();
    }

    backToMyAccount() {
        this._impersonationService.backToImpersonator();
    }

    showOpenDangKyBoSungTinhNang() {

        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = "Đăng ký bổ sung mục đích sử dụng";

        this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: DangKyBoSungModalComponent,
            nzComponentParams: {
            },
            nzMaskClosable: false,
            nzWidth: '720px'
        }).afterClose.subscribe(res => {
            if (res) {
            }
        });
    }
}
