import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService, SettingsService } from '@delon/theme';

@Component({
    selector: 'layout-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent {
    constructor(
        public settingsSrv: SettingsService,
        private route: Router,
        private _ngAlainMenuService: MenuService,
        private cdref: ChangeDetectorRef
    ) {
        
    }

    ngAfterViewInit(): void {
        if (this.route.url.startsWith('/app/danh-muc/')) {
            this._ngAlainMenuService.setItem('Pages_DanhMuc_Menu', {_selected : true});
            this.cdref.detectChanges();
        }

        this.route.events.subscribe(event => {
            if(event instanceof NavigationEnd) {
                if(event.url.startsWith('/app/danh-muc/')) { 
                    this._ngAlainMenuService.setItem('Pages_DanhMuc_Menu', {_selected : true});
                }
            }
        })
    }



    get collapsed() {
        return this.settingsSrv.layout.collapsed;
    }
    toggleCollapsed() {
        this.settingsSrv.setLayout('collapsed', !this.collapsed);
    }
}
