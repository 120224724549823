<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[4, 4]">
        <div nz-col class="gutter-row " [nzSm]="12" [nzXs]="12">
            <label class="font-weight-normal">Mã <span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.ma" >
                <validation-custom [sModel]="dataItem.ma"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row " [nzSm]="12" [nzXs]="12">
            <label class="font-weight-normal">Loại báo cáo <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <enum-combo [EnumCode]="ComboBoxEnumCode.LoaiBaoCaoTet"  [(ngModel)]="dataItem.loaiDotBaoCaoEnum" [disabled]="isView"   >
                </enum-combo>
                <validation-custom [sModel]="dataItem.loaiDotBaoCaoEnum"></validation-custom>
            </nz-form-control>
        </div>
    </div>

    <div nz-row [nzGutter]="[4, 4]">
        <div nz-col class="gutter-row ord-form-control" [nzSm]="24" [nzXs]="24">
            <label class="font-weight-normal">Nội dung <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input nz-input [(ngModel)]="dataItem.ten">
                <validation-custom [sModel]="dataItem.ten"></validation-custom>
            </nz-form-control>
        </div>
    </div>


    <div nz-row >

        <div nz-col class="gutter-row ord-form-control" [nzSm]="6" [nzXs]="6">
            <label class="font-weight-normal">Sắp xếp <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input  type="number" nz-input [(ngModel)]="dataItem.sortOrder">
                <validation-custom [sModel]="dataItem.sortOrder"></validation-custom>
            </nz-form-control>
        </div>
       
        <div nz-col class="gutter-row " [nzSm]="6" [nzXs]="6">
            <label class="font-weight-normal">Kích hoạt</label>
            <nz-form-control>
                <nz-switch [(ngModel)]="dataItem.isActive" nzCheckedChildren="Có" nzUnCheckedChildren="Không">
                </nz-switch>
            </nz-form-control>
        </div>

        <div nz-col class="gutter-row " [nzSm]="6" [nzXs]="6">
            <label class="font-weight-normal">Khám, cấp cứu</label>
            <nz-form-control>
                <nz-switch [(ngModel)]="dataItem.isKham" nzCheckedChildren="Có" nzUnCheckedChildren="Không">
                </nz-switch>
            </nz-form-control>
        </div>

        <div nz-col class="gutter-row " [nzSm]="6" [nzXs]="6" *ngIf="!dataItem.isKham">
            <label class="font-weight-normal">Thống kê cộng dồn</label>
            <nz-form-control>
                <nz-switch [(ngModel)]="dataItem.isCongDon" nzCheckedChildren="Có" nzUnCheckedChildren="Không">
                </nz-switch>
            </nz-form-control>
        </div>
    
    </div>
    <div nz-row>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="24" [nzXs]="24">
            <label class="font-weight-normal">Ghi chú</label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input nz-input [(ngModel)]="dataItem.ghiChu">
            </nz-form-control>
        </div>
    </div>
    <div nz-row>

    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>