<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Cấp độ<span class="text-red">*</span></label>
            <nz-form-control>
                <enum-combo [EnumCode]="ComboBoxEnumCode.CapDoDonViHanhChinh" [(ngModel)]="dataItem.capDo"
                    (ngModelChange)="checkViewByCapDo()" #capDo [disabled]="isView"></enum-combo>
                <validation-custom [sModel]="dataItem.capDo" [nativeElement]="capDo"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Mã<span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.code" [disabled]="isView">
                <validation-custom [sModel]="dataItem.code"></validation-custom>
            </nz-form-control>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Tên<span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.ten" [disabled]="isView">
                <validation-custom [sModel]="dataItem.ten"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">Tên viết tắt</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.tenVietTat" [disabled]="isView">
            </nz-form-control>
        </div>
    </div>
    <div nz-row>

        <div nz-col nzXs="24" nzSm="8" *ngIf="dataItem.capDo > CapDoDonViHanhChinh.Xa">
            <label class="font-weight-normal">Xã<span class="text-red">*</span></label>
            <nz-form-control>
                <don-vi-hanh-chinh-chinh-combo [(ngModel)]="dataItem.maXa" [capDo]="CapDoDonViHanhChinh.Xa"
                    [parentCode]="dataItem.maHuyen" [disabled]="isView">
                </don-vi-hanh-chinh-chinh-combo>
                <validation-custom [sModel]="dataItem.maXa"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="8" *ngIf="dataItem.capDo > CapDoDonViHanhChinh.Huyen">
            <label class="font-weight-normal">Huyện<span class="text-red">*</span></label>
            <nz-form-control>
                <don-vi-hanh-chinh-chinh-combo [(ngModel)]="dataItem.maHuyen" [capDo]="CapDoDonViHanhChinh.Huyen"
                    [parentCode]="dataItem.maTinh" [disabled]="isView">
                </don-vi-hanh-chinh-chinh-combo>
                <validation-custom [sModel]="dataItem.maHuyen"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="8" *ngIf="dataItem.capDo > CapDoDonViHanhChinh.Tinh">
            <label class="font-weight-normal">Tỉnh<span class="text-red">*</span></label>
            <nz-form-control>
                <don-vi-hanh-chinh-chinh-combo [(ngModel)]="dataItem.maTinh" [capDo]="CapDoDonViHanhChinh.Tinh"
                    [disabled]="isView">
                </don-vi-hanh-chinh-chinh-combo>
                <validation-custom [sModel]="dataItem.maTinh"></validation-custom>
            </nz-form-control>
        </div>
    </div>
    <div nz-row>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal"> Vĩ độ (latitude) </label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.latitude" [disabled]="isView" />
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal"> Kinh độ (longitude)</label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.longitude" [disabled]="isView" />
            </nz-form-control>
        </div>
    </div>
    
    <div nz-row [nzGutter]="16" *ngIf="dataItem.capDo === CapDoDonViHanhChinh.Tinh">
        <div style="display: flex; flex-direction: column;margin-top: 10px; " nz-col class="gutter-row" nzXs="24"
            nzLg="8" nzXXl="6">
            <label nz-checkbox [(ngModel)]="dataItem.isTrungUong"><b>Trực thuộc trung ương</b></label>
        </div>
    </div>

    <div nz-row>
        <div nz-col nzXs="24">
            <label class="font-weight-normal">Kích hoạt</label>
            <nz-form-control>
                <nz-switch [(ngModel)]="dataItem.isActive" nzCheckedChildren="Có" nzUnCheckedChildren="Không"
                    [nzDisabled]="isView"></nz-switch>
            </nz-form-control>
        </div>
    </div>

    

</div>


<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon
            nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>