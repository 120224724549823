import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import {
    BCT_CauHinhLichAmDto,
    BCT_DotBaoCaoDto,
    BCT_DotBaoCaoChiTietDto,
    BCT_DotBaoCaoServiceProxy,
    ComboBoxEnumCode,
    ComboBoxTableCode,
} from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';
import { finalize } from 'rxjs/operators';
import { differenceInCalendarDays } from 'date-fns';
import { DateTime } from 'luxon';
import { ChiSoBaoCaoAddComponent } from '../../chi-so-bao-cao/modal/chi-so-bao-cao-add/chi-so-bao-cao-add.component';
 
@Component({
    templateUrl: './dot-bao-cao-edit-modal.component.html',
})
export class DotBaoCaoEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formValidateDotBaoCao';
    namBaoCao: any;
    tabIndex = 0;
    @Input() dataItem: BCT_DotBaoCaoDto;
    ComboBoxEnumCode = ComboBoxEnumCode;
    disabledDate?: (d: Date) => boolean;
    cauHinhLichAm: BCT_CauHinhLichAmDto[] = [];
    cauHinhChiSo: BCT_DotBaoCaoChiTietDto[] = [Object.assign(new BCT_DotBaoCaoChiTietDto(), { sortOrder: 1 })];
    ComboBoxTableCode = ComboBoxTableCode;

    constructor(injector: Injector, private _BCT_DotBaoCaoService: BCT_DotBaoCaoServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
        }
    }

    getInfoById(id: number): void {
        abp.ui.setBusy();
        this._BCT_DotBaoCaoService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {

                this.cauHinhLichAm = response.cauHinhLichAm;
                this.cauHinhChiSo = response.cauHinhChiSo;
                this.dataItem = response;
            });
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error('Bạn cần nhập đủ dữ liệu các trường có dấu * đỏ !!');
            return;
        }
        abp.ui.setBusy();

        this.dataItem.cauHinhLichAm = this.cauHinhLichAm;
        this.dataItem.cauHinhChiSo = this.cauHinhChiSo;

        this._BCT_DotBaoCaoService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new BCT_DotBaoCaoDto(), {
            isDungMaHeThong: true,
            isActive: false,
            isKham: true,
        });
    }
    setMinNgayKetThuc(date: DateTime) {
        this.dataItem.ngayKetThuc = null;
        if (date != null) {
            this.disabledDate = (current: Date): boolean => differenceInCalendarDays(current, date.toJSDate()) < 0;
        }
        this.calcListNgayBaoCao();
    }
    calcListNgayBaoCao() {
        this.cauHinhLichAm = [];
        if (this.dataItem.ngayBatDau != null && this.dataItem.ngayKetThuc != null) {
            let ngayBatDau = this.dataItem.ngayBatDau;
            let ngayKetThuc = this.dataItem.ngayKetThuc;
            while (ngayBatDau <= ngayKetThuc) {
                var ngayObj = Object.assign(new BCT_CauHinhLichAmDto(), {
                    ngayDuong: ngayBatDau,
                    sNgayDuong: ngayBatDau.toFormat('dd/MM/yyyy'),
                });
                this.cauHinhLichAm.push(ngayObj);
                ngayBatDau = ngayBatDau.plus({ days: 1 });
            }
        }
    }
    themChiSo() {
        this.modalService.create({
            nzTitle: "Thêm mới chỉ số",
            nzContent: ChiSoBaoCaoAddComponent,
            nzComponentParams: {
                listOfSelectedValue: this.cauHinhChiSo.map(x => x.chiSoId),
                loaiDotBaoCaoEnum: this.dataItem.loaiDotBaoCaoEnum,
            },
            nzFooter: null,
            nzMaskClosable: false,
            nzWidth: '60%',
        }).afterClose.subscribe(v => {
            if(v) {
                v.forEach(it => {
                    let chiSo =  new BCT_DotBaoCaoChiTietDto();
                    chiSo.maChiSo = it.ma;
                    chiSo.tenChiSo = it.ten;
                    chiSo.chiSoId = it.id;
                    chiSo.sortOrder = it.sortOrder;
                    this.cauHinhChiSo = [...this.cauHinhChiSo, chiSo];
                })
                
            }

        });
    }
    xoaChiSo(idx) {
        this.cauHinhChiSo.splice(idx, 1);
    }

    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }

    changeDotBaoCao($event) {
        if($event) {
            abp.ui.setBusy();
        this._BCT_DotBaoCaoService.getListChiSoByLoaibaoCaoId($event.value)
            .pipe(finalize(() => {abp.ui.clearBusy()}))
            .subscribe(val => {
                this.cauHinhChiSo = val;
            })
        }
        
    }
    //#endregion
}
