import { Injectable } from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { AMR_OrganismDto} from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AMRViKhuanEditComponent } from '../amr-vi-khuan-edit-modal/amr-vi-khuan-edit-modal.component';




@Injectable({
    providedIn: 'root'
})
export class AMRViKhuanUxService {

    constructor(private modalService: NzModalService) { }

    public openCreateOrEditModal(option: { dataItem: AMR_OrganismDto; isView?: boolean; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.isView ? 'Xem chi tiết' : (option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ');
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
          nzContent: AMRViKhuanEditComponent,
            nzComponentParams: {
                dataItem: Object.assign(new AMR_OrganismDto(), { ...option.dataItem }),
                isView: option.isView,
            },
            nzWidth: '70%',
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result: AMR_OrganismDto) => {
            if (result != null) {
                option.callBack(result);
            }
        });
    }
    
}
