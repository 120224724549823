<nz-row class="root" nzJustify="center" nzGutter="16" nzAlign="middle">
    <nz-col nzFlex="64px" class="col-filter">
        <i nz-icon nzType="filter" nzTheme="outline"></i>
    </nz-col>
    <div nz-col nzFlex="auto" class="col-content" (keydown.enter)="onEnter()">
        <ng-content></ng-content>
    </div>
    <div nz-col class="col-action">
        <!-- <button class="btn-search" nz-button nzType="default" (click)="search()">Áp dụng</button> -->
        <nz-form-label class="font-weight-500" style="visibility: hidden;" *ngIf="isHasSpaceControl">
            Tìm kiếm
        </nz-form-label>
        <div>
            <button class="btn-search" nz-button nzType="primary" (click)="search()">Tìm kiếm</button>
            <button class="btn-refresh" *ngIf="isUsingReset" nz-button nzType="default" (click)="reset()">Đặt lại</button>
        </div>
    </div>
</nz-row>