import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ChucVuDto, KhoaPhongDto } from '@shared/service-proxies/service-proxies';

import { LoadModuleService } from '@app/shared/services/load-module.service';
import { ChucVuEditComponent } from '../chuc-vu-edit-modal/chuc-vu-edit-modal.component';


@Injectable({
    providedIn: 'root',
})
export class ChucVuUXService {
    constructor(private modalService: NzModalService,
        private loadModule: LoadModuleService) { }

    public async openCreateOrEditModal(option: { dataItem: KhoaPhongDto; isView?: boolean; callBack?: any }): Promise<void> {
        await this.loadModule.loadModuleHeThong();
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.isView ? 'Xem chi tiết' : (option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ');
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: ChucVuEditComponent,
            nzComponentParams: {
                dataItem: Object.assign(new ChucVuDto(), { ...option.dataItem }),
                isView: option.isView
            },
            nzMaskClosable: false,
            nzWidth: '30%',
        });

        modal.afterClose.subscribe((result: ChucVuDto) => {
            option.callBack(result);
        });
    }
}