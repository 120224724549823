import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboServiceProxy, ComboTableInputDtoOfString } from '@shared/service-proxies/service-proxies';
import _ from 'lodash';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { NzSelectModeType } from 'ng-zorro-antd/select';

@Component({
    selector: 'danh-muc-tuong-duong',
    template: ` <div class="ora-combo">
        
        <nz-select
                    nzShowSearch
                    nzServerSearch
                    [nzMaxTagCount]="maxTagCount"
                    [nzMaxTagPlaceholder]="maxTagPlaceholderTmpl"
                    [nzAllowClear]="nzAllowClear"
                    nzPlaceHolder="{{ placeHolders }}"
                    [nzMode]="nzMode"
                    [nzLoading]="loading"
                    [(ngModel)]="_value"
                    [nzDisabled]="_isDisabled"
                    (nzFocus)="(onFocus)"
                    style="width:100%"
                    (ngModelChange)="onChangeValue($event)"
                    (nzOnSearch)="search$.next($event)"
                    [nzDropdownClassName]="'oda-select-dropdown'"
                    [nzDropdownRender]="renderTemplate"
                    [nzSuffixIcon]="prefixTemplateUser"
                >
                    <nz-option *ngFor="let item of optionList" [nzLabel]="item.displayText" [nzValue]="item.value"> </nz-option>

                    <ng-template #renderTemplate>
                        <hr *ngIf="isAdd || isSearchAdvance" />
                        <div *ngIf="isSearchAdvance">
                            <a nz-button nzType="link" (click)="searchAdvance()"
                                ><i nz-icon nzType="search" nzTheme="outline"></i> Tìm kiếm</a
                            >
                        </div>
                        <div *ngIf="isAdd">
                            <a nz-button nzType="link" (click)="openCreateOrEditModal(0)"
                                ><i nz-icon nzType="plus-circle" nzTheme="outline"></i> Thêm mới</a
                            >
                        </div>
                    </ng-template>

                    <ng-template #prefixTemplateUser><i nz-icon nzType="down" nzTheme="outline"></i></ng-template>
                </nz-select>
                <button nz-button nzType="text" (click)="openCreateOrEditModal(_value)" *ngIf="isUpdate && _value && !disabled">
                    <i nz-icon nzType="edit"></i>
                </button>
                <ng-template #maxTagPlaceholderTmpl let-selectedList>+ {{ selectedList.length }} </ng-template>
    </div>`,
    styles: [
        `
            .ora-combo {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            .ora-combo nz-select {
                width: 100%;
            }

            .ora-close {
                cursor: pointer;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DanhMucTuongDuongComponent),
            multi: true,
        },
    ],
})
export class DanhMucTuongDuongComponent  implements OnInit, ControlValueAccessor {
    @Input() placeHolders?: string = '- Chọn (Nhập để tìm kiếm ...) -';
    @Input() nzAllowClear?: boolean = true;
    @Input() maxOptionRender = 20;
    @Input() maxTagCount = 5;
    @Input() isAdd = false;
    @Input() isUpdate = false;
    @Input() isSearchAdvance = false;
    @Output() eventChange = new EventEmitter<any>();

    search$ = new Subject<string>();
    nzMode: NzSelectModeType = 'default';
    _value: any | string | number = '';
    public optionList: any[] = [];
    public optionListDefault: any[] = [];
    _isDisabled = false;
    loading = false;
    private searchSubscription: Subscription;
    private dataSubscription: Subscription;
    public onChange = (v: any) => { };
    private onTouched = () => { };
    key = "combo-data-danh-muc-tuong-duong-default";
    cache = sessionStorage.getItem(this.key);
    isMulti = false;

    constructor(
        private _comBoServiceProxy: ComboServiceProxy,
        //private _CoSoUXService: CoSoUXService
        ) {
    }

    writeValue(obj: any): void {
        this._value = obj;
        this.getDataFromSever(undefined, true);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }

    onFocus(event: any): void {
        this.onTouched();
    }

    get svalue() {

        if (this._value != null) {
            let selectedItem = this.optionListDefault.find((m) => m.value == this._value);
            if (selectedItem != null) {
                return selectedItem.displayText;
            }
        }

    }

    @Input()
    get disabled() {
        return this._isDisabled;
    }

    set disabled(v: boolean) {
        this._isDisabled = v;
    }




    ngOnInit() {
        this.searchSubscription = this.search$.pipe(
            debounceTime(1000),
            distinctUntilChanged()
        )
            .subscribe((result) => {
                if(result) {
                    this.getDataFromSever(result);
                } else {
                    if(this._value) {this.optionList = this.optionListDefault;}
                    else {
                        this.getDataFromSever();
                    }
                }
            });
    }

    getDataFromSever(search?: string, isChangeListDefault: boolean = false) {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
            this.dataSubscription = null;
        }


        let isUseCache = !search && !this._value;
        if (isUseCache && this.cache) {
            this.optionList = JSON.parse(this.cache);
        } else {
            let input = new ComboTableInputDtoOfString();
            input.maxResult = this.maxOptionRender;
            input.filter = search;
            if(!search) input.id = this._value;
            this.loading = true;
            this.dataSubscription = this._comBoServiceProxy.allMaTuongDuong(input)
            .pipe(finalize(() => { this.loading = false }))
            .subscribe(
                result => {
                    this.optionList = result;
                    if (isUseCache && !this.cache) {
                        sessionStorage.setItem(this.key, JSON.stringify(result));
                    }
                    if(isChangeListDefault) {
                        this.optionListDefault = result;
                    }
                }
            )
        }


    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }


    }


    openCreateOrEditModal(id: number) {
        // let _item = Object.assign(new CoSoDto(), { id });
        // this._CoSoUXService.openCreateOrEditModal({
        //     dataItem: _item,
        //     callBack: (result: CoSoDto) => {
        //         if (result) {
        //             if (id === 0) {
        //                 this.optionList.push({
        //                     value: result.id,
        //                     displayText: result.tenCoSo,
        //                     fts: AppUtilityService.getFullTextSearch(result.tenCoSo),
        //                     data: result,
        //                 });
        //             } else {
        //                 var index = this.optionList.findIndex((x) => x.value == id);
        //                 if (index !== -1) {
        //                     this.optionList[index] = Object.assign(
        //                         {},
        //                         {
        //                             value: result.id,
        //                             displayText: result.tenCoSo,
        //                             fts: AppUtilityService.getFullTextSearch(result.tenCoSo),
        //                             data: result,
        //                         },
        //                     );
        //                 }
        //             }
        //             this.writeValue(result.id);
        //         }
        //     },
        // });
    }



    searchAdvance() {
        // this._CoSoUXService.openSearchListModal({
        //     listOfSelectedValue: this.isMulti ? [...this._value] : [this._value],
        //     title: 'Tìm kiếm ',
        //     isMulti: this.isMulti,
        //     coSoId: undefined,
        //     callBack: (result: CoSoDto[]) => {
        //         if (result != null && result.length > 0) {
        //             if (this.isMulti) {
        //                 let listOfSelectedValueFromModal = result.map((x) => x.id);
        //                 this.writeValue(listOfSelectedValueFromModal);
        //             } else {
        //                 let selectedValueFromModal = result[0].id;
        //                 this.writeValue(selectedValueFromModal);
        //             }
        //         }
        //     },
        // });
    }

    onChangeValue(event: any): void {
        this.onChange(event);
        let selectedItem = this.optionList.find((x) => x.value == event);
        if (selectedItem) {
            this.eventChange.emit(selectedItem.data);
        }
        else {
            this.eventChange.emit(null);
        }
        this.optionListDefault = this.optionList;
    }



}
