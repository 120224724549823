<nz-card class="box-message">
    <ng-container *ngIf="referenceId; else notLog">
        <div *ngFor="let itemRoot of dataSource; trackBy: trackByIndex">
            <nz-divider  [nzText]="text">
                <ng-template #text>
                  {{ itemRoot.date  | luxonFormat:'D'  }}
                </ng-template>
              </nz-divider>
            <nz-list nzItemLayout="horizontal">
                <nz-list-item >
                    <nz-list-item-meta>
                        
                        <nz-list-item-meta-title>
                            <b >{{ itemRoot.creatorUserName||'creatorUserName'}} </b>
                        </nz-list-item-meta-title>
                        <nz-list-item-meta-description >
                            <ul>
                                <li *ngFor="let subItem of itemRoot.listTrackingValue">
                                    <span> {{subItem.fieldName}}: {{subItem.oldValue}} <i nz-icon nzType="arrow-right" nzTheme="outline"></i> {{subItem.newValue}}</span>
                                </li>
                            </ul>
                          
                        </nz-list-item-meta-description>
                    </nz-list-item-meta>
                </nz-list-item>
            </nz-list>
        </div>
    </ng-container>
    <ng-template #notLog>
        <nz-divider  [nzText]="text">
            <ng-template #text>
              {{ dateNow | luxonFormat:'D'  }}
            </ng-template>
          </nz-divider>
        <nz-list nzItemLayout="horizontal">
            <nz-list-item>
                <nz-list-item-meta >
                   
                    <nz-list-item-meta-title>
                        <b>{{ appSession.user.name}} </b>
                    </nz-list-item-meta-title>
                    <nz-list-item-meta-description>
                        <span> Đang tạo mới dữ liệu...</span>
                    </nz-list-item-meta-description>
                </nz-list-item-meta>
            </nz-list-item>
        </nz-list>
    </ng-template>
</nz-card>