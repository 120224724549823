import { Injectable } from '@angular/core';
import { Observable } from '@node_modules/rxjs';
import { BaseStateService } from '@shared/base-state.service';
import { CommonServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppUtilityService } from '../common/custom/utility.service';

interface State {
    reload: boolean;

    [proName: string]: any;
}

const initialState: State = {
    reload: false
};

@Injectable({ providedIn: 'root' })
export class CommonComboDataStateService extends BaseStateService<State> {
    reload$: Observable<boolean> = this.select((s) => s.reload);

    data$(key: string): Observable<any[]> {
        return this.select((state) => state[key]);
    }

    constructor(
        private commonService: CommonServiceProxy
    ) {
        super(initialState);
        this.getInitStateFromSession();
    }

    getData(opt: { type: 'table' | 'enum' | 'dictionary' | 'tree'; cascader?: any; name: number; keyCache: string; dataOption?: any; group?: number }) {
        setTimeout(() => {
            const data = this.state[opt.keyCache];
            if (AppUtilityService.isNullOrEmpty(data) === false && (AppUtilityService.isNullOrEmpty(opt.dataOption) || opt.dataOption.length <= 0)) {
                return;
            }
            this.setState({ [opt.keyCache]: [] });
            if (opt.type === 'enum') {
                this.commonService.getAppEnum(opt.name, opt.group).subscribe((d) => {
                    this.setStateAndSession(opt.keyCache, d);
                });
            } else if (opt.type === 'tree') {
                const req: any = {
                    cascaderId: opt.cascader,
                    cascaderMa: opt.cascader,
                    cascaderCode: opt.cascader,
                    type: opt.name
                };
                if (opt.dataOption && opt.dataOption.length > 0) {
                    this.setStateAndSession(opt.keyCache, opt.dataOption);
                } else {
                    this.commonService.getDataDictionary(req).subscribe((d) => {
                        this.setStateAndSession(opt.keyCache, d);
                    });
                }
            } else {
                const req: any = {
                    cascaderId: opt.cascader,
                    cascaderMa: opt.cascader,
                    cascaderCode: opt.cascader,
                    tableEnum: opt.name
                };
                if (opt.dataOption) {
                    if (opt.dataOption.cascaderFieldType == "list") {
                        req.cascaderId = null;
                        req.cascaderMa = null;
                        req.cascaderCode = null;
                        req.listcascaderCode = opt.cascader;
                    }
                }
                this.commonService.getAppEnum(req, opt.group).subscribe((d) => {
                    this.setStateAndSession(opt.keyCache, d);
                });
            }
        });
    }

    clearAll() {
        this.clearState(initialState);
        this.setState({
            reload: false
        });
        this.setState({
            reload: true
        });
    }

    clearByKey(name: string) {
        sessionStorage.removeItem(name);
        this.setState({ [name]: null });
    }

    setStateAndSession(proName, data) {
        this.setState({
            [proName]: data
        });
        const state = this.state;
        sessionStorage.setItem('commonComboDataState', JSON.stringify(state));
    }

    getInitStateFromSession() {
        const s = sessionStorage.getItem('commonComboDataState');
        if (!AppUtilityService.isNullOrEmpty(s)) {
            setTimeout(() => {
                this.setState(JSON.parse(s));
            });
        }
    }
}
