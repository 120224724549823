<div style="background-color: #fff; width: 100%; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
    <div class="footer">
        <span class="title-footer" *ngIf="settingThietLapHoTro.noiDung">
            {{settingThietLapHoTro.noiDung}}
        </span>
        <span *ngIf="settingThietLapHoTro.nguoiHoTro"><i class="fa fa-user"></i> Họ Tên:
        </span> <span style="margin-right:5px">{{settingThietLapHoTro.nguoiHoTro}} </span>

        <span  *ngIf="settingThietLapHoTro.dienThoai"><i class="fa fa-phone"></i> Điện thoại:
        </span> <span style="margin-right:5px">{{settingThietLapHoTro.dienThoai}}</span>

        <span  *ngIf="settingThietLapHoTro.email"><i class="fa-regular fa-envelope"></i>
            Email: </span> <span style="margin-right:5px">{{settingThietLapHoTro.email}}</span>

            <span *ngIf="settingThietLapHoTro.zalo" style="margin-right: 10px;">
                <span  *ngIf="settingThietLapHoTro.zalo"> <img style="width: 20px; color: #a39039;" src="/assets/icon-zalo2.png"/> Zalo:
                </span> <a (click)="clickZalo()"
                >{{settingThietLapHoTro.zalo}}</a>
            </span>
    </div>
</div>