import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'ora-field-set',
    templateUrl: './ora-field-set.component.html',
    styleUrls: ['./ora-field-set.component.scss'],
})
export class OraFieldSetComponent implements OnInit {
    @Input() tieuDe = '';
    ngOnInit(): void {}
}
