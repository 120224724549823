<div>
    <div>
        <a (click)="changePositionUp()">
            <span nz-icon nzType="up-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
        </a>
    </div>
    <div>
        <a (click)="changePositionDown()">
            <span nz-icon nzType="down-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span>
        </a>
    </div>
</div>