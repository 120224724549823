import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { RoleServiceProxy, RoleListDto, GetRolesInput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'role-combo',
    template: `<nz-select
        style="width: 100%;"
        nzAllowClear
        [nzPlaceHolder]="l('FilterByRole')"
        [nzDropdownStyle]="dropDownStyle"
        [(ngModel)]="selectedRole"
        [nzDisabled]='_isDisabled'
        (ngModelChange)="selectedChange($event)"
    >
        <nz-option *ngFor="let role of roles" [nzLabel]="role.displayName" [nzValue]="role.id"></nz-option>
    </nz-select> `,
})
export class RoleComboComponent extends AppComponentBase implements OnInit {
    roles: RoleListDto[] = [];

    _isDisabled = false;
    @Input()
    get disabled() {
        return this._isDisabled;
    }

    set disabled(v: boolean) {
        this._isDisabled = v;
    }

    @Input()
    dropDownStyle: any = null;

    @Input()
    selectMode: 'multiple' | 'tags' | 'default' = 'multiple';

    @Input()
    selectedRole: any = undefined;

    @Output()
    selectedRoleChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private _roleService: RoleServiceProxy, injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {

        let input: GetRolesInput = new GetRolesInput();
        input.isNotAdminX = true;

        this._roleService.getRoles(input).subscribe((result) => {
            this.roles = result.items;
        });
    }

    selectedChange(selectKey: any) {
        this.selectedRoleChange.emit(selectKey);
    }
}
