<nz-select
    class="pt-select"
    [(ngModel)]="_value"
    [nzDropdownMatchSelectWidth]="false"
    [nzAutoClearSearchValue]="true"
    [nzAllowClear]="allowClear"
    [nzPlaceHolder]="placeHolder"
    [nzMode]="selectMode"
    (ngModelChange)="onChangeValue($event)"
    [nzDisabled]="_isDisabled"
    nzShowSearch
    nzServerSearch
    [nzNotFoundContent]="refNotFound"
    (nzOnSearch)="search($event)"           
    [nzDropdownRender]="renderTemplate"
>
    <nz-option *ngFor="let option of options" [nzLabel]="option.displayText" [nzValue]="option.value"></nz-option>

    <ng-template #renderTemplate>
        <hr *ngIf="isAdd || isSearchAdvance" />
        <div *ngIf="isSearchAdvance">
            <a nz-button nzType="link" (click)="searchAdvance()"><i nz-icon nzType="search" nzTheme="outline"></i> Tìm kiếm</a>
        </div>
        <div *ngIf="isAdd">
            <a nz-button nzType="link" (click)="addOrEdit()"><i nz-icon nzType="plus-circle" nzTheme="outline"></i> Thêm mới</a>
        </div>
    </ng-template>

</nz-select>
