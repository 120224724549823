import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterColumns, FilterType } from '@shared/service-proxies/service-proxies';

export class FilterSuggestion extends FilterColumns {
    isActive?: boolean;
}

@Component({
    selector: 'ora-txt-search',
    templateUrl: './ora-txt-search.component.html',
    styleUrls: ['./ora-txt-search.component.scss'],
})
export class OraTxtSearchComponent implements OnInit {
    FilterType = FilterType;
    keyword = '';
    nzVisibleFilterSuggestion = false;

    @Output() onSearch = new EventEmitter();

    //filterText
    @Input() filterText: any;
    @Output() filterTextChange = new EventEmitter();

    //filterColumn
    @Input() arrFilter: FilterColumns[] = [];
    @Output() arrFilterChange = new EventEmitter<FilterColumns[]>();

    listFieldSuggestion: FilterSuggestion[] = [];
    @Input()
    set filterColumns(values: FilterColumns[]) {
        this.listFieldSuggestion = [];
        if (values) {
            values.forEach(item => {
                this.listFieldSuggestion.push(
                    Object.assign(new FilterSuggestion(), {
                        fieldName: item.fieldName,
                        fieldDisplay: item.fieldDisplay,
                        filterType: FilterType.SearchByKeyword,
                    })
                );
            });
        }
    }

    //ListXuLy
    listFieldSelect: FilterSuggestion[] = [];

    constructor() { }

    ngOnInit(): void {
        this.listFieldSuggestion = [
            Object.assign(new FilterSuggestion(), {
                fieldName: 'all',
                fieldDisplay: 'Từ khóa',
                filterType: FilterType.SearchByKeyword,
            }),
            ...(this.listFieldSuggestion?.length > 0 ? this.listFieldSuggestion : [])
        ];
    }

    search() {
        this.onSearch.emit();
    }

    clear() {
        this.listFieldSelect = [];
    }

    trackByIndex(index: number, el: any): number {
        return index;
    }

    selectField(value: FilterSuggestion) {
        if (this.keyword) {
            const valueSelectInfo = this.listFieldSelect.find((item) => {
                return item.fieldName === value.fieldName;
            });

            if (valueSelectInfo && value.fieldName !== 'all') {
                valueSelectInfo.values = [
                    ...valueSelectInfo.values,
                    this.keyword?.trim()
                ];
            }
            else {
                this.listFieldSelect = this.listFieldSelect.filter((item) => {
                    return item.fieldName !== value.fieldName;
                });

                value.values = [this.keyword?.trim()];

                this.listFieldSelect = [...this.listFieldSelect, value];
            }

            //CALL EVENT
            this.filterTextChange.emit(this.keyword);
            this.arrFilterChange.emit(this.listFieldSelect);

            //RESET Keyword
            this.keyword = '';
            document.getElementById('keywordSearch').focus();
        }
    }

    removeFilterSelect(value: FilterSuggestion, index: number) {
        value = this.listFieldSelect[index];
        this.listFieldSelect = this.listFieldSelect.filter((item) => {
            return item.fieldName !== value.fieldName;
        });

        //CALL EVENT
        this.arrFilterChange.emit(this.listFieldSelect);
        const valueKeyword = this.listFieldSelect.find((item) => {
            return item.fieldName === 'all';
        });
        if (!valueKeyword) {
            this.filterTextChange.emit('');
        }
    }

    searchByKeyword(event: any) {
        if (this.keyword && this.keyword !== '') {
            this.navigate(event.key);
            event.preventDefault();
        } else {
            if (event.key === 'Backspace' && this.listFieldSelect.length > 0) {
                this.removeFilterSelect(this.listFieldSelect[this.listFieldSelect.length - 1], this.listFieldSelect.length - 1);
                this.hiddenFilterSuggestion();
            }
        }
    }

    focusInput() {
        this.setItemActiveStatus(0);
    }

    private navigate(key) {
        const keyCode = key;
        let index = this.getActiveItemIndex();
        if (index === -1 || this.keyword === '') {
            this.setItemActiveStatus(0);
        }

        if (!this.nzVisibleFilterSuggestion) {
            this.nzVisibleFilterSuggestion = true;
        }

        // Escape
        if (keyCode === 'Escape') {
            this.keyword = '';
            this.hiddenFilterSuggestion();
            return true;
        }

        if (keyCode === 'Escape' || keyCode === 'Ctrl' || key.ctrlKey) {
            return true;
        }

        // 37: Left arrow
        // 38: Up arrow
        // 39: Right arrow
        // 40: Down arrow
        // 13: Enter
        if (keyCode === 'ArrowLeft' || keyCode === 'ArrowUp' || keyCode === 'ArrowRight' || keyCode === 'ArrowDown' || keyCode === 'Enter') {
            switch (keyCode) {
                case 'ArrowLeft':
                case 'ArrowUp':
                    this.back();
                    break;
                case 'ArrowRight':
                case 'ArrowDown':
                    this.next();
                    break;
                case 'Enter':
                    const selectedItems = this.listFieldSuggestion.find((item: FilterSuggestion) => {
                        return item.isActive;
                    });
                    this.selectField(selectedItems);

                    this.hiddenFilterSuggestion();

                    this.search();
                    break;
            }
            return true;
        }

        return false;
    }

    private getActiveItemIndex() {
        return this.listFieldSuggestion.findIndex((item: FilterSuggestion) => {
            return item.isActive;
        });
    }

    private setItemActiveStatus(index: number) {
        this.listFieldSuggestion?.forEach((item: FilterSuggestion) => item.isActive = false);
        const sourceItem = this.listFieldSuggestion[index];
        if (sourceItem) {
            sourceItem.isActive = true;
        }
    }

    private hiddenFilterSuggestion() {
        setTimeout(() => {
            this.nzVisibleFilterSuggestion = false;
        }, 100);
    }

    private next() {
        let index = this.getActiveItemIndex();
        if (index === -1) {
            const firstItem = this.listFieldSuggestion[0];
            if (firstItem) {
                firstItem.isActive = true;
            }
        } else {
            index = index < this.listFieldSuggestion.length - 1 ? index + 1 : 0;
            this.setItemActiveStatus(index);
        }
    }

    private back() {
        let index = this.getActiveItemIndex();
        if (index === -1) {
            const lastItem = this.listFieldSuggestion[this.listFieldSuggestion.length - 1];
            if (lastItem) {
                lastItem.isActive = true;
            }
        } else {
            index = index > 0 ? index - 1 : this.listFieldSuggestion.length - 1;
            this.setItemActiveStatus(index);
        }
    }
}
