import { Component, Injector, Input, OnInit } from '@angular/core';
import { CodeSystemConst } from '@app/shared/common/AppConsts';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { LstDictionaryDto, LstDictionaryServiceProxy } from '@shared/service-proxies/service-proxies';
import * as $ from 'jquery';
import _ from 'lodash';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: '../modal/dictionary-edit-modal.component.html',
})
export class DictionaryEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formValidateLstDictionary';
    @Input() dataItem: LstDictionaryDto;
    @Input() lstDictionaryTypeCode = '';

    constructor(injector: Injector, private _dataService: LstDictionaryServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        if (!AppUtilityService.isNullOrWhiteSpace(this.dataItem.code)) {
            this.getInfoById(this.dataItem.code);
        } else {
            this.clearForm();
        }
    }
    getInfoById(code: string) {
        abp.ui.setBusy();
        this._dataService
            .getByCode(code)
            .pipe(finalize(abp.ui.clearBusy))
            .subscribe((res) => {
                this.dataItem = res;
            });
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error('Bạn cần nhập đủ dữ liệu các trường có dấu * đỏ !!');
            return;
        }
        abp.ui.setBusy();
        this._dataService
            .createOrUpdate(this.dataItem)
            .pipe(finalize(abp.ui.clearBusy))
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new LstDictionaryDto(), { isActive: true, lstDictionaryTypeCode: this.lstDictionaryTypeCode });
    }

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
}
