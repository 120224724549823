<div class="oda-body-main" [id]="formId">
    <div nz-row>
        <div nz-col class="gutter-row " [nzSm]="8" [nzXs]="12">
            <label class="font-weight-normal">Mã <span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.maChiSo">
                <validation-custom [sModel]="dataItem.maChiSo"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row " [nzSm]="8" [nzXs]="24">
            <label class="font-weight-normal">Sắp Xếp <span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.sortOrder" type="number">
                <validation-custom [sModel]="dataItem.sortOrder"></validation-custom>
            </nz-form-control>
        </div>
        <div nz-col class="gutter-row " [nzSm]="8" [nzXs]="24">
            <label class="font-weight-normal">Kích hoạt</label>
            <nz-form-control>
                <nz-switch [(ngModel)]="dataItem.isActive" nzCheckedChildren="Có" nzUnCheckedChildren="Không">
                </nz-switch>
            </nz-form-control>
        </div>
    </div>
    <div nz-row>
        <div nz-col class="gutter-row ord-form-control" [nzSm]="24" [nzXs]="24">
            <label class="font-weight-normal">Tên <span class="text-red">*</span></label>
            <nz-form-control nzErrorTip="Giá trị bắt buộc!">
                <input nz-input [(ngModel)]="dataItem.tenChiSo">
                <validation-custom [sModel]="dataItem.tenChiSo"></validation-custom>
            </nz-form-control>
        </div>
    </div>
    <div nz-row>
        <div nz-col class="gutter-row " [nzSm]="8" [nzXs]="24">
            <label class="font-weight-normal">Kiểu dữ liệu <span class="text-red">*</span></label>
            <enum-combo [EnumCode]="ComboBoxEnumCode.KieuDuLieu" [(ngModel)]="dataItem.kieuDuLieuTraLoiEnum" [disabled]="isView">
            </enum-combo>
            <validation-custom [sModel]="dataItem.kieuDuLieuTraLoiEnum"></validation-custom>
        </div>
        <div nz-col class="gutter-row " nzSm="16" nzXs="24" *ngIf="showDuLieuTu()" style="padding-left:5px;">
            <label class="font-weight-normal">Dữ Liệu Từ {{dataItem.dictionaryTypeCode}} <span class="text-red">*</span></label>
            <nz-form-control>
                <table-combo [TableEnum]="ComboBoxTableCode.GetAllDuLieuTu" [(ngModel)]="dataItem.dictionaryTypeCode" [disabled]="isView">
                </table-combo>
                <validation-custom [sModel]="dataItem.dictionaryTypeCode"></validation-custom>
            </nz-form-control>
        </div>
    </div>
</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>
