import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzButtonSize } from 'ng-zorro-antd/button';

@Component({
  selector: 'ord-add-new-btn',
  template: `
    <ord-common-btn nzTypeButton="primary" nzIcon="plus-circle" text="{{text}}"
                    [loading]="loading" [nzSize]="nzSize"
                    (clickEvent)="clickEvent.emit()"></ord-common-btn>`
})
export class AddNewBtnComponent {
  @Input() text = 'Thêm mới';
  @Input() loading = false;
  @Input() nzSize: NzButtonSize;
  @Output() clickEvent = new EventEmitter();
}
