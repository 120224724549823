import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import * as $ from 'jquery';
export type IframeSourceType = 'url' | 'byte';
@Component({
    selector: 'view-pdf-v2',
    templateUrl: './view-pdf.component.html',
    styleUrls: ['./view-pdf.component.scss'],
})
export class ViewPdfKetQuaV2Component implements OnInit {
    @Input() path;
    @Input() byteData;
    @Input() type: IframeSourceType = 'url';
    frm_Viewprf = 'frm_Viewprf' + Math.floor(Math.random() * 100000);
    constructor(protected sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        if (this.type === 'byte' && this.byteData) {
            const blob = AppUtilityService.convertBase64ToBlob(this.byteData);
            this.path = window.URL.createObjectURL(blob);
        }
    }

    getPath() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.path);
    }

     //#region UX_JQuery

     fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
